import { ReactElement } from 'react';
import BasicLayout from '../../layouts/BasicLayout';
import PickUpAndDeliveryPage from '../../pages/PickUpAndDeliveryPage';

const PickUpAndDeliveryRoute = (): ReactElement => (
    <BasicLayout>
        <PickUpAndDeliveryPage />
    </BasicLayout>
);

export default PickUpAndDeliveryRoute;

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationFullDataFragment } from '../../../api';
import { SelectField, CheckBoxField } from '../../../components/inputs';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import LocationInfo from './LocationInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(5),
            width: '70%',
        },

        title: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(2),
        },
        locationInfo: {
            backgroundColor: '#EDECE8',
        },
        header: {
            color: '#676767',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
        },
        information: {
            color: '#3A686B',
        },
        checkBox: {
            marginBottom: theme.spacing(3),
        },
    })
);

type PickUpOptionProps = {
    data: LocationFullDataFragment[];
    pickUpLocation: string;
    dropOffLocation: string;
};

const PickUpOption = ({ data, pickUpLocation, dropOffLocation }: PickUpOptionProps) => {
    const { t } = useTranslation(['pickUpPage']);
    const styles = useStyles();
    const classes = useBookingStyles();

    const [field] = useField('dropOffSameLocationAsPickUp');

    const { pickUp, dropOff } = useMemo(
        () => ({
            pickUp: data?.find(e => e.id === pickUpLocation),
            dropOff: data?.find(e => e.id === dropOffLocation),
        }),
        [pickUpLocation, dropOffLocation, data]
    );

    const locationOptions = useMemo(() => {
        if (data) {
            return data?.map(item => ({ label: item.name, value: item.id }));
        }

        return [];
    }, [data]);

    return (
        <Grid item>
            <Grid item>
                <Typography className={classes.pickUpDeliveryTitle} variant="h6">
                    {t('pickUpPage:pickUpLocation')}
                </Typography>
                <SelectField
                    id="pickUpLocation"
                    name="pickUpLocation"
                    options={[...locationOptions]}
                    placeholder={t('pickUpPage:placeHolder.selectLocation')}
                    variant="basic"
                />
                {pickUp && <LocationInfo location={pickUp} />}

                <Typography className={classes.pickUpDeliveryTitle} variant="h6">
                    {t('pickUpPage:dropOffLocation')}
                </Typography>
                <Box className={styles.checkBox}>
                    <CheckBoxField label={t('pickUpPage:label.dropOffOption')} name="dropOffSameLocationAsPickUp" />
                </Box>

                <SelectField
                    disabled={field.value === true}
                    id="dropOffLocation"
                    name="dropOffLocation"
                    options={[...locationOptions]}
                    placeholder={t('pickUpPage:placeHolder.selectLocation')}
                    variant="basic"
                />

                {dropOff && <LocationInfo location={dropOff} />}
            </Grid>
        </Grid>
    );
};

export default PickUpOption;

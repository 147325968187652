import { FormControl, InputLabel, InputProps, FormHelperText } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { Field, FieldProps } from 'formik';
import { omit, compose } from 'lodash/fp';
import React, { createRef, Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import TinyDatePicker from 'tiny-date-picker';
import DateAdornment from './DateAdornment';
import { variantComponent } from './shared';
import { styles } from './useFieldStyles';
import 'tiny-date-picker/tiny-date-picker.css';

export interface DatePickerFieldProps
    extends Omit<InputProps, 'value' | 'onChange' | 'classes'>,
        WithStyles<typeof styles>,
        WithTranslation {
    label?: string;
    variant?: keyof typeof variantComponent;
    required?: boolean;
}

export interface DatePickerInputProps extends DatePickerFieldProps, FieldProps<Date> {}

class DatePickerInput extends Component<DatePickerInputProps> {
    inputRef = createRef<HTMLInputElement>();

    // TODO: create datepicker type
    picker: any;

    componentDidUpdate() {
        const { field, t } = this.props;
        if (!this.picker && t('common:formats.datePickerFormat') !== 'formats.datePickerFormat') {
            const { value } = field;
            this.picker = TinyDatePicker(this.inputRef.current, {
                mode: 'dp-below',
                format: (date: Date) => dayjs(date).format(t('common:formats.datePickerFormat')),
            });

            if (value) {
                this.picker.setState({ selectedDate: typeof value === 'string' ? dayjs(value).toDate() : value });
            }

            this.picker.on('statechange', this.onChange);
        }
    }

    componentWillUnmount() {
        if (this.picker?.destroy) {
            this.picker.destroy();
        }
    }

    onChange = (_, picker) => {
        const { form, field } = this.props;
        const { setFieldValue } = form;

        const { selectedDate } = picker.state;

        setFieldValue(field.name, selectedDate ? dayjs(selectedDate) : undefined);
    };

    render() {
        const { label, classes, meta, field, variant = 'standard', required = false, ...props } = this.props;

        const hasError = !!meta.error && meta.touched;

        const InputComponent = variantComponent[variant];

        return (
            <FormControl fullWidth>
                {label && (
                    <InputLabel className={classes.label} shrink>
                        {label} {required && <span style={{ color: 'red' }}>*</span>}
                    </InputLabel>
                )}
                <InputComponent
                    // spread input props
                    {...omit('form', props)}
                    // spread field
                    // onChange will be overriden by date picker
                    {...omit(['onChange', 'value'], field)}
                    // add end adornment
                    endAdornment={<DateAdornment position="end" />}
                    // error state
                    error={hasError}
                    // input reference
                    inputRef={this.inputRef}
                />
                {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
            </FormControl>
        );
    }
}

const DatePickerField = (props: DatePickerFieldProps) => (
    <Field {...props}>{fieldProps => <DatePickerInput {...props} {...fieldProps} />}</Field>
);

export default compose(withTranslation('common'), withStyles(styles))(DatePickerField);

import { Box, Grid, Typography, Container, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { useListLocationsQuery, DeliveryMethod } from '../../api';
import { useLoadingController } from '../../components/LoadingProvider';
import ButtonGroupField from '../../components/inputs/ButtonGroupField';
import useBookingStyles from '../../layouts/BasicLayout/useBookingStyles';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import { useBookContext } from '../../routes/BookRoutes/shared/BookingProvider';
import { setBookingData } from '../../routes/BookRoutes/shared/actions';
import { BookingState, DeliveryOptions, PickUpAndDeliveryFormValues } from '../../routes/BookRoutes/shared/types';
import { useGlobalContext } from '../../utilities/shared/GlobalContextProvider';
import { BillingSummary } from '../Booking/components';
import AssetSummary from '../Booking/components/AssetSummary';
import PickupAndDeliverySummary from '../Booking/components/PickupAndDeliverySummary';
import DeliveryOption from './components/DeliveryOption';
import PickUpOption from './components/PickUpOption';

const PickUpAndDeliveryPage = () => {
    const { data, loading } = useListLocationsQuery();
    useLoadingController(loading);
    const { state, dispatch } = useBookContext();
    const { state: globalState } = useGlobalContext();
    const { pickUpDelivery: initialPickUpDelivery } = globalState;
    const { asset, date, packageSelection, pickUpDelivery }: BookingState = state;

    const history = useHistory();
    const { additionalOptions } = packageSelection;

    const classes = useBookingStyles();
    const { t } = useTranslation(['pickUpPage']);

    const options = useMemo(
        () => [
            {
                label: t('pickUpPage:button.pickUp'),
                value: DeliveryMethod.Pickup,
            },
            {
                label: t('pickUpPage:button.delivery'),
                value: DeliveryMethod.Delivery,
            },
        ],
        [t]
    );

    const schema = Yup.object().shape({
        deliveryDetails: Yup.object().when('deliveryMethod', {
            is: DeliveryMethod.Delivery,
            then: Yup.object().shape({
                date: Yup.string().required(t('pickUpPage:errors.required')),
                location: Yup.object().shape({
                    block: Yup.string().required(t('pickUpPage:errors.required')),
                    building: Yup.string().required(t('pickUpPage:errors.required')),
                    country: Yup.string().required(t('pickUpPage:errors.required')),
                    postalCode: Yup.string().required(t('pickUpPage:errors.required')),
                    street: Yup.string().required(t('pickUpPage:errors.required')),
                    type: Yup.string().required(t('pickUpPage:errors.required')),
                    unit: Yup.string().required(t('pickUpPage:errors.required')),
                }),
                time: Yup.string().required(t('pickUpPage:errors.required')),
            }),
        }),

        deliveryMethod: Yup.string().required(t('pickUpPage:errors.required')),
        driverDetails: Yup.object().when('deliveryMethod', {
            is: DeliveryMethod.Delivery,
            then: Yup.object().shape({
                contactNumber: Yup.string().required(t('pickUpPage:errors.required')),
                name: Yup.string().required(t('pickUpPage:errors.required')),
            }),
        }),

        dropOffLocation: Yup.string().when('deliveryMethod', {
            is: DeliveryMethod.Pickup,
            then: Yup.string().when('dropOffSameLocationAsPickUp', {
                is: false,
                then: Yup.string().required(t('pickUpPage:errors.required')),
            }),
        }),
        dropOffSameLocationAsPickUp: Yup.bool(),
        pickUpLocation: Yup.string().when('deliveryMethod', {
            is: DeliveryMethod.Pickup,
            then: Yup.string().required(t('pickUpPage:errors.required')),
        }),
        returnDetails: Yup.object().when('deliveryMethod', {
            is: DeliveryMethod.Delivery,
            then: Yup.object().shape({
                date: Yup.string().required(t('pickUpPage:errors.required')),
                location: Yup.string().when('deliveryOption', {
                    is: DeliveryOptions.OneWay,
                    then: Yup.string().required(t('pickUpPage:errors.required')),
                }),
                time: Yup.string().required(t('pickUpPage:errors.required')),
            }),
        }),
    });

    const handleApply = useCallback(
        data => {
            dispatch(setBookingData<PickUpAndDeliveryFormValues>('pickUpDelivery', data));
            history.push('/book/confirmation');
        },
        [dispatch, history]
    );

    return (
        <Container className={classes.bookingPage} maxWidth="lg">
            <Formik initialValues={initialPickUpDelivery} onSubmit={handleApply} validationSchema={schema}>
                {({ values, isSubmitting, errors }) => (
                    <Form style={{ width: '100%' }}>
                        <Grid spacing={5} container>
                            <Grid lg={8} sm={12} style={{ borderRight: 'solid 1px rgb(234,234,234)' }} item>
                                <Container maxWidth="md">
                                    <Typography className={classes.pickUpDeliveryTitle}>
                                        {t('pickUpPage:pickUpAndDelivery')}
                                    </Typography>
                                    <Box>
                                        <ButtonGroupField name="deliveryMethod" options={options} />
                                    </Box>
                                    {values.deliveryMethod === DeliveryMethod.Pickup && (
                                        <PickUpOption
                                            data={data?.listLocations}
                                            dropOffLocation={values.dropOffLocation}
                                            pickUpLocation={values.pickUpLocation}
                                        />
                                    )}
                                    {values.deliveryMethod === DeliveryMethod.Delivery && (
                                        <DeliveryOption
                                            data={data?.listLocations}
                                            deliveryOption={values.deliveryOption}
                                        />
                                    )}
                                </Container>
                            </Grid>
                            <Grid lg={4} sm={12} item>
                                <AssetSummary asset={state.asset} />
                                <div style={{ height: '1rem' }} />
                                <PickupAndDeliverySummary bookingDate={date} pickUpDelivery={pickUpDelivery} />
                                <div style={{ height: '1rem' }} />
                                <BillingSummary
                                    additionalOptions={additionalOptions}
                                    rentalFee={asset.restrictions.pricePerDay * date.days}
                                />
                                <div style={{ height: '1rem' }} />
                                <Button
                                    color="secondary"
                                    disabled={isSubmitting || !isEmpty(errors)}
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                    fullWidth
                                >
                                    {t('pickUpPage:button.continue')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default withErrorBoundary(PickUpAndDeliveryPage);

import {
    Asset,
    CustomerContactType,
    CustomerStringValueType,
    CustomerDateValueType,
    UploadedFile,
    LicenseDetails,
    ResidentialAddress,
    AssetListDataFragment,
    DeliveryMethod,
} from '../../../api';

export type ListItemType = {
    value: string;
    label: string;
};

export enum DeliveryOptions {
    OneWay = 'One-way',
    TwoWay = 'Two-way',
}

export type Booking = {
    assetId: string;
    bookingDates: {
        start: Date;
        end: Date;
    };
};

export type BookingDate = {
    start: Date | string;
    end: Date | string;
    days: number;
};

export type PackageSelection = {
    additionalOptions: AdditionalOption[];
};

export type BookingState = {
    booking: Booking | {};
    // TODO: update with correct types
    customer: CustomerDetailsFormValues;
    driver: DriverDetailsFormValues;
    asset: Asset | AssetListDataFragment;
    date?: BookingDate;
    packageSelection: PackageSelection;
    pickUpDelivery: PickUpAndDeliveryFormValues;
};

export type AdditionalItem = {
    id: string;
    label: string;
    price: number;
};

export type AdditionalOption = {
    id: string;
    quantity: number;
    price: number;
    totalPrice: number;
    label: string;
};

export type CustomerDetailsFormValues = {
    salutation: CustomerStringValueType;
    fullName: CustomerStringValueType;
    displayName: CustomerStringValueType;
    identityNo: CustomerStringValueType;
    gender: CustomerStringValueType;
    dob: CustomerDateValueType;
    race: CustomerStringValueType;
    nationality: CustomerStringValueType;
    mobile: CustomerContactType;
    smsContact: CustomerContactType;
    email: CustomerStringValueType;
    residentialAddress: ResidentialAddress;
    supportingDocuments?: UploadedFile[];
    profilePicture: { signedUrl?: string };
    maritalStatus: CustomerStringValueType;
};

export type AccountDetailsFormValues = {
    fullName: CustomerStringValueType;
    identityNo: CustomerStringValueType;
    gender: CustomerStringValueType;
    dob: CustomerDateValueType;
    race: CustomerStringValueType;
    nationality: CustomerStringValueType;
    mobile: CustomerContactType;
    email: CustomerStringValueType;
    residentialAddress: ResidentialAddress;
    maritalStatus: CustomerStringValueType;
    password: string;
    confirmPassword: string;
};

export type DriverDetailsFormValues = {
    licenseDetails: LicenseDetails;
};

export type PickUpAndDeliveryFormValues = {
    deliveryMethod: DeliveryMethod;
    deliveryOption: DeliveryOptions;
    pickUpLocation: string;
    dropOffLocation: string;
    dropOffSameLocationAsPickUp: boolean;
    driverDetails: {
        name: string;
        contactNumber: string;
    };
    deliveryDetails: {
        location: ResidentialAddress;
        date: string;
        time: string;
    };
    returnDetails: {
        location: ResidentialAddress | string;
        date: string;
        time: string;
    };
    returnSameAsPickUpLocation: boolean;
};

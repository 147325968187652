import { omit } from 'lodash/fp';
import { CustomerFullDataFragment, LicenseDetails } from '../api';
import { CustomerDetailsFormValues, DriverDetailsFormValues } from '../routes/BookRoutes/shared/types';
import { DefaultContactTypeValue, DefaultResidential, DefaultStringValue } from './constants';

export const getCurrentDate = () => {
    const currentTime = new Date();

    return new Date(currentTime.getUTCFullYear(), currentTime.getUTCMonth(), currentTime.getUTCDate());
};

export const convertToCustomerDetailsFormValues = (customer: CustomerFullDataFragment): CustomerDetailsFormValues => {
    const residentialAddress = {
        ...omit(['__typename'], customer.residentialAddress || DefaultResidential),
    };
    const customerData: CustomerDetailsFormValues = {
        profilePicture: { signedUrl: customer.profilePicture?.signedUrl },
        salutation: {
            ...omit(['__typename'], customer?.particulars?.salutation || { ...DefaultStringValue }),
        },
        fullName: {
            ...omit(['__typename'], customer?.fullName || { ...DefaultStringValue }),
        },
        displayName: {
            ...omit(['__typename'], customer?.displayName || { ...DefaultStringValue }),
        },
        identityNo: {
            ...omit(['__typename'], customer?.particulars?.identityNo || { ...DefaultStringValue }),
        },
        gender: {
            ...omit(['__typename'], customer?.particulars?.gender || { ...DefaultStringValue }),
        },
        dob: {
            ...omit(['__typename'], customer?.particulars?.dateOfBirth || { ...DefaultStringValue }),
        },
        race: {
            ...omit(['__typename'], customer?.particulars?.race || { ...DefaultStringValue }),
        },
        nationality: {
            ...omit(['__typename'], customer?.particulars?.nationality || { ...DefaultStringValue }),
        },
        maritalStatus: {
            ...omit(['__typename'], customer?.particulars?.maritalStatus || { ...DefaultStringValue }),
        },
        mobile: {
            ...omit(['__typename'], customer?.mobilePhone || { ...DefaultContactTypeValue }),
        },
        smsContact: {
            ...omit(['__typename'], customer?.mobilePhone || { ...DefaultContactTypeValue }),
        },
        email: {
            ...omit(['__typename'], customer?.email),
        },
        residentialAddress: {
            ...residentialAddress,
            addressType: {
                ...omit(
                    ['__typename'],
                    residentialAddress.addressType || {
                        ...DefaultStringValue,
                    }
                ),
            },
        },
    };

    return customerData;
};

export const convertToDriverDetails = (licenseDetails: LicenseDetails): DriverDetailsFormValues => {
    const driverData: DriverDetailsFormValues = {
        licenseDetails: {
            ...omit(['__typename'], licenseDetails),
            classes: [
                ...licenseDetails.classes.map(licenseClass => ({
                    ...omit(['__typename'], licenseClass),
                })),
            ],
            disqualification: {
                ...omit(['__typename'], licenseDetails.disqualification),
            },
            revocation: {
                ...omit(['__typename'], licenseDetails.revocation),
            },
            suspension: {
                ...omit(['__typename'], licenseDetails.suspension),
            },
        },
    };

    return driverData;
};

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SelectField, TextField, DatePickerField } from '../../../components/inputs';
import { useGlobalContext } from '../../../utilities/shared/GlobalContextProvider';
import SupportingDocuments from './SupportingDocuments';

const PersonalDetailsSection = () => {
    const [t] = useTranslation('customerPage');
    const { state } = useGlobalContext();

    return (
        <Grid spacing={3} xs={12} container item>
            <Grid md={6} xs={12} item>
                <SelectField
                    label={t('label.salutation')}
                    name="salutation.value"
                    options={state?.appOptions?.salutations}
                    placeholder={t('placeholder.salutation')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.fullName')}
                    name="fullName.value"
                    placeholder={t('placeholder.fullName')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.displayName')}
                    name="displayName.value"
                    placeholder={t('placeholder.displayName')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.identityNo')}
                    name="identityNo.value"
                    placeholder={t('placeholder.identityNo')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <SelectField
                    label={t('label.gender')}
                    name="gender.value"
                    options={state?.appOptions?.genders}
                    placeholder={t('placeholder.gender')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <DatePickerField
                    label={t('label.dob')}
                    name="dob.value"
                    placeholder={t('placeholder.dob')}
                    fullWidth
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <SelectField
                    label={t('label.race')}
                    name="race.value"
                    options={state?.appOptions?.races}
                    placeholder={t('placeholder.race')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <SelectField
                    label={t('label.nationality')}
                    name="nationality.value"
                    options={state?.appOptions?.nationalities}
                    placeholder={t('placeholder.nationality')}
                    required
                />
            </Grid>
            <Grid xs={12} container item>
                <SupportingDocuments name="supportingDocuments" />
            </Grid>
        </Grid>
    );
};

export default PersonalDetailsSection;

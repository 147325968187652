import { Box, Button, Divider, Drawer as MuiDrawer, DrawerProps } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePublic from '../../utilities/usePublic';
import useBasicStyles from './useBasicStyles';

const Drawer = (props: DrawerProps) => {
    const { t } = useTranslation(['appBar']);
    const classes = useBasicStyles();
    const appvantageLogo = usePublic('assets/images/appvantageLogo.png');
    const { onClose } = props as DrawerProps & { onClose: any };

    return (
        <MuiDrawer className={classes.drawer} {...props}>
            <div onClick={onClose} onKeyDown={onClose} role="presentation">
                <Box
                    alignItems="center"
                    className={classes.drawerBackground}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <img alt="" className={classes.drawerLogo} src={appvantageLogo} />
                    <Button color="secondary" variant="contained">
                        {t('appBar:signIn')}
                    </Button>
                </Box>
                <Divider />
            </div>
        </MuiDrawer>
    );
};

export default Drawer;

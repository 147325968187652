import { ReactElement, ReactNode } from 'react';
import Footer from './Footer';
import Header from './Header';
import useBasicStyles from './useBasicStyles';

export type DefaultLayoutProps = {
    children: ReactElement | ReactNode | null;
};

const BasicLayout = ({ children }: DefaultLayoutProps): ReactElement => {
    const classes = useBasicStyles();

    return (
        <div className={classes.root}>
            <Header />
            <div className={classes.content}>
                <main className={classes.main}>{children}</main>
            </div>
            <Footer />
        </div>
    );
};

export default BasicLayout;

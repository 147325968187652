import { Grid, Avatar, Typography, Container, Button, TextField as MatTextField } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Formik, Form } from 'formik';
import { pick, isEmpty } from 'lodash/fp';
import React, { useCallback, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AccountFields, useSaveUserAccountDetailsMutation, useUploadProfilePictureMutation } from '../../../api';
import { useAlert } from '../../../components/AlertProvider';
import { SelectField, TextField, DatePickerField, ViewOnlyDateField, PasswordField } from '../../../components/inputs';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';
import { CustomerDetailsFormValues, AccountDetailsFormValues } from '../../../routes/BookRoutes/shared/types';
import { convertToCustomerDetailsFormValues } from '../../../utilities/commonFunctions';
import { DefaultStringValue } from '../../../utilities/constants';
import { useHandleError } from '../../../utilities/handleError';
import { useGlobalContext } from '../../../utilities/shared/GlobalContextProvider';
import { setGlobalStateData } from '../../../utilities/shared/actions';

const AccountPage = () => {
    const classes = useOverviewStyles();
    const { profilePicture, state, dispatch } = useGlobalContext();
    const { customer, appOptions } = state;
    const { t } = useTranslation(['overviewPage', 'customerPage', 'signUpPage']);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [uploadProfilePicture] = useUploadProfilePictureMutation();
    const imgRef = useRef<HTMLInputElement>(null);
    const [saveUserAccountDetails] = useSaveUserAccountDetailsMutation();
    const { show } = useAlert();

    const InitValues: AccountDetailsFormValues = {
        ...pick(
            [
                'fullName',
                'identityNo',
                'gender',
                'dob',
                'race',
                'maritalStatus',
                'nationality',
                'mobile',
                'email',
                'residentialAddress',
            ],
            customer
        ),
        password: '',
        confirmPassword: '',
    };

    const validationSchema = useMemo(() => {
        const schema = yup.object().shape({
            confirmPassword: yup.lazy((_, { parent }) => {
                const { password } = parent;

                return yup
                    .string()
                    .test('is-same', t('signUpPage:validation.notMatch.confirmPassword'), value =>
                        password ? value === password : true
                    );
            }),
            dob: yup.object().shape({
                value: yup.date().required(t('customerPage:validation.required.dob')),
            }),
            email: yup.object().shape({
                value: yup
                    .string()
                    .required(t('customerPage:validation.required.email'))
                    .email(t('customerPage:validation.invalid.email'))
                    .max(320, t('customerPage:validation.maxLength.email')),
            }),
            fullName: yup.object().shape({
                value: yup.string().required(t('customerPage:validation.required.fullName')),
            }),
            gender: yup.object().shape({
                value: yup.string().required(t('customerPage:validation.required.gender')),
            }),
            identityNo: yup.object().shape({
                value: yup.string().required(t('customerPage:validation.required.identityNo')),
            }),
            maritalStatus: yup.object().shape({
                value: yup.string().required(t('customerPage:validation.required.maritalStatus')),
            }),
            mobile: yup.object().shape({
                value: yup
                    .string()
                    .required(t('customerPage:validation.required.mobile'))
                    .min(8, t('customerPage:validation.minLength.mobile'))
                    .max(8, t('customerPage:validation.maxLength.mobile')),
            }),
            nationality: yup.object().shape({
                value: yup.string().required(t('customerPage:validation.required.nationality')),
            }),
            race: yup.object().shape({
                value: yup.string().required(t('customerPage:validation.required.race')),
            }),
            residentialAddress: yup.object().shape({
                block: yup.string().required(t('customerPage:validation.required.residentialAddress.block')),
                country: yup.string().required(t('customerPage:validation.required.residentialAddress.country')),
                postalCode: yup
                    .string()
                    .required(t('customerPage:validation.required.residentialAddress.postalCode'))
                    .min(6, t('customerPage:validation.minLength.residentialAddress.postalCode'))
                    .max(6, t('customerPage:validation.maxLength.residentialAddress.postalCode')),
                street: yup.string().required(t('customerPage:validation.required.residentialAddress.street')),
                unit: yup.string().required(t('customerPage:validation.required.residentialAddress.unit')),
            }),
        });

        return schema;
    }, [t]);

    const openFileDialog = useCallback(() => {
        imgRef.current.click();
    }, [imgRef]);

    const handleUpload = useCallback(
        async event => {
            const file = event.target.files[0];

            const uploadResult = await uploadProfilePicture({
                variables: { file },
            });

            dispatch(
                setGlobalStateData<CustomerDetailsFormValues>('customer', {
                    ...customer,
                    profilePicture: { signedUrl: uploadResult.data?.uploadProfilePicture?.signedUrl },
                })
            );
        },
        [customer, dispatch, uploadProfilePicture]
    );

    const handleSubmit = useHandleError(
        async (values: AccountDetailsFormValues) => {
            const accountFields: AccountFields = {
                fullName: {
                    ...values.fullName,
                },
                email: {
                    ...values.email,
                },
                mobilePhone: {
                    internationalCode: values.mobile.internationalCode || '',
                    value: values.mobile.value || '',
                    source: values.mobile.source,
                },
                identityNo: {
                    ...values.identityNo,
                },
                dateOfBirth: {
                    ...values.dob,
                },
                gender: {
                    ...values.gender,
                },
                race: {
                    ...values.race,
                },
                maritalStatus: {
                    ...values.maritalStatus,
                },
                nationality: {
                    ...values.nationality,
                },
                residentialAddress: {
                    ...values.residentialAddress,
                    addressType: values.residentialAddress.addressType || { ...DefaultStringValue },
                },
                password: values.password,
            };

            const { data } = await saveUserAccountDetails({
                variables: {
                    fields: accountFields,
                },
            });

            const customerData = convertToCustomerDetailsFormValues(data.saveUserAccountDetails);

            dispatch(setGlobalStateData<CustomerDetailsFormValues>('customer', customerData));

            setEditMode(false);
            show('success', t('alert.accountSaveSuccess'));
        },
        [saveUserAccountDetails]
    );

    return (
        <Container maxWidth="md">
            <Formik initialValues={InitValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ isSubmitting, errors, values }) => (
                    <Form style={{ width: '100%' }}>
                        <Grid spacing={3} container>
                            <Grid alignItems="center" justify="center" md={1} xs={12} container item>
                                <Avatar alt="User Profile" className={classes.avatarLarge} src={profilePicture} />
                            </Grid>
                            <Grid md={11} xs={12} container item>
                                <Grid xs={12} item>
                                    <Typography className={classes.name} variant="h6">
                                        {customer.fullName?.value}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} item>
                                    <div className={classes.buttonGroup}>
                                        <Button
                                            className={classes.overviewButton}
                                            color="secondary"
                                            disabled={isSubmitting}
                                            onClick={openFileDialog}
                                            startIcon={<AddAPhotoIcon />}
                                            variant="outlined"
                                        >
                                            {t('label.uploadPhoto')}
                                        </Button>
                                        <input
                                            ref={imgRef}
                                            accept="image/jpg, image/png, image/tiff, image/gif, image/bmp"
                                            onChange={handleUpload}
                                            style={{ display: 'none' }}
                                            type="file"
                                        />
                                        {editMode && (
                                            <Button
                                                className={classes.overviewButton}
                                                color="secondary"
                                                disabled={isSubmitting || !isEmpty(errors)}
                                                type="submit"
                                                variant="contained"
                                            >
                                                {t('label.saveProfile')}
                                            </Button>
                                        )}
                                        {!editMode && (
                                            <Button
                                                className={classes.overviewButton}
                                                color="secondary"
                                                onClick={() => setEditMode(true)}
                                                type="button"
                                                variant="contained"
                                            >
                                                {t('label.editProfile')}
                                            </Button>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid xs={12} item>
                                <Typography className={classes.subGroupTitle} component="p">
                                    {t('label.personalDetails')}
                                </Typography>
                            </Grid>

                            <Grid md={6} xs={12} item>
                                <TextField
                                    label={t('customerPage:label.fullName')}
                                    name="fullName.value"
                                    placeholder={t('customerPage:placeholder.fullName')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <TextField
                                    label={t('customerPage:label.identityNo')}
                                    name="identityNo.value"
                                    placeholder={t('customerPage:placeholder.identityNo')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid md={6} xs={12} item>
                                <SelectField
                                    label={t('customerPage:label.gender')}
                                    name="gender.value"
                                    options={appOptions?.genders}
                                    placeholder={t('customerPage:placeholder.fullName')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                {!editMode && (
                                    <ViewOnlyDateField label={t('customerPage:label.dob')} name="dob.value" />
                                )}
                                <div style={{ display: editMode ? '' : 'none' }}>
                                    <DatePickerField
                                        label={t('customerPage:label.dob')}
                                        name="dob.value"
                                        placeholder={t('customerPage:placeholder.dob')}
                                        viewOnly={!editMode}
                                        fullWidth
                                        required
                                    />
                                </div>
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <SelectField
                                    label={t('customerPage:label.race')}
                                    name="race.value"
                                    options={appOptions?.races}
                                    placeholder={t('customerPage:placeholder.race')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <SelectField
                                    label={t('customerPage:label.maritalStatus')}
                                    name="maritalStatus.value"
                                    options={appOptions?.maritalStatuses}
                                    placeholder={t('customerPage:placeholder.maritalStatus')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <SelectField
                                    label={t('customerPage:label.nationality')}
                                    name="nationality.value"
                                    options={appOptions?.nationalities}
                                    placeholder={t('customerPage:placeholder.nationality')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <Typography className={classes.subGroupTitle} component="p">
                                    {t('label.contactDetails')}
                                </Typography>
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <TextField
                                    label={t('customerPage:label.mobile')}
                                    name="mobile.value"
                                    placeholder={t('customerPage:placeholder.mobile')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <TextField
                                    label={t('customerPage:label.email')}
                                    name="email.value"
                                    placeholder={t('customerPage:placeholder.email')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <SelectField
                                    label={t('customerPage:label.residentialAddress.addressType')}
                                    name="residentialAddress.addressType.value"
                                    options={appOptions?.addressTypes}
                                    placeholder={t('customerPage:placeholder.residentialAddress.addressType')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <TextField
                                    label={t('customerPage:label.residentialAddress.block')}
                                    name="residentialAddress.block"
                                    placeholder={t('customerPage:placeholder.residentialAddress.block')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <TextField
                                    label={t('customerPage:label.residentialAddress.unit')}
                                    name="residentialAddress.unit"
                                    placeholder={t('customerPage:placeholder.residentialAddress.unit')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <TextField
                                    label={t('customerPage:label.residentialAddress.street')}
                                    name="residentialAddress.street"
                                    placeholder={t('customerPage:placeholder.residentialAddress.street')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <TextField
                                    label={t('customerPage:label.residentialAddress.building')}
                                    name="residentialAddress.building"
                                    placeholder={t('customerPage:placeholder.residentialAddress.building')}
                                    viewOnly={!editMode}
                                    fullWidth
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <TextField
                                    label={t('customerPage:label.residentialAddress.postalCode')}
                                    name="residentialAddress.postalCode"
                                    placeholder={t('customerPage:placeholder.residentialAddress.postalCode')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <SelectField
                                    label={t('customerPage:label.residentialAddress.country')}
                                    name="residentialAddress.country"
                                    options={appOptions?.countries}
                                    placeholder={t('customerPage:placeholder.residentialAddress.country')}
                                    viewOnly={!editMode}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            {editMode && (
                                <>
                                    <Grid xs={12} item>
                                        <Typography className={classes.subGroupTitle} component="p">
                                            {t('customerPage:label.changePassword')}
                                        </Typography>
                                    </Grid>

                                    <Grid md={6} xs={12} item>
                                        <PasswordField
                                            label={t('customerPage:label.password')}
                                            name="password"
                                            placeholder={t('customerPage:placeholder.password')}
                                            type="password"
                                            viewOnly={!editMode}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid md={6} xs={12} item>
                                        <PasswordField
                                            label={t('customerPage:label.confirmPassword')}
                                            name="confirmPassword"
                                            placeholder={t('customerPage:placeholder.confirmPassword')}
                                            type="password"
                                            viewOnly={!editMode}
                                            fullWidth
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid xs={12} item>
                                <Typography className={classes.subGroupTitle} component="p">
                                    {t('label.refer')}
                                </Typography>
                            </Grid>

                            <Grid direction="row" spacing={2} container>
                                <Grid md={8} sm={7} xs={12} item>
                                    <MatTextField fullWidth />
                                </Grid>
                                <Grid md={1} sm={1} xs={12} item>
                                    <Button
                                        className={classes.overviewButton}
                                        color="secondary"
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                    >
                                        {t('label.copyLink')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default withErrorBoundary(AccountPage);

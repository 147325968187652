import {
    CustomerContactType,
    CustomerDetailsSource,
    DeliveryMethod,
    LicenseClass,
    LicenseDetails,
    ResidentialAddress,
} from '../api';
import {
    CustomerDetailsFormValues,
    DeliveryOptions,
    DriverDetailsFormValues,
    PickUpAndDeliveryFormValues,
} from '../routes/BookRoutes/shared/types';

export const DefaultStringValue = { source: CustomerDetailsSource.Manual, value: '' };

export const DefaultContactTypeValue: CustomerContactType = {
    source: CustomerDetailsSource.Manual,
    value: '',
    internationalCode: '',
};

export const DefaultResidential: ResidentialAddress = {
    source: CustomerDetailsSource.Manual,
    block: '',
    unit: '',
    street: '',
    building: '',
    country: 'SG',
    postalCode: '',
    addressType: DefaultStringValue,
};

export const InitialCustomerFormValues: CustomerDetailsFormValues = {
    profilePicture: {},
    salutation: DefaultStringValue,
    fullName: DefaultStringValue,
    displayName: DefaultStringValue,
    identityNo: DefaultStringValue,
    gender: DefaultStringValue,
    dob: DefaultStringValue,
    race: DefaultStringValue,
    nationality: DefaultStringValue,
    mobile: DefaultContactTypeValue,
    smsContact: DefaultContactTypeValue,
    email: DefaultStringValue,
    residentialAddress: DefaultResidential,
    supportingDocuments: [],
    maritalStatus: DefaultStringValue,
};

const defaultLicenseClass: LicenseClass = { class: '', issueDate: '' };

const defaultLicenseDetails: LicenseDetails = {
    source: CustomerDetailsSource.Manual,
    classes: [defaultLicenseClass],
    number: '',
    validity: '',
    expiryDate: '',
};

export const InitialDriverFormValues: DriverDetailsFormValues = {
    licenseDetails: defaultLicenseDetails,
};

export const InitialPickUpDeliveryFormValues: PickUpAndDeliveryFormValues = {
    deliveryMethod: DeliveryMethod.Pickup,
    deliveryOption: DeliveryOptions.OneWay,
    pickUpLocation: '',
    dropOffLocation: '',
    driverDetails: {
        name: '',
        contactNumber: '',
    },
    deliveryDetails: {
        date: '',
        time: '',
        location: DefaultResidential,
    },
    returnDetails: {
        location: '',
        date: '',
        time: '',
    },
    returnSameAsPickUpLocation: false,
    dropOffSameLocationAsPickUp: false,
};

import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import NotificationModal from '../../components/NotificationModal';

export type NotificationContext = {
    setNotification: (boolean) => void;
};

const Context = createContext<NotificationContext | null>(null);

export const useNotificationContext = () => {
    const context = useContext(Context);

    if (context === null) {
        throw new Error('Context missing in React tree');
    }

    return context;
};

export type NotificationContextProviderProps = {
    children: JSX.Element | ReactNode;
    initialState?: string | null;
};

const NotificationContextProvider = ({ children, initialState = null }: NotificationContextProviderProps) => {
    const [state, setState] = useState(initialState);

    const onClose = useCallback(() => {
        setState(null);
    }, [setState]);

    // const setNotification = useCallback(
    //     message => {
    //         setState(message);
    //     },
    //     [setState]
    // );

    const context = useMemo(
        () => ({
            setNotification: message => {
                setState(message);
            },
        }),
        [setState]
    );

    return (
        <Context.Provider value={context}>
            {children}
            <NotificationModal message={state} onClose={onClose} open={state !== null} />
        </Context.Provider>
    );
};

export default NotificationContextProvider;

export const TAX = 0.07;

export const calculate = (rentalFee, additionalOptions) => {
    const fees = [...additionalOptions.map(option => option.totalPrice), rentalFee];

    const subTotal = fees.reduce((curr, value) => curr + value, 0);

    const taxValue = subTotal * TAX;

    const total = subTotal + taxValue;

    return {
        subTotal,
        taxValue,
        total,
    };
};

export const calculateDays = (start: Date, end: Date): number => {
    const diffTime = end.getTime() - start.getTime();
    const diffDays = diffTime / (1000 * 3600 * 24);

    return diffDays + 1;
};

import { Container, Grid, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';
import { useBookContext } from '../../../routes/BookRoutes/shared/BookingProvider';
import { setBookingData } from '../../../routes/BookRoutes/shared/actions';
import { BookingState, DriverDetailsFormValues } from '../../../routes/BookRoutes/shared/types';
import { useGlobalContext } from '../../../utilities/shared/GlobalContextProvider';
import AssetSummary from '../components/AssetSummary';
import BillingSummary from '../components/BillingSummary';
import PickupAndDeliverySummary from '../components/PickupAndDeliverySummary';
import DriverDetails from './DriverDetails';

const DriverPage = () => {
    const classes = useBookingStyles();
    const { state, dispatch } = useBookContext();
    const { state: globalState } = useGlobalContext();
    const { driver } = globalState;
    const { asset, date, packageSelection, pickUpDelivery }: BookingState = state;
    const { additionalOptions } = packageSelection;
    const { t } = useTranslation('driverPage');
    const history = useHistory();

    const validationSchema = useMemo(() => {
        const schema = yup.object().shape({
            licenseDetails: yup.object().shape({
                classes: yup
                    .array()
                    .of(
                        yup.object().shape({
                            class: yup.string().required(t('validation.required.licenseClass')),
                            issueDate: yup.string().required(t('validation.required.issueDate')),
                        })
                    )
                    .required(t('validation.required.licenseClass')),
                expiryDate: yup.string().required(t('validation.required.expiryDate')),
                validity: yup.string().required(t('validation.required.licenseValidity')),
            }),
        });

        return schema;
    }, [t]);

    const handleApply = useCallback(
        data => {
            dispatch(setBookingData<DriverDetailsFormValues>('driver', data));
            history.push('/book/pickupDelivery');
        },
        [dispatch, history]
    );

    return (
        <Container className={classes.bookingPage} maxWidth="lg">
            <Formik initialValues={driver} onSubmit={handleApply} validationSchema={validationSchema}>
                {({ isSubmitting, errors, values }) => (
                    <Form style={{ width: '100%' }}>
                        <Grid spacing={4} container>
                            <Grid lg={8} sm={12} style={{ borderRight: 'solid 1px rgb(234,234,234)' }} item>
                                <Container maxWidth="md">
                                    <DriverDetails driverDetails={driver} />
                                </Container>
                            </Grid>
                            <Grid lg={4} sm={12} item>
                                <AssetSummary asset={state.asset} />
                                <div style={{ height: '1rem' }} />
                                <PickupAndDeliverySummary bookingDate={date} pickUpDelivery={pickUpDelivery} />
                                <div style={{ height: '1rem' }} />
                                <BillingSummary
                                    additionalOptions={additionalOptions}
                                    rentalFee={asset.restrictions.pricePerDay * date.days}
                                />
                                <div style={{ height: '1rem' }} />
                                <Button
                                    color="secondary"
                                    disabled={isSubmitting || !isEmpty(errors)}
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                    fullWidth
                                >
                                    {t('continueButtonText')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default withErrorBoundary(DriverPage);

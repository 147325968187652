import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationFullDataFragment } from '../../../api';
import { DatePickerField, SelectField, TextField, CheckBoxField } from '../../../components/inputs';
import ButtonGroupField from '../../../components/inputs/ButtonGroupField';
import TimeField from '../../../components/inputs/TimeField';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { DeliveryOptions } from '../../../routes/BookRoutes/shared/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(5),
            width: '65%',
        },
        button: {
            width: '50%',
            marginTop: theme.spacing(2),
            borderRadius: 0,
            '& .MuiButton-contained': {
                boxShadow: 'none',
            },
            padding: theme.spacing(1),
        },
        inputBox: {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        setWidthToHundred: {
            width: '100%',
        },
        dateInput: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            border: 0,
            paddingRight: '24px',
            paddingLeft: '15px',
            paddingTop: '2.25px',
            paddingBottom: '2.25px',
            marginTop: '4px',
        },
        title: {
            marginTop: theme.spacing(5),
            fontSize: '1.1rem',
            fontWeight: 'bold',
        },
        textInput: {
            '& .MuiInput-input': {
                marginTop: '4px',
            },
            '& .MuiSelect-root': {
                marginTop: '4px',
            },
        },
        addressInput: {
            border: `1px solid ${theme.palette.secondary.main}`,
            opacity: '0.6',
            '& .MuiSelect-root': {
                padding: '10px 0',
            },
        },
    })
);

type DeliveryOptionProps = {
    data: LocationFullDataFragment[];
    deliveryOption: string;
};

const DeliveryOption = ({ data, deliveryOption }: DeliveryOptionProps) => {
    const { t } = useTranslation(['pickUpPage']);
    const classes = useBookingStyles();
    const styles = useStyles();

    const locationOptions = useMemo(() => {
        if (data) {
            return data?.map(item => ({ label: item.name, value: item.id }));
        }

        return [];
    }, [data]);

    return (
        <Grid item>
            <Box my={4}>
                <Typography className={classes.pickUpDeliveryTitle}>{t('pickUpPage:deliveryOptions')}</Typography>
                <ButtonGroupField
                    name="deliveryOption"
                    options={[
                        { label: t('pickUpPage:button.oneWay'), value: t('pickUpPage:button.oneWay') },
                        { label: t('pickUpPage:button.twoWay'), value: t('pickUpPage:button.twoWay') },
                    ]}
                />
            </Box>
            <Typography className={classes.pickUpDeliveryTitle}>{t('pickUpPage:deliveryAddress')}</Typography>
            <Grid justifyContent="space-between" spacing={5} container>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <TextField
                        className={styles.textInput}
                        id="driverDetails.name"
                        label={t('pickUpPage:label.driverName')}
                        name="driverDetails.name"
                        placeholder={t('pickUpPage:placeHolder.driverNamePlaceholder')}
                    />
                </Grid>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <TextField
                        className={styles.textInput}
                        id="driverDetails.contactNumber"
                        label={t('pickUpPage:label.driverContactNumber')}
                        name="driverDetails.contactNumber"
                        placeholder={t('pickUpPage:placeHolder.driverContactNumberPlaceholder')}
                    />
                </Grid>
            </Grid>

            <Box display="flex" flexDirection="column">
                <SelectField
                    className={styles.addressInput}
                    id="address.type"
                    label={t('pickUpPage:label.addressType')}
                    name="address.type"
                    options={[{ value: '10', label: 'Condominium' }]}
                    variant="basic"
                />
            </Box>
            <Grid justifyContent="space-between" spacing={5} container>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <TextField
                        className={styles.textInput}
                        id="address.block"
                        label={t('pickUpPage:label.addressBlock')}
                        name="address.block"
                        placeholder={t('pickUpPage:placeHolder.addressPlaceholder')}
                    />
                </Grid>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <TextField
                        className={styles.textInput}
                        id="address.unit"
                        label={t('pickUpPage:label.adressUnit')}
                        name="address.unit"
                        placeholder={t('pickUpPage:placeHolder.addressPlaceholder')}
                    />
                </Grid>
            </Grid>
            <Grid justifyContent="space-between" spacing={5} container>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <TextField
                        className={styles.textInput}
                        id="address.street"
                        label={t('pickUpPage:label.addressStreet')}
                        name="address.street"
                        placeholder={t('pickUpPage:placeHolder.addressPlaceholder')}
                    />
                </Grid>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <TextField
                        className={styles.textInput}
                        id="address.building"
                        label={t('pickUpPage:label.addressBuilding')}
                        name="address.building"
                        placeholder={t('pickUpPage:placeHolder.addressPlaceholder')}
                    />
                </Grid>
            </Grid>
            <Grid justifyContent="space-between" spacing={5} container>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <TextField
                        className={styles.textInput}
                        id="address.postalCode"
                        label={t('pickUpPage:label.postalCode')}
                        name="address.postalCode"
                        placeholder={t('pickUpPage:placeHolder.postalCodePlaceholder')}
                    />
                </Grid>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <SelectField
                        className={styles.textInput}
                        id="address.country"
                        label={t('pickUpPage:label.addressCountry')}
                        name="address.country"
                        options={[{ value: '10', label: 'test' }]}
                    />
                </Grid>
            </Grid>

            <Typography className={classes.pickUpDeliveryTitle}>{t('pickUpPage:deliveryDateTime')}</Typography>
            <Grid justifyContent="space-between" spacing={5} container>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <DatePickerField
                        className={styles.dateInput}
                        id="deliveryDetails.date"
                        label={t('pickUpPage:label.deliveryDate')}
                        name="deliveryDetails.date"
                        variant="basic"
                        disableUnderline
                    />
                </Grid>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <TimeField
                        id="deliveryDetails.time"
                        label={t('pickUpPage:label.deliveryTime')}
                        name="deliveryDetails.time"
                    />
                </Grid>
            </Grid>
            <Typography className={classes.pickUpDeliveryTitle}>{t('pickUpPage:vehicleReturnLocation')}</Typography>
            {deliveryOption === DeliveryOptions.OneWay ? (
                <Box className={styles.inputBox} display="flex" flexDirection="row" my={4}>
                    <SelectField
                        id="returnDetails.location"
                        label={t('pickUpPage:label.returnLocation')}
                        name="returnDetails.location"
                        options={locationOptions}
                        placeholder={t('pickUpPage:placeHolder.selectLocation')}
                        variant="basic"
                    />
                </Box>
            ) : (
                <Box className={styles.inputBox} display="flex" flexDirection="row">
                    <CheckBoxField label={t('pickUpPage:label.dropOffOption')} name="returnSameAsPickUpLocation" />
                </Box>
            )}
            <Typography className={classes.pickUpDeliveryTitle}>{t('pickUpPage:vehicleReturnDateTime')}</Typography>
            <Grid justifyContent="space-between" spacing={5} container>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <DatePickerField
                        className={styles.dateInput}
                        id="returnDetails.date"
                        label={t('pickUpPage:label.returnDate')}
                        name="returnDetails.date"
                        variant="basic"
                        disableUnderline
                    />
                </Grid>
                <Grid className={styles.inputBox} sm={6} xs={12} item>
                    <TimeField
                        id="returnDetails.time"
                        label={t('pickUpPage:label.returnTime')}
                        name="returnDetails.time"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DeliveryOption;

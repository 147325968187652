import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DatePickerField, ViewOnlyDateField } from '../../../components/inputs';

interface LicenseRevocationProps {
    name: string;
    viewOnly?: boolean;
}

const LicenseRevocation = ({ name, viewOnly = false }: LicenseRevocationProps) => {
    const { t } = useTranslation('driverPage');

    return (
        <>
            <Grid md={6} xs={12} item>
                {viewOnly && (
                    <ViewOnlyDateField label={t('label.revocationStart')} name={`${name}.revocation.startDate`} />
                )}
                <div style={{ display: viewOnly ? 'none' : '' }}>
                    <DatePickerField
                        label={t('label.revocationStart')}
                        name={`${name}.revocation.startDate`}
                        placeholder={t('placeholder.revocationStart')}
                        fullWidth
                    />
                </div>
            </Grid>
            <Grid md={6} xs={12} item>
                {viewOnly && <ViewOnlyDateField label={t('label.revocationEnd')} name={`${name}.revocation.endDate`} />}
                <div style={{ display: viewOnly ? 'none' : '' }}>
                    <DatePickerField
                        label={t('label.revocationEnd')}
                        name={`${name}.revocation.endDate`}
                        placeholder={t('placeholder.revocationEnd')}
                    />
                </div>
            </Grid>
        </>
    );
};

export default LicenseRevocation;

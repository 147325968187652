import { FormControl, FormHelperText, InputLabel, Select, MenuItem, SelectProps, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useField } from 'formik';
import React from 'react';
import { variantComponent } from './shared';
import useFieldStyles from './useFieldStyles';

export interface SelectFieldProps extends Omit<SelectProps, 'value' | 'onChange' | 'variant'> {
    name: string;
    options: { label: string; value: string }[];
    // override variant
    variant?: keyof typeof variantComponent;
    viewOnly?: boolean;
    required?: boolean;
}

const SelectField = ({
    name,
    label,
    placeholder,
    options,
    variant = 'standard',
    viewOnly = false,
    required = false,
    ...props
}: SelectFieldProps) => {
    const styles = useFieldStyles();

    const [field, meta] = useField({ name });

    const hasError = !!meta.error && meta.touched;

    const InputComponent = variantComponent[variant];

    return (
        <FormControl fullWidth>
            {label && (
                <InputLabel className={styles.label} shrink>
                    {label} {required && <span style={{ color: 'red' }}>*</span>}
                </InputLabel>
            )}
            {!viewOnly && (
                <Select
                    // spread input props
                    {...props}
                    // spread field
                    {...field}
                    // update elect style
                    className={clsx(styles.select, props.className)}
                    // error state
                    error={hasError}
                    // set variant input component
                    input={<InputComponent />}
                    displayEmpty
                >
                    {placeholder && (
                        <MenuItem value="" disabled>
                            <div className={styles.placeholderOpacity}>{placeholder}</div>
                        </MenuItem>
                    )}

                    {options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {viewOnly && (
                <>
                    <br />
                    <Typography>{options.find(option => meta.value === option.value)?.label}</Typography>
                </>
            )}
            {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default SelectField;

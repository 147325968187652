import { Asset, AssetStatus } from '../../../api';
import {
    InitialCustomerFormValues,
    InitialDriverFormValues,
    InitialPickUpDeliveryFormValues,
} from '../../../utilities/constants';
import { SET_BOOKING_DATA, BookingActions } from './actions';
import { BookingState } from './types';

export const storageKey = 'BOOKING';

const defaultAsset: Asset = {
    make: '',
    model: '',
    variant: '',
    category: { id: '1', code: '1', description: '' },
    images: [{ id: '001', filename: '', etag: '', size: 0, objectName: '', uploadedAt: new Date(), signedUrl: '' }],
    restrictions: {
        pricePerDay: 1000,
        pricePerHour: 0,
        pricePerWeek: 0,
        pricePerMonth: 0,
    },
    technicalSpecifications: {
        mileage: 12000,
        numberOfSeats: 5,
        fuelCapacityLitres: 100,
        numberOfGears: 5,
        length: 10,
        numberOfAxles: 0,
        numberOfWheels: 4,
        hasTailLift: false,
        hasRefrigeration: false,
        fuelType: '',
        features: [],
    },
    id: '',
    identifier: '',
    colour: '',
    status: AssetStatus.Active,
    categoryId: '1',
    additionalItems: [],
};

const defaultBookingDate = {
    start: new Date(),
    end: new Date(),
    days: 1,
};

export const initialState: BookingState = {
    booking: {},
    customer: InitialCustomerFormValues,
    driver: InitialDriverFormValues,
    pickUpDelivery: InitialPickUpDeliveryFormValues,
    asset: defaultAsset,
    date: defaultBookingDate,
    packageSelection: { additionalOptions: [] },
};

export const initializer = (initialValue = initialState): BookingState =>
    JSON.parse(sessionStorage.getItem(storageKey)) || initialValue;

const reducer = (state, action: BookingActions): BookingState => {
    switch (action.type) {
        case SET_BOOKING_DATA:
            return {
                ...state,
                [action.step]: action.data,
            };
        default:
            return state;
    }
};

export default reducer;

import { AppOptions } from '../../api';
import { CustomerDetailsFormValues, DriverDetailsFormValues } from '../../routes/BookRoutes/shared/types';
import { AuthorizationState } from './types';

export const SET_GLOBAL_STATE_DATA = 'SET_GLOBAL_STATE_DATA';
export type SetGlobalStateData<T> = { type: typeof SET_GLOBAL_STATE_DATA; step: string; data: T };

export const setGlobalStateData = <T>(step: string, data: T): SetGlobalStateData<T> => ({
    type: SET_GLOBAL_STATE_DATA,
    step,
    data,
});

export type GlobalActions = SetGlobalStateData<
    AuthorizationState | AppOptions | CustomerDetailsFormValues | DriverDetailsFormValues
>;

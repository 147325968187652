import { createTheme } from '@material-ui/core/styles';

const palette = {
    primary: {
        main: '#1C1C1C',
        contrastText: '#fff',
    },

    secondary: {
        main: '#ed2a74',
        contrastText: '#fff',
    },
};

const datepickerColor = {
    background: palette.secondary.main,
    color: 'white',
};

const basicTheme = createTheme({
    typography: {
        button: {
            textTransform: 'none',
        },
        fontFamily: 'Alegreya Sans',
    },

    palette,

    overrides: {
        MuiButton: {
            contained: {
                boxShadow: 'none',
            },
        },
        MuiCssBaseline: {
            '@global': {
                // tiny date picker override
                '.dp .dp-selected': datepickerColor,

                '.dr-cals .dp .dp-selected': {
                    background: 'transparent',
                    color: 'inherit',
                },

                '.dr-cals, .dp': {
                    paddingTop: 0,

                    '&:before': {
                        display: 'none',
                    },

                    '& .dp-cal-month, & .dp-cal-year': {
                        fontWeight: 500,

                        '&:focus, &:hover': datepickerColor,
                    },

                    '& .dp-close': { '&:focus, &:hover': datepickerColor },

                    '& .dp-clear': { '&:focus, &:hover': datepickerColor },

                    '& .dp-today': { '&:focus, &:hover': datepickerColor },

                    '& .dp-next, & .dp-prev': { '&:focus, &:hover': datepickerColor },

                    '& .dp-month, & .dp-year': { '&:focus, &:hover': datepickerColor },

                    '& .dp-day': {
                        fontWeight: 'lighter',

                        '&:focus, &:hover': datepickerColor,
                    },

                    '& .dr-in-range': {
                        backgroundColor: palette.secondary.main,
                    },

                    '& .dp .dp-selected.dr-selected, & .dp-day.dr-selected, & .dr-selected & .dp-day:hover': {
                        backgroundColor: palette.secondary.main,
                        fontWeight: 'bold',
                        color: 'white',
                    },

                    '& .dp-day-today:after': {
                        display: 'none',
                    },
                },
            },
        },
    },
});

export default basicTheme;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BasicLayout from '../layouts/BasicLayout';
import { LoginRoute, SignUpRoute } from '../routes/IdentityRoute';

const IdentityRouter = () => (
    <BasicLayout>
        <Switch>
            <Route component={LoginRoute} path="/signin" exact />
            <Route component={SignUpRoute} path="/signup" exact />
        </Switch>
    </BasicLayout>
);

export default IdentityRouter;

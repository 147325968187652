import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import usePublic from '../../utilities/usePublic';

const useStyles = makeStyles<Theme, { footerImage: string }>((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '100vh',
            backgroundColor: '#F7F6F3',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        content: {},
        main: {},
        toolbar: theme.mixins.toolbar,
        appBar: {
            backgroundColor: '#000',
            position: 'sticky',
        },

        logo: {
            height: theme.spacing(3.5),
            backgroundSize: 'contain',
        },

        logoLink: {
            margin: `${theme.spacing(2)}, 0`,
            height: theme.spacing(3.5),
        },

        avatarSmall: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },

        navLink: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) },

        signInButton: {
            color: `${theme.palette.primary.main}`,
        },
        wrapper: {
            height: '100vh',
            width: '100%',
            flexGrow: 1,
            backgroundPosition: 'center center',
            backgroundRepeat: 'repeat',
        },

        drawer: {
            width: '100%',
            '&  .MuiDrawer-paper ': {
                width: '100%',
            },
        },

        drawerBackground: {
            backgroundImage:
                'linear-gradient(rgba(0, 0, 0, 0.9),rgba(0, 0, 0, 0.9)),' +
                'url(public/assets/images/searchBackground.png)',
            height: '50vh',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
        },
        drawerLogo: {
            height: theme.spacing(4.5),
            backgroundSize: 'contain',
            marginBottom: theme.spacing(0.5),
        },
        footer: {
            backgroundImage: props => `url(${props.footerImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            marginTop: 'auto',
        },
        itemsContainer: {
            padding: '1rem',
            backgroundColor: '#EDECE8',
            margin: '3.5rem 0 ',
        },
        itemsRow: {
            marginTop: '0.3rem',
        },
        itemsCounter: {
            '& .MuiIconButton-root': {
                borderRadius: '4px',
                border: '1px solid  #c4c4c4',
                margin: '0 1rem',
                [theme.breakpoints.down('sm')]: {
                    width: '24px',
                    height: '24px',
                    marginTop: '0.3rem',
                },
            },
            width: '50%',
        },

        itemsButton: {
            '&.MuiButtonBase-root': {
                color: `${theme.palette.secondary.contrastText}`,
                backgroundColor: `${theme.palette.secondary.main}`,

                '&:hover': {
                    color: `${theme.palette.secondary.contrastText}`,
                    backgroundColor: `${theme.palette.secondary.main}`,
                },
            },
        },
        itemsCounterText: {
            alignSelf: 'center',
            width: '10px',
        },
        itemsLabel: {
            fontWeight: 'lighter',
            fontSize: '1.25rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '.8rem',
            },
        },
    })
);

const useBasicStyles = () => {
    const footerImage = usePublic('assets/images/footer.png');

    return useStyles({ footerImage });
};

export default useBasicStyles;

import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { AssetListDataFragment, useAssetsQuery } from '../../api';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import { ListItemType } from '../../routes/BookRoutes/shared/types';
import LandingBody from './components/LandingBody';
import LandingHeader from './components/LandingHeader';
import { PagingState, FilterState, setLandingPageData } from './shared/actions';
import reducer, { landingPageInitialState } from './shared/reducer';

const LandingPage = () => {
    const [state, dispatch] = useReducer(reducer, landingPageInitialState);
    const { filter, paging } = state;
    const { date } = filter;
    const { start, end } = date;
    const { location, brand, carType } = filter;
    const [minBudget, maxBudget] = filter.budget;
    const { sort: sortPrice, pageSize, page } = paging;

    const { data, refetch } = useAssetsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: { location: 'any', brand: 'any', carType: 'any', bookingDate: { start, end } },
        },
    });

    const handleSearch = useCallback(async () => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    const setPagingState = useCallback((newPagingState: PagingState) => {
        dispatch(setLandingPageData<PagingState>('paging', newPagingState));
    }, []);

    const setFilterState = useCallback(
        (newFilterSate: FilterState) => {
            dispatch(setLandingPageData<FilterState>('filter', newFilterSate));
            setPagingState({ ...paging, page: 1 });
        },
        [paging, setPagingState]
    );

    const filterAssets = useCallback((): AssetListDataFragment[] => {
        const assetList = data?.assets || [];

        const sortFunc = (firstItem: AssetListDataFragment, secondItem: AssetListDataFragment) =>
            (sortPrice === '10' ? firstItem.restrictions.pricePerDay : secondItem.restrictions.pricePerDay) -
            (sortPrice === '10' ? secondItem.restrictions.pricePerDay : firstItem.restrictions.pricePerDay);

        return assetList
            .filter(
                item =>
                    (item.make === brand || brand === 'any') &&
                    (item.categoryId === carType || carType === 'any') &&
                    (item.currentLocation.id === location || location === 'any') &&
                    item.restrictions.pricePerDay >= minBudget &&
                    item.restrictions.pricePerDay <= maxBudget
            )
            .sort(sortFunc);
    }, [data?.assets, sortPrice, brand, carType, location, minBudget, maxBudget]);

    const assets: AssetListDataFragment[] = useMemo(() => {
        const filteredAssets = filterAssets();
        const records = filteredAssets.length;

        if (records > 0) {
            const endIndex = pageSize * page > records ? records : pageSize * page;
            const startIndex = (page - 1) * pageSize;

            return filteredAssets.splice(startIndex, endIndex);
        }

        return filteredAssets;
    }, [filterAssets, page, pageSize]);

    useEffect(() => {
        const filteredAssets = filterAssets();
        const records = filteredAssets.length;
        const pages = Math.ceil(records / pageSize);
        setPagingState({ page, pageSize, records, pages, sort: sortPrice });
    }, [filterAssets, page, pageSize, setPagingState, sortPrice]);

    const filterOptions = useMemo(() => {
        const assetList = data?.assets || [];
        const brands: ListItemType[] = [];
        const carTypes: ListItemType[] = [];

        if (assetList) {
            assetList.forEach(asset => {
                const filteredBrand = brands.filter(brand => brand.value === asset.make);
                if (filteredBrand && filteredBrand.length === 0) {
                    brands.push({ value: asset.make, label: asset.make });
                }
            });

            assetList.forEach(asset => {
                const filteredCarType = carTypes.filter(carType => carType.value === asset.categoryId);
                if (filteredCarType && filteredCarType.length === 0) {
                    carTypes.push({ value: asset.categoryId, label: asset.category?.description });
                }
            });
        }

        return { brands, carTypes };
    }, [data?.assets]);

    useEffect(() => {
        const assetList = data?.assets || [];
        if (assetList.length > 0) {
            const prices = assetList.map(item => item.restrictions.pricePerDay);
            const maxPrice = Math.max(...prices);
            const minPrice = Math.min(...prices);

            setFilterState({ ...filter, budget: [minPrice, maxPrice], budgetMin: minPrice, budgetMax: maxPrice });
        }
    }, [data?.assets]);

    return (
        <>
            <LandingHeader filter={filter} setFilterState={setFilterState} />
            <LandingBody
                assets={assets}
                filter={filter}
                filterOptions={filterOptions}
                paging={paging}
                setFilterState={setFilterState}
                setPagingState={setPagingState}
            />
        </>
    );
};
export default withErrorBoundary(LandingPage);

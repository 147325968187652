import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import usePublic from '../../utilities/usePublic';

const useStyles = makeStyles<Theme, { bookingConfirmationBackground: string }>((theme: Theme) =>
    createStyles({
        card: {
            boxShadow: 'none',
        },
        bookingPage: {
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '4rem',
            paddingBottom: '2rem',
        },
        bookingAside: {
            flex: '0 0 35%',
            '& > :first-child': {
                marginBottom: '0',
                marginRight: '2rem',
            },
            [theme.breakpoints.down('md')]: {
                '> *:not(:last-child)': {
                    marginBottom: '2rem',
                },
            },
        },
        bookingMain: {
            flex: '1 0 calc(65% - 2rem)',
        },
        packageSelectionContainer: {
            borderRight: '1px solid #eaeaea',
            [theme.breakpoints.down('md')]: {
                '& > *:not(:last-child)': {
                    marginBottom: '3rem',
                },
            },
        },
        assetImage: {
            width: '100%',
            marginBottom: '2rem',
        },
        assetImageAside: {
            width: '100%',
        },
        assetImageConfirmation: {
            width: '60%',
        },
        assetContainer: {
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },
        assetDescription: {
            fontWeight: 800,
            fontSize: '1.75rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '.9rem',
            },
        },
        assetSpecs: { fontWeight: 500, fontSize: '1.25rem' },
        currency: {
            fontWeight: 700,
            fontSize: '.8rem',
        },
        price: {
            fontSize: '1.75rem',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: '.9rem',
            },
            paddingLeft: '0.2rem',
        },
        total: {
            fontSize: '1.25rem',
            fontWeight: 800,
        },
        totalLabel: {
            fontSize: '1.25rem',
            fontWeight: 800,
        },
        priceType: {
            fontSize: '1rem',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: '.8rem',
            },
        },
        expanded: {
            transform: 'translateY(2rem)',
            'overflow-y': 'auto',
        },
        groupHeader: {
            fontWeight: 800,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '1.5rem',
            textAlign: 'left',
        },
        groupTitle: {
            fontWeight: 800,
            paddingBottom: '0.4rem',
            fontSize: '1.5rem',
        },
        subGroupTitle: {
            fontWeight: 400,
            fontSize: '1.5rem',
        },
        subCardTitle: {
            fontWeight: 400,
            paddingLeft: '8px',
        },
        subCardValue: {
            color: 'rgb(58, 104, 107)',
            paddingLeft: '32px',
        },
        featureHeader: {
            fontSize: '1.5rem',
            fontWeight: 800,
            [theme.breakpoints.down('sm')]: {
                fontSize: '.9rem',
            },
        },
        featureIcon: {
            width: '32px',
            height: '32px',
            '& rect': {
                fill: `${theme.palette.secondary.main}`,
            },
        },
        featureRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0.3rem 0',
            justifyContent: 'space-between',
            '> *:not(:last-child)': {
                marginRight: '1rem',
            },
        },
        featureLabel: {
            fontWeight: 'lighter',
            fontSize: '1.25rem',
            paddingLeft: '0.5rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '.8rem',
            },
        },
        featureValue: {
            fontWeight: 'bold',
            fontSize: '1.25rem',
            paddingRight: '2.5rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '.7rem',
                paddingRight: '0',
            },
        },

        asideValue: {
            marginLeft: 'auto',
            paddingRight: '1rem',
            color: '#292929',
        },
        asideTotalValue: {
            marginLeft: 'auto',
            paddingRight: '1rem',
            fontSize: '1.25rem',
            fontWeight: 800,
        },
        uploadList: {
            paddingInlineStart: '0',
            listStyleType: 'none',
            '& > *:not(:last-child)': {
                marginBottom: '0.5rem',
            },
        },
        uploadPreview: {
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:last-child)': {
                marginRight: '1rem',
            },
        },
        link: {
            fontWeight: 500,
            textDecoration: 'none',
            color: `${theme.palette.secondary.main}`,
            cursor: 'pointer',
        },
        summaryButton: {
            width: '16.25rem',
            marginLeft: '1rem',
            marginRight: '1rem',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                width: '8.125rem',
                marginLeft: '.5rem',
                marginRight: '.5rem',
            },
        },
        placeholderIcon: {
            rect: {
                fill: `${theme.palette.secondary.main}`,
            },
        },
        divider: {
            margin: '0.1rem 0',
        },
        label: {
            color: '#292929',
            opacity: '0.5',
        },
        pricingDetails: {
            '& > *:not(:last-child)': {
                position: 'relative',
                '&::after': {
                    content: "' '",
                    position: 'absolute',
                    right: '0',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    height: '50%',
                    width: '1px',
                    backgroundColor: 'rgb(226, 226, 226)',
                },
            },
        },

        rentalDrawer: {
            backgroundColor: '#F7F6F3',
        },

        rentalDrawerContainer: {
            padding: '4rem 3rem',
        },

        rentalListNumbers: {
            '&::marker': {
                color: 'red',
            },
            marginBottom: '1rem',
        },
        viewBookingDetails: {
            opacity: 0.75,
        },
        appvantageLogo: {
            width: '180px',
            height: '30px',
        },
        bookingConfirmation: {
            backgroundImage: props => `url(${props.bookingConfirmationBackground})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        pickUpDeliveryTitle: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(1),
            fontSize: '1.3rem',
            fontWeight: 'bold',
        },
    })
);

const useBookingStyles = () => {
    const bookingConfirmationBackground = usePublic('assets/images/bookingConfirmationBackground.svg');

    return useStyles({ bookingConfirmationBackground });
};

export default useBookingStyles;

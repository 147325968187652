import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useState, useCallback } from 'react';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { AdditionalItem as AdditionalItemType, AdditionalOption } from '../../../routes/BookRoutes/shared/types';
import useFormatting from '../../../utilities/useFormatting';
import Counter from './Counter';

type AdditionalItemProps = {
    item: AdditionalItemType;
    onChange?(value: AdditionalOption): void;
};

const AdditionalItemFC = ({ item, onChange }: AdditionalItemProps) => {
    const { price, label, id }: AdditionalItemType = item;
    const [totalPrice, setTotalPrice] = useState(0);
    const classes = useBookingStyles();
    const basicStyles = useBasicStyles();
    const { formatCurrencyFixed } = useFormatting();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleChange = useCallback(
        value => {
            setTotalPrice(value * price);
            onChange({ id, label, price, quantity: value, totalPrice: price * value });
        },
        [id, label, onChange, price]
    );

    return (
        <Grid className={basicStyles.itemsRow} container>
            <Grid xs={10} item>
                <Box
                    sx={{ display: 'flex', flexDirection: matches ? 'row' : 'column', justifyContent: 'space-between' }}
                >
                    <Typography className={basicStyles.itemsLabel} component="h6">
                        <span>
                            {label} (${price} per item)
                        </span>
                    </Typography>
                    <Counter initialValue={0} onChange={handleChange} />
                </Box>
            </Grid>
            <Grid xs={2} item>
                <Typography className={classes.featureValue} component="h6" style={{ textAlign: 'right' }}>
                    {formatCurrencyFixed(totalPrice)}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default AdditionalItemFC;

import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useIdentityStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { paddingTop: '7rem', paddingBottom: '2rem', display: 'flex', flexDirection: 'row' },
        content: {},
        main: {},
        toolbar: theme.mixins.toolbar,
        appBar: {
            width: '100%',
            backgroundColor: alpha('#1C1C1C', 0.65),
        },
        card: {
            boxShadow: 'none',
            border: 'none',
            borderRadius: '30px',
            opacity: '.95',
            width: '100%',
            maxWidth: '450px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        signUpCard: {
            boxShadow: 'none',
            border: 'none',
            borderRadius: '30px',
            opacity: '.95',
            width: '100%',
            maxWidth: '600px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        signUpModalCard: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: alpha('#1C1C1C', 0.8),
            color: `${theme.palette.secondary.contrastText}`,
            padding: '1.5rem',
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '20px',
        },
        buttonGroup: {
            borderRadius: '0',
            '& > button': {
                borderRadius: 0,
            },
        },
        buttonGroupFormControl: {
            flexDirection: 'row',
            alignItems: 'center',
            '& label + .MuiInput-formControl': {
                marginTop: '0rem',
                marginLeft: '1rem',
                flexGrow: 1,
            },
        },
        buttonGroupButton: {
            fontWeight: 'normal',
            height: '45px',
            fontSize: '1rem',
            textTransform: 'none',
        },
        cardContent: {
            padding: '1.5 rem',
            '&:last-child': {
                paddingBottom: '1.5rem',
            },
            '> *:not(:last-child)': {
                marginBottom: '1.5rem',
            },
        },
        cardTitle: {
            color: `${theme.palette.secondary.main}`,
            fontWeight: 800,
            fontSize: '1.5rem',
            marginBlockStart: '0px',
            marginBlockEnd: '1rem',
        },
        link: {
            fontWeight: 500,
            textDecoration: 'none',
            color: `${theme.palette.secondary.main}`,
        },
    })
);

export default useIdentityStyles;

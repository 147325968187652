import { CardMedia, Card, CardContent, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';
import usePublic from '../../../utilities/usePublic';

type PromotionsCardProps = {
    title: string;
    description: string;
    image: string;
    to: string;
};

const PromotionsCard = ({ title, description, image, to, ...props }: PromotionsCardProps) => {
    const classes = useOverviewStyles();
    const { t } = useTranslation('overviewPage');
    const imagePath = usePublic(image);

    return (
        <Card className={classes.promotionCard} {...props}>
            <CardMedia className={classes.promotionCardMedia} image={imagePath} />
            <CardContent>
                <Typography className={classes.promotionCardTitle} component="h5" variant="body2">
                    {title}
                </Typography>
                <Typography className={classes.promotionCardTitle} component="p" variant="body2">
                    {description}
                </Typography>
                <Link className={classes.promotionCardLearnMore} to={to}>
                    {t('label.learnMore')} <ChevronRight />
                </Link>
            </CardContent>
        </Card>
    );
};

export default PromotionsCard;

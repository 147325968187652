import { Grid } from '@material-ui/core';
import { AssetListDataFragment } from '../../../api';
import { AdditionalItem } from '../../../routes/BookRoutes/shared/types';
import AdditionalItems from './AdditionalItems';
import Features from './Features';
import RentalTerms from './RentTerm';

interface AssetFeaturesProps {
    asset: AssetListDataFragment;
    additionalItems: AdditionalItem[];
    onChange?: any;
}

const AssetFeatures = ({ asset, additionalItems, onChange }: AssetFeaturesProps) => (
    <Grid container>
        <Grid xs={12} item>
            <Features asset={asset} />
        </Grid>
        <AdditionalItems additionalItems={additionalItems} onChange={onChange} />
        <RentalTerms />
    </Grid>
);
export default AssetFeatures;

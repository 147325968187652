import { Switch, Route } from 'react-router';
import BookingProvider from '../routes/BookRoutes/shared/BookingProvider';
import LandingPageRoute from '../routes/LandingPageRoute';
import BookingRouter from './BookRouter';

const BookContextRouter = () => (
    <BookingProvider>
        <Switch>
            <Route component={LandingPageRoute} path="/" exact />
            <Route component={BookingRouter} path={['/book']} />
        </Switch>
    </BookingProvider>
);

export default BookContextRouter;

import { Typography, Container, Grid, Button, Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePickerField, LocationField } from '../../../components/inputs';
import { calculateDays } from '../../../routes/BookRoutes/shared/calculate';
import { getCurrentDate } from '../../../utilities/commonFunctions';
import { FilterState } from '../shared/actions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        containerBackground: {
            [theme.breakpoints.up('sm')]: {
                height: '65vh',
            },
            backgroundImage:
                'linear-gradient(90deg, rgb(0, 0, 0) 0%,' +
                'rgba(0, 0, 0, 0) 100%),' +
                'url(public/assets/images/searchBackground.png)',
            height: '100vh',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        content: {
            height: '100%',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        headerTitle: {
            fontWeight: 'bold',
            fontSize: '43px',
            color: theme.palette.secondary.main,
            whiteSpace: 'pre-line',
        },
        headerContent: {
            width: '60%',
            fontSize: '17px',
            color: theme.palette.secondary.contrastText,
        },
        headerTopGrid: {
            marginTop: 'auto',
            marginBottom: 'auto',
        },

        highlightedWord: {
            color: theme.palette.secondary.main,
        },
    })
);

const initialValues = {
    date: {
        start: getCurrentDate(),
        end: getCurrentDate(),
        days: 1,
    },
    filter: { location: 'any', brand: 'any', carType: 'any' },
};

type LandingHeaderProps = {
    setFilterState: (filterState: FilterState) => void;
    filter: FilterState;
};

const LandingHeader = ({ setFilterState, filter }: LandingHeaderProps) => {
    const styles = useStyles();
    const { t } = useTranslation(['landingPage', 'common']);

    const handleSearch = useCallback(
        data => {
            const { filter: dataFilter, date: dataDate } = data;
            const { start, end } = dataDate;
            const { location } = dataFilter;
            const days = calculateDays(start, end);
            setFilterState({ ...filter, date: { ...dataDate, days }, location });
        },
        [setFilterState, filter]
    );

    return (
        <div className={styles.containerBackground}>
            <Container className={styles.content} maxWidth={false}>
                <Box display="flex" flexDirection="column" height="100%">
                    <Box my="auto">
                        <Typography className={styles.headerTitle}>{t('landingPage:bookVehiclesHeader')}</Typography>
                        <Typography className={styles.headerContent}>{t('landingPage:bookVehiclesBody')}</Typography>
                    </Box>
                    <Box>
                        <Formik initialValues={initialValues} onSubmit={handleSearch}>
                            <Form>
                                <Grid direction="row" spacing={2} container>
                                    <Grid sm={7} xs={12} item>
                                        <DateRangePickerField
                                            format={t('common:formats.datePickerFormat')}
                                            id="date"
                                            name="date"
                                            placeholder="Search Dates"
                                            variant="basic"
                                            disableUnderline
                                        />
                                    </Grid>
                                    <Grid sm={3} xs={12} item>
                                        <LocationField
                                            name="filter.location"
                                            placeholder="Select Location"
                                            variant="basic"
                                        />
                                    </Grid>
                                    <Grid sm={2} xs={12} item>
                                        <Button color="secondary" type="submit" variant="contained" fullWidth>
                                            {t('button.search')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Formik>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default LandingHeader;

import { Button } from '@material-ui/core';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useGlobalContext } from '../../utilities/shared/GlobalContextProvider';
import { setGlobalStateData } from '../../utilities/shared/actions';
import UserMenu from './UserMenu';
import useBasicStyles from './useBasicStyles';

const useUserProfileMenu = () => {
    const { state, dispatch } = useGlobalContext();
    const { authorization } = state;
    const classes = useBasicStyles();
    const { t } = useTranslation(['appBar']);
    const history = useHistory();

    const signInSignOut = useCallback(() => {
        dispatch(setGlobalStateData('authorization', { token: '' }));
        history.push('/signIn');
    }, [history, dispatch]);

    const profileMenu = useMemo(() => {
        if (!authorization?.token) {
            return (
                <Button className={classes.navLink} color="secondary" onClick={signInSignOut} variant="contained">
                    {t('signIn')}
                </Button>
            );
        }

        return <UserMenu handleSignOut={signInSignOut} />;
    }, [authorization, classes.navLink, signInSignOut, t]);

    return profileMenu;
};

export default useUserProfileMenu;

export const SET_LANDING_PAGE_DATA = 'SET_LANDING_PAGE_DATA';
export type SetLandingPageData<T> = { type: typeof SET_LANDING_PAGE_DATA; step: string; data: T };

export type PagingState = {
    page: number;
    pageSize: number;
    pages: number;
    records: number;
    sort: string;
};

export type FilterState = {
    location: string;
    brand: string;
    carType: string;
    budget: number[];
    budgetMin: number;
    budgetMax: number;
    date: { start: Date; end: Date; days: number };
};

export type LandingPageState = {
    paging: PagingState;
    filter: FilterState;
};

export const setLandingPageData = <T>(step: string, data: T): SetLandingPageData<T> => ({
    type: SET_LANDING_PAGE_DATA,
    step,
    data,
});

export type LandingPageActions = SetLandingPageData<PagingState | FilterState>;

import { Box, Container, Grid, Typography } from '@material-ui/core';
import { Facebook, Instagram, YouTube } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useBasicStyles from './useBasicStyles';

const Footer = () => {
    const classes = useBasicStyles();

    const { t } = useTranslation(['appBar']);

    return (
        <Box className={classes.footer} color="white" component="footer">
            <Container maxWidth={false}>
                <Grid spacing={2} container>
                    <Grid sm={3} xs={12} item>
                        <Box>
                            <Typography variant="h6"> {t('appBar:aboutUs')}</Typography>
                        </Box>
                        <Box> {t('appBar:whoAreWe')}</Box>
                    </Grid>
                    <Grid sm={3} xs={12} item>
                        <Box mb={1} sx={{ textAlign: 'left' }}>
                            <Typography variant="h6">{t('appBar:Discover')}</Typography>
                        </Box>
                        <Box mb={0.5} sx={{ textAlign: 'left' }}>
                            {t('appBar:announcement')}
                        </Box>
                        <Box mb={0.5} sx={{ textAlign: 'left' }}>
                            {t('appBar:referralProgram')}
                        </Box>
                    </Grid>
                    <Grid sm={3} xs={12} item>
                        <Box mb={1} sx={{ textAlign: 'left' }}>
                            <Typography variant="h6">{t('appBar:support')}</Typography>
                        </Box>
                        <Box mb={0.5} sx={{ textAlign: 'left' }}>
                            {t('appBar:contactUs')}
                        </Box>
                        <Box mb={0.5} sx={{ textAlign: 'left' }}>
                            {t('appBar:faq')}
                        </Box>
                        <Box mb={0.5} sx={{ textAlign: 'left' }}>
                            {t('appBar:sitemap')}
                        </Box>
                    </Grid>
                    <Grid sm={3} xs={12} item>
                        <Box mb={1} sx={{ textAlign: 'left' }}>
                            <Typography variant="h6">{t('appBar:followUs')}</Typography>
                        </Box>
                        <Grid spacing={3} container>
                            <Grid item>
                                <Instagram />
                            </Grid>
                            <Grid item>
                                <Facebook />
                            </Grid>
                            <Grid item>
                                <YouTube />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;

import { FormControl, InputLabel, Select, MenuItem, SelectProps } from '@material-ui/core';

import React from 'react';
import { variantComponent } from '../../../components/inputs/shared';
import useFieldStyles from '../../../components/inputs/useFieldStyles';

export interface SelectFieldProps extends Omit<SelectProps, 'onChange' | 'variant'> {
    options: { label: string; value: string }[];
    // override variant
    variant?: keyof typeof variantComponent;
    onChange(value: string): void;
}

const SelectNavLink = ({ label, placeholder, options, variant = 'standard', onChange, ...props }: SelectFieldProps) => {
    const styles = useFieldStyles();
    const InputComponent = variantComponent[variant];

    return (
        <FormControl fullWidth>
            {label && (
                <InputLabel className={styles.label} shrink>
                    {label}
                </InputLabel>
            )}
            <Select
                onChange={e => {
                    onChange(e.target.value.toString());
                }}
                // spread input props
                {...props}
                // update elect style
                className={styles.select}
                // set variant input component
                input={<InputComponent />}
                displayEmpty
            >
                {placeholder && (
                    <MenuItem value="" disabled>
                        <div className={styles.placeholderOpacity}>{placeholder}</div>
                    </MenuItem>
                )}

                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectNavLink;

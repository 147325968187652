import { Grid, Typography } from '@material-ui/core';

import React from 'react';
import { useTranslation } from 'react-i18next';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { CustomerDetailsFormValues } from '../../../routes/BookRoutes/shared/types';
import CustomerDetailsForm from './CustomerDetailsForm';
import MyInfoBox from './MyInfoBox';

interface CustomerDetailsProps {
    initialValues: CustomerDetailsFormValues;
}

const CustomerDetails = ({ initialValues }: CustomerDetailsProps) => {
    const classes = useBookingStyles();
    const { t } = useTranslation('customerPage');

    return (
        <Grid direction="column" spacing={2} container>
            <Grid xs={12} item>
                <Typography className={classes.groupTitle} color="secondary" component="p">
                    {t('title')}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <MyInfoBox />
            </Grid>
            <Grid xs={12} item>
                <CustomerDetailsForm />
            </Grid>
        </Grid>
    );
};

export default CustomerDetails;

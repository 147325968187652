import { InputAdornment } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import React from 'react';
import useFieldStyles from './useFieldStyles';

const DateAdornment = props => {
    const styles = useFieldStyles();

    return (
        <InputAdornment className={styles.adornment} {...props}>
            <CalendarToday />
        </InputAdornment>
    );
};

export default DateAdornment;

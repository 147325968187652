import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Option } from '../../../api';
import { SelectField, DatePickerField, ViewOnlyDateField } from '../../../components/inputs';

interface LicenseClasssProps {
    name: string;
    licenseClassOptions: Option[];
    viewOnly?: boolean;
}

const LicenseClass = ({ name, licenseClassOptions, viewOnly = false }: LicenseClasssProps) => {
    const { t } = useTranslation('driverPage');

    return (
        <>
            <Grid md={6} xs={12} item>
                <SelectField
                    label={t('label.class')}
                    name={`${name}.class`}
                    options={licenseClassOptions}
                    placeholder={t('placeholder.class')}
                    viewOnly={viewOnly}
                    fullWidth
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                {viewOnly && <ViewOnlyDateField label={t('label.issueDate')} name={`${name}.issueDate`} />}
                <div style={{ display: viewOnly ? 'none' : '' }}>
                    <DatePickerField
                        label={t('label.issueDate')}
                        name={`${name}.issueDate`}
                        placeholder={t('placeholder.issueDate')}
                        required
                    />
                </div>
            </Grid>
        </>
    );
};

export default LicenseClass;

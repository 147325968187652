import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DatePickerField, ViewOnlyDateField } from '../../../components/inputs';

interface LicenseSuspensionProps {
    name: string;
    viewOnly?: boolean;
}

const LicenseSuspension = ({ name, viewOnly = false }: LicenseSuspensionProps) => {
    const { t } = useTranslation('driverPage');

    return (
        <>
            <Grid md={6} xs={12} item>
                {viewOnly && (
                    <ViewOnlyDateField label={t('label.suspensionStart')} name={`${name}.suspension.startDate`} />
                )}
                <div style={{ display: viewOnly ? 'none' : '' }}>
                    <DatePickerField
                        label={t('label.suspensionStart')}
                        name={`${name}.suspension.startDate`}
                        placeholder={t('placeholder.suspensionStart')}
                    />
                </div>
            </Grid>
            <Grid md={6} xs={12} item>
                {viewOnly && <ViewOnlyDateField label={t('label.suspensionEnd')} name={`${name}.suspension.endDate`} />}
                <div style={{ display: viewOnly ? 'none' : '' }}>
                    <DatePickerField
                        label={t('label.suspensionEnd')}
                        name={`${name}.suspension.endDate`}
                        placeholder={t('placeholder.suspensionEnd')}
                    />
                </div>
            </Grid>
            <Grid md={6} xs={12} item>
                {viewOnly && (
                    <ViewOnlyDateField
                        label={t('label.disqualificationStart')}
                        name={`${name}.disqualification.startDate`}
                    />
                )}
                <div style={{ display: viewOnly ? 'none' : '' }}>
                    <DatePickerField
                        label={t('label.disqualificationStart')}
                        name={`${name}.disqualification.startDate`}
                        placeholder={t('placeholder.disqualificationStart')}
                    />
                </div>
            </Grid>
            <Grid md={6} xs={12} item>
                {viewOnly && (
                    <ViewOnlyDateField
                        label={t('label.disqualificationEnd')}
                        name={`${name}.disqualification.endDate`}
                    />
                )}
                <div style={{ display: viewOnly ? 'none' : '' }}>
                    <DatePickerField
                        label={t('label.disqualificationEnd')}
                        name={`${name}.disqualification.endDate`}
                        placeholder={t('placeholder.disqualificationEnd')}
                    />
                </div>
            </Grid>
        </>
    );
};

export default LicenseSuspension;

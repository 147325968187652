import { InitialCustomerFormValues, InitialDriverFormValues, InitialPickUpDeliveryFormValues } from '../constants';
import { SET_GLOBAL_STATE_DATA, GlobalActions } from './actions';
import { GlobalState } from './types';

export const storageKey = 'GLOBAL';

export const initialState: GlobalState = {
    authorization: {},
    appOptions: {
        businessTypes: [],
        countries: [],
        genders: [],
        gendersManual: [],
        races: [],
        licenseClasses: [],
        licenseValidity: [],
        nationalities: [],
        salutations: [],
        maritalStatuses: [],
        addressTypes: [],
    },
    customer: InitialCustomerFormValues,
    driver: InitialDriverFormValues,
    pickUpDelivery: InitialPickUpDeliveryFormValues,
};

export const initializer = (initialValue = initialState): GlobalState =>
    JSON.parse(sessionStorage.getItem(storageKey)) || initialValue;

const reducer = (state, action: GlobalActions): GlobalState => {
    switch (action.type) {
        case SET_GLOBAL_STATE_DATA:
            return {
                ...state,
                [action.step]: action.data,
            };
        default:
            return state;
    }
};

export default reducer;

import { Grid, Card, CardContent, Typography, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizontalIcon from '@material-ui/icons/MoreHoriz';
import { useTranslation } from 'react-i18next';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';
import useFormatting from '../../../utilities/useFormatting';
import ImageWithFallback from './ImageWithFallback';

interface BookingItemProps {
    category: string;
    identifier: string;
    imageUrl: string;
    make: string;
    model: string;
    numberOfSeats: string;
    variant: string;
    fallbackImage: string;
    rentalPeriodStart: Date;
    rentalPeriodEnd: Date;
}

const BookingItem = ({
    category,
    identifier,
    make,
    model,
    variant,
    numberOfSeats,
    imageUrl,
    fallbackImage,
    rentalPeriodStart,
    rentalPeriodEnd,
}: BookingItemProps) => {
    const classes = useOverviewStyles();
    const { t } = useTranslation('overviewPage');
    const { formatDate } = useFormatting();

    return (
        <Card className={classes.bookingItemCard}>
            <CardContent>
                <div className={classes.bookingItemContainer}>
                    <Grid container>
                        <Grid md={4} sm={4} item>
                            <div className={classes.imageContainer}>
                                <ImageWithFallback
                                    className={classes.assetImage}
                                    fallbackSrc={fallbackImage}
                                    src={imageUrl}
                                />
                            </div>
                        </Grid>
                        <Grid md={7} sm={7} item>
                            <div className={classes.bookingItemAssetDetails}>
                                <span className={classes.bookingItemAssetDescription}>
                                    {make} {model} {variant}
                                </span>
                                <span className={classes.bookingItemAssetTechDetails}>
                                    {category} {numberOfSeats} Seater
                                </span>
                                <span className={classes.bookingItemAssetIdentifier}>{identifier}</span>
                                <Typography className={classes.bookingItemLabel} component="span" variant="body1">
                                    {t('label.rentalPeriod')}
                                </Typography>
                                <Grid spacing={1} container>
                                    <Grid xs={8} item>
                                        <Typography color="primary">Start Date</Typography>
                                    </Grid>
                                    <Grid xs={4} item>
                                        <Typography color="primary" component="p">
                                            {formatDate(rentalPeriodStart)}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8} item>
                                        <Typography color="primary">End Date</Typography>
                                    </Grid>
                                    <Grid xs={4} item>
                                        <Typography color="primary" component="p">
                                            {formatDate(rentalPeriodEnd)}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Button
                                            className={classes.bookingItemButton}
                                            color="secondary"
                                            variant="contained"
                                            fullWidth
                                        >
                                            {t('label.viewDetails')}
                                        </Button>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Button
                                            className={classes.bookingItemButton}
                                            color="secondary"
                                            variant="outlined"
                                            fullWidth
                                        >
                                            {t('label.cancelBooking')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid md={1} sm={1} item>
                            <IconButton aria-label="settings">
                                <MoreHorizontalIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    );
};
export default BookingItem;

import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useBasicStyles from '../../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../../layouts/useCommonStyles';

export type LoginFormSubmitProps = { isSubmitting: boolean; hasError: boolean };

const LoginFormSubmit = ({ isSubmitting, hasError }: LoginFormSubmitProps) => {
    const { t } = useTranslation(['loginPage']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();

    return (
        <Grid xs={12} item>
            <Button
                className={basicStyles.signInButton}
                classes={{ disabled: commonStyles.disabledSecondaryButton }}
                color="secondary"
                disabled={isSubmitting || hasError}
                size="large"
                type="submit"
                variant="contained"
                fullWidth
            >
                {t('loginPage:submitButton')}
            </Button>
        </Grid>
    );
};

export default LoginFormSubmit;

import { Grid, Typography, Drawer, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';

interface ElementProps {
    children: ReactElement | ReactNode;
}

const Title = ({ children }: ElementProps) => (
    <Typography color="secondary" component="h2" style={{ marginLeft: '1.25rem', fontWeight: 800, fontSize: '1.3rem' }}>
        {children}
    </Typography>
);

const Heading = ({ children }: ElementProps) => (
    <Typography color="secondary" component="h6" style={{ fontWeight: 'bold' }}>
        {children}
    </Typography>
);

const List = ({ children }: ElementProps) => <ol style={{ paddingLeft: '2rem' }}>{children}</ol>;

const SubList = ({ children }: ElementProps) => <li style={{ listStyleType: 'lower-alpha' }}>{children}</li>;

type RentalDrawerProps = {
    onClose: VoidFunction;
    open: boolean;
};

const RentalTermsDrawer = ({ onClose, ...props }: RentalDrawerProps) => {
    const { t } = useTranslation('booking');

    const classes = useBookingStyles();
    const MainList = useMemo(
        () =>
            ({ children }: ElementProps) =>
                <li className={classes.rentalListNumbers}>{children}</li>,
        [classes]
    );

    return (
        <Drawer anchor="right" classes={{ paper: classes.rentalDrawer }} onClose={onClose} {...props}>
            <Grid className={classes.rentalDrawerContainer} direction="column" container>
                <Grid container item>
                    <Grid xs={12} item>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Title>{t('rentTerm.title')}</Title>
                            <IconButton onClick={onClose}>
                                <CloseIcon fill="#000" />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>

                <List>
                    <MainList>
                        <Heading>{t('rentTerm.hirerHeading')}</Heading>
                        <p>{t('rentTerm.hirerSubHeading')}</p>
                        <List>
                            <Trans i18nKey="booking:rentTerm:hirerList">
                                <SubList>Be 24-60 years old with at least 2 years of driving experience. </SubList>
                                <SubList>
                                    Singaporean: Hirer and any additional driver have to produce original Singapore NRIC
                                    and original Singapore driving license upon collection of vehicle.
                                </SubList>
                                <SubList>
                                    Foreigner: Hirer and any additional driver have to produce original passport or
                                    other permit and original valid country driving license or international driving
                                    permit.
                                </SubList>
                                <SubList>Only authorised drivers are insured to drive our rental vehicle. </SubList>
                            </Trans>
                        </List>
                    </MainList>

                    <MainList>
                        <Heading>{t('rentTerm.ratesHeading')}</Heading>
                        <List>
                            <Trans i18nKey="booking:rentTerm:ratesList">
                                <SubList>
                                    All prices quoted are in Singapore dollars and are subject to 7% GST (Goods and
                                    Services Tax).
                                </SubList>
                                <SubList>
                                    Singaporean: Hirer and any additional driver have to produce original Singapore NRIC
                                    and original Singapore driving license upon collection of vehicle.
                                </SubList>
                                <SubList>
                                    Foreigner: Hirer and any additional driver have to produce original passport or
                                    other permit and original valid country driving license or international driving
                                    permit.
                                </SubList>
                                <SubList>Only authorised drivers are insured to drive our rental vehicle. </SubList>
                            </Trans>
                        </List>
                    </MainList>

                    <MainList>
                        <Heading>{t('rentTerm.malaysiaDriveHeading')}</Heading>
                        <List>
                            <Trans i18nKey="booking:rentTerm:malaysiaDriveList">
                                <SubList>
                                    All our vehicles can be driven to Malaysia up to Kuala Lumpur only with additional
                                    surcharge of $40 per day.
                                </SubList>
                                <SubList>
                                    Towing service will be provided in the event of a breakdown / accident in Malaysia.
                                    No replacement car will be given in Malaysia.
                                </SubList>
                            </Trans>
                        </List>
                    </MainList>

                    <MainList>
                        <Heading>{t('rentTerm.fuelHeading')}</Heading>
                        <List>
                            <Trans i18nKey="booking:rentTerm:fuelList">
                                <SubList>
                                    Upon returning of the vehicle, Hirer should refill the fuel back to the same level
                                    as per given. Any petrol shortage will be charged at $20
                                </SubList>
                            </Trans>
                        </List>
                    </MainList>
                </List>
            </Grid>
        </Drawer>
    );
};

export default RentalTermsDrawer;

import { Grid, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import useIdentityStyles from '../../../layouts/BasicLayout/useIdentityStyles';

const SignUpFooter = () => {
    const classes = useIdentityStyles();
    const history = useHistory();

    return (
        <Grid xs={12} item>
            <Typography style={{ fontWeight: 'lighter', textAlign: 'center', paddingTop: '20px' }}>
                <Trans i18nKey="signUpPage:alreadySignedUp">
                    {'Have an account with us yet? '}
                    <Link
                        className={classes.link}
                        color="secondary"
                        onClick={() => history.push('/signin')}
                        to="/signin"
                    >
                        {'Sign In '}
                    </Link>
                    now!
                </Trans>
            </Typography>
        </Grid>
    );
};

export default SignUpFooter;

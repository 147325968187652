import { Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryMethod, useListLocationsQuery } from '../../../api';
import { useLoadingController } from '../../../components/LoadingProvider';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { BookingDate, DeliveryOptions, PickUpAndDeliveryFormValues } from '../../../routes/BookRoutes/shared/types';
import useFormatting from '../../../utilities/useFormatting';

interface PickupAndDeliverySummaryProps {
    bookingDate: BookingDate;
    fullHeight?: boolean;
    pickUpDelivery?: PickUpAndDeliveryFormValues;
}

const formatAddress = address => `${address.street} ${address.building} ${address.unit}, ${address.postalCode}`;

const PickupAndDeliverySummary = ({ bookingDate, fullHeight, pickUpDelivery }: PickupAndDeliverySummaryProps) => {
    const { data, loading } = useListLocationsQuery();
    useLoadingController(loading);
    const classes = useBookingStyles();
    const { t } = useTranslation('pickupAndDeliverySummary');
    const { formatDate } = useFormatting();

    const pickupLocation = useMemo(() => {
        if (!data) {
            return '-';
        }

        switch (pickUpDelivery.deliveryMethod) {
            case DeliveryMethod.Pickup: {
                const { listLocations } = data;
                const location = listLocations.find(e => e.id === pickUpDelivery.pickUpLocation);

                if (location) {
                    return formatAddress(location.address);
                }

                return '-';
            }

            case DeliveryMethod.Delivery:
                return formatAddress(pickUpDelivery.deliveryDetails.location);

            default:
                return '-';
        }
    }, [pickUpDelivery, data]);

    const dropOffPoint = useMemo(() => {
        if (!data) {
            return '-';
        }

        switch (pickUpDelivery.deliveryMethod) {
            case DeliveryMethod.Pickup: {
                if (pickUpDelivery.dropOffSameLocationAsPickUp) {
                    return pickupLocation;
                }
                const { listLocations } = data;
                const location = listLocations.find(e => e.id === pickUpDelivery.pickUpLocation);

                if (location) {
                    return formatAddress(location.address);
                }

                return '-';
            }

            case DeliveryMethod.Delivery: {
                switch (pickUpDelivery.deliveryOption) {
                    case DeliveryOptions.OneWay: {
                        const { listLocations } = data;
                        const location = listLocations.find(e => e.id === pickUpDelivery.returnDetails.location);

                        if (location) {
                            return formatAddress(location.address);
                        }

                        return '-';
                    }
                    case DeliveryOptions.TwoWay:
                        return formatAddress(pickUpDelivery.returnDetails.location);
                    default:
                        return '-';
                }
            }

            default:
                return '-';
        }
    }, [pickUpDelivery, data, pickupLocation]);

    return (
        <Card className={classes.card} style={{ height: fullHeight === true ? '100%' : '' }}>
            <CardContent>
                <Grid spacing={1} container>
                    <Grid xs={12} item>
                        <Typography className={classes.groupTitle} color="secondary" component="p">
                            {t('title')}
                        </Typography>
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            {t('label.startRentDate')}
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {formatDate(bookingDate?.start)}
                        </Typography>
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            {t('label.pickupLocation')}
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {pickupLocation || '-'}
                        </Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <Divider className={classes.divider} component="hr" variant="fullWidth" />
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            {t('label.endRentDate')}
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {formatDate(bookingDate?.end)}
                        </Typography>
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            {t('label.dropOffPoint')}
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {dropOffPoint || '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PickupAndDeliverySummary;

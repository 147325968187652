import { Grid, Container, Tab, Tabs } from '@material-ui/core';
import { useState, useMemo } from 'react';
import { useBookingsQuery } from '../../../api';
import TabPanel from '../../../components/TabPanel';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';
import { getCurrentDate } from '../../../utilities/commonFunctions';
import usePublic from '../../../utilities/usePublic';
import { BookingList } from '../../Booking/components';

const BookingsPage = () => {
    const { data } = useBookingsQuery();
    const fallbackImage = usePublic('assets/images/notAvailable.png');

    const bookings = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.bookings;
    }, [data]);

    const [tab, setTab] = useState<number>(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const upcomingBookings = useMemo(() => {
        const currentDate = getCurrentDate();

        return bookings
            .filter(booking => new Date(booking.rentalStartDate) > currentDate)
            .map(item => ({
                category: item.asset.category?.description,
                identifier: item.asset.identifier,
                imageUrl: item.asset.images.length > 0 ? item.asset.images[0].signedUrl : '',
                make: item.asset.make,
                model: item.asset.model,
                numberOfSeats: item.asset.technicalSpecifications.numberOfSeats?.toString(),
                variant: item.asset.variant,
                fallbackImage,
                rentalPeriodStart: new Date(item.rentalStartDate),
                rentalPeriodEnd: new Date(item.rentalEndDate),
            }));
    }, [bookings, fallbackImage]);

    const activeBookings = useMemo(() => {
        const currentDate = getCurrentDate();

        return bookings
            .filter(
                booking =>
                    currentDate >= new Date(booking.rentalStartDate) && currentDate <= new Date(booking.rentalEndDate)
            )
            .map(item => ({
                category: item.asset.category?.description,
                identifier: item.asset.identifier,
                imageUrl: item.asset.images.length > 0 ? item.asset.images[0].signedUrl : '',
                make: item.asset.make,
                model: item.asset.model,
                numberOfSeats: item.asset.technicalSpecifications.numberOfSeats?.toString(),
                variant: item.asset.variant,
                fallbackImage,
                rentalPeriodStart: new Date(item.rentalStartDate),
                rentalPeriodEnd: new Date(item.rentalEndDate),
            }));
    }, [bookings, fallbackImage]);

    const pastBookings = useMemo(() => {
        const currentDate = getCurrentDate();

        return bookings
            .filter(booking => currentDate > new Date(booking.rentalEndDate))
            .map(item => ({
                category: item.asset.category?.description,
                identifier: item.asset.identifier,
                imageUrl: item.asset.images.length > 0 ? item.asset.images[0].signedUrl : '',
                make: item.asset.make,
                model: item.asset.model,
                numberOfSeats: item.asset.technicalSpecifications.numberOfSeats?.toString(),
                variant: item.asset.variant,
                fallbackImage,
                rentalPeriodStart: new Date(item.rentalStartDate),
                rentalPeriodEnd: new Date(item.rentalEndDate),
            }));
    }, [bookings, fallbackImage]);

    return (
        <Container maxWidth="md">
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    <Tabs indicatorColor="primary" onChange={handleChange} textColor="primary" value={tab}>
                        <Tab label="Upcoming Bookings" />
                        <Tab label="Active Bookings" />
                        <Tab label="Past Bookings" />
                    </Tabs>

                    <TabPanel index={0} value={tab}>
                        {upcomingBookings && <BookingList bookings={[...upcomingBookings]} />}
                    </TabPanel>
                    <TabPanel index={1} value={tab}>
                        {activeBookings && <BookingList bookings={[...activeBookings]} />}
                    </TabPanel>
                    <TabPanel index={2} value={tab}>
                        {pastBookings && <BookingList bookings={[...pastBookings]} />}
                    </TabPanel>
                </Grid>
            </Grid>
        </Container>
    );
};

export default withErrorBoundary(BookingsPage);

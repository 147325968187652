import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as format from './format';

const useFormatting = () => {
    // TODO supply precisions from config/api

    const { t } = useTranslation('common');

    const formatting = useMemo(() => {
        const currencySymbol = '$';
        const decimal = 2;
        const formatNumber = format.formatNumber(decimal);
        const formatNumberFixed = format.formatNumber(decimal, true);
        const formatCurrency = format.formatCurrency(decimal, false, currencySymbol);
        const formatCurrencyFixed = format.formatCurrency(decimal, true, currencySymbol);
        const formatDate = (date: Date | string) => dayjs(date).format(t('formats.dateFormat'));
        const formatDateTime = (date: Date | string) => dayjs(date).format(t('formats.dateTimeFormat'));
        const formatTime = (date: Date | string) => dayjs(date).format(t('formats.timeFormat'));

        return {
            // metadata
            currencySymbol,
            decimal,

            // format providers
            formatNumber,
            formatNumberFixed,
            formatCurrency,
            formatCurrencyFixed,
            formatDate,
            formatDateTime,
            formatTime,
        };
    }, [t]);

    return formatting;
};
export default useFormatting;

import { Box, Button, CardMedia, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetListDataFragment } from '../../../api';
import usePublic from '../../../utilities/usePublic';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            border: '1px solid #F7F6F3',
            position: 'relative',
            borderRadius: '20px',
        },

        media: {
            height: '100%',
            width: '100%',
            marginBottom: theme.spacing(2),
            border: '1px solid #F7F6F3',
            borderRadius: '20px',
        },
        carTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: theme.spacing(0.5),
        },
        price: {
            fontWeight: 'bold',
            fontSize: '25px',
        },
        icon: {
            width: '24px',
            height: '24px',
            marginRight: theme.spacing(1),
            flexShrink: 0,
        },
        selectButton: {
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'none',
        },

        technicalSpecifications: {
            display: 'none',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },

        mainContent: {
            position: 'relative',
            paddingBottom: theme.spacing(3),
            width: '100%',
            '&:hover': {
                position: 'absolute',
                zIndex: 100,
                backgroundColor: '#F7F6F3',
                boxShadow: '0px 20px 20px #46676A40',
                borderRadius: '0px 0px 20px 20px',
                borderBottom: '1px solid #F7F6F3',
                '& > $selectButton , & > $technicalSpecifications': {
                    display: 'flex',
                },
            },
        },

        pricingDetails: {
            '& > *:not(:last-child)': {
                position: 'relative',
                '&::after': {
                    content: "' '",
                    position: 'absolute',
                    right: '0',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    height: '50%',
                    width: '1px',
                    backgroundColor: 'rgb(226, 226, 226)',
                },
            },
        },
    })
);

interface AssetProps {
    onSelect(assetId: string): void;
    asset: AssetListDataFragment;
    days: number;
}

const Asset = ({ onSelect, asset, days }: AssetProps) => {
    const { t } = useTranslation(['landingPage']);
    const styles = useStyles();
    const imageUnavailable = usePublic('assets/images/notAvailable.png');

    const picture = useMemo(() => {
        if (asset.images.length > 0) {
            return asset.images[0].signedUrl;
        }

        return imageUnavailable;
    }, [asset.images, imageUnavailable]);

    const rangeIcon = usePublic('assets/images/mileRange.svg');

    return (
        <Grid sm={4} xs={12} item>
            <Box className={styles.root} display="flex" flexDirection="column">
                <div className={styles.mainContent}>
                    <img alt="car" className={styles.media} src={picture} />
                    <Typography align="center" className={styles.carTitle}>
                        {asset.make} {asset.model} {asset.variant}{' '}
                    </Typography>

                    <Grid className={styles.technicalSpecifications} spacing={1} container>
                        {asset.technicalSpecifications.features.map(feature => (
                            <Grid key={feature.description} xs={6} item>
                                <Box display="flex" flexDirection="row" mb={1}>
                                    <CardMedia className={styles.icon} image={rangeIcon} />
                                    <Typography>{feature.description}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Box className={styles.pricingDetails} display="flex" flexDirection="row" mb={1} textAlign="center">
                        <Box display="flex" flex="1" flexDirection="column">
                            <Typography>
                                <sup>$</sup>
                                <span className={styles.price}>{asset.restrictions.pricePerDay}</span>
                            </Typography>
                            <Typography color="secondary">{t('landingPage:price')}</Typography>
                        </Box>
                        <Box display="flex" flex="1" flexDirection="column">
                            <Typography>
                                <sup>$</sup>
                                <span className={styles.price}>{asset.restrictions.pricePerDay * days}</span>
                            </Typography>
                            <Typography color="secondary">{t('landingPage:totalPrice')}</Typography>
                        </Box>
                    </Box>
                    <Button
                        className={styles.selectButton}
                        color="secondary"
                        onClick={() => onSelect(asset.id)}
                        variant="contained"
                    >
                        {t('landingPage:button:select')}
                    </Button>
                </div>
            </Box>
        </Grid>
    );
};

export default Asset;

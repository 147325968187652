import React, { useState, useEffect, useCallback, useRef } from 'react';

interface ImageWithFallbackProps {
    fallbackSrc: string;
    src: string;
    className?: string;
}

const ImageWithFallback = ({ src: originalSrc, fallbackSrc, ...props }: ImageWithFallbackProps) => {
    const fallbackRef = useRef(null);
    const [src, setSrc] = useState(originalSrc);

    useEffect(() => {
        // use fallback if there's no src
        setSrc(originalSrc || fallbackSrc);
    }, [fallbackSrc, originalSrc, setSrc]);

    useEffect(() => {
        if (fallbackRef.current === src && src !== fallbackSrc) {
            // the fallback reference did change
            setSrc(fallbackSrc);
        }

        fallbackRef.current = fallbackSrc;
    }, [src, fallbackSrc, fallbackRef, setSrc]);

    const onError = useCallback(() => setSrc(fallbackSrc), [setSrc, fallbackSrc]);

    return <img alt="" {...props} onError={onError} src={src} />;
};

export default ImageWithFallback;

import { Card, CardContent, Grid, Typography, Divider } from '@material-ui/core';
import { orderBy, isNil, flow, pick, join } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { calculate, TAX } from '../../../routes/BookRoutes/shared/calculate';
import { AdditionalOption } from '../../../routes/BookRoutes/shared/types';
import useFormatting from '../../../utilities/useFormatting';

const addressProperties = ['block', 'unit', 'street', 'building', 'country', 'postalCode'];

export const mapAddress = flow([pick(addressProperties), Object.values, values => values.filter(Boolean), join(' ')]);

type BillingSummaryPropTypesAddress = {
    block: string;
    unit: string;
    street: string;
    building?: string;
    country: string;
    postalCode: string;
};

type BillingSummaryPropTypes = {
    additionalOptions: AdditionalOption[];
    receiveAddress?: BillingSummaryPropTypesAddress;
    rentalFee: number;
    returnAddress?: BillingSummaryPropTypesAddress;
};

const BillingSummary = ({ rentalFee, additionalOptions, receiveAddress, returnAddress }: BillingSummaryPropTypes) => {
    const { formatCurrencyFixed, formatNumber } = useFormatting();
    const options = useMemo(() => orderBy(['label'], ['asc'], additionalOptions), [additionalOptions]);
    const prices = useMemo(() => calculate(rentalFee, options), [options, rentalFee]);
    const classes = useBookingStyles();
    const { t } = useTranslation('booking');

    const locations = useMemo(() => {
        if (isNil(receiveAddress) || isNil(returnAddress)) {
            return '';
        }

        return (
            <>
                <Grid direction="row" xs={12} container item>
                    <Typography className={classes.subCardTitle} component="p">
                        {receiveAddress}
                    </Typography>
                    <Typography className={classes.asideValue} component="p">
                        {formatCurrencyFixed(0)}
                    </Typography>
                </Grid>
                <Grid direction="row" xs={12} container item>
                    <Typography className={classes.subCardTitle}>{returnAddress}</Typography>
                    <Typography className={classes.asideValue} component="p">
                        {formatCurrencyFixed(0)}
                    </Typography>
                </Grid>
            </>
        );
    }, [formatCurrencyFixed, receiveAddress, returnAddress, classes]);

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid spacing={1} container>
                    <Grid xs={12} item>
                        <Typography className={classes.groupTitle} color="secondary" component="p">
                            {t('billingSummary.title')}
                        </Typography>
                    </Grid>

                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            Rental Fee
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {formatCurrencyFixed(rentalFee)}
                        </Typography>
                    </Grid>
                    {options.map(option => (
                        <Grid key={option.id} direction="row" xs={12} container item>
                            <Typography component="p">
                                {option.label} (Quantity:{option.quantity})
                            </Typography>

                            <Typography className={classes.asideValue} component="p">
                                {formatCurrencyFixed(option.totalPrice)}
                            </Typography>
                        </Grid>
                    ))}
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            Subtotal
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {formatCurrencyFixed(prices.subTotal)}
                        </Typography>
                    </Grid>
                    {locations}
                    <Grid xs={12} item>
                        <Divider className={classes.divider} component="hr" variant="fullWidth" />
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            {t('billingSummary.label.gst')}({formatNumber(TAX * 100)}%)
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {formatCurrencyFixed(prices.taxValue)}
                        </Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <Divider className={classes.divider} component="hr" variant="fullWidth" />
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.totalLabel} color="secondary">
                            {t('billingSummary.label.totalCost')}
                        </Typography>
                        <Typography className={classes.asideTotalValue} color="secondary">
                            {formatCurrencyFixed(prices.total)}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default BillingSummary;

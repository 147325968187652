import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetListDataFragment } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import { PagingState } from '../shared/actions';
import Asset from './Asset';
import SelectInput from './SelectInput';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginBottom: 0,
            },
        },
        label: {
            fontSize: '16px',
            flexShrink: 0,
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                alignSelf: 'center',
                marginRight: theme.spacing(2),
                marginBottom: 0,
            },
        },
        availableSoon: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(3),
        },
        pagination: {
            width: '100%',
            margin: 'auto',
            '& .MuiPaginationItem-root': {
                backgroundColor: '#f0efec',
                borderRadius: '0',
                color: theme.palette.secondary.main,
                height: '50px',
                width: '50px',
                margin: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.25rem',
                '&.Mui-selected': {
                    color: '#fff',
                    backgroundColor: theme.palette.secondary.main,
                },
            },
            '& .MuiPagination-ul': {
                justifyContent: 'center',
                '& :first-child .MuiPaginationItem-root': {
                    color: '#fff',
                    backgroundColor: theme.palette.secondary.main,
                    marginRight: '1rem',
                },
                '& :last-child .MuiPaginationItem-root': {
                    color: '#fff',
                    backgroundColor: theme.palette.secondary.main,
                    marginLeft: '1rem',
                },
            },
        },

        paginationBox: {
            marginTop: theme.spacing(7),
        },
    })
);

interface AssetListProps {
    onSelect(assetId: string): void;
    assetList: AssetListDataFragment[];
    days: number;
    setPagingState: (pagingState: PagingState) => void;
    paging: PagingState;
}

const AssetList = ({ onSelect, assetList, days, setPagingState, paging }: AssetListProps) => {
    const styles = useStyles();
    const commonStyles = useCommonStyles();
    const { t } = useTranslation(['landingPage']);

    const handleSort = useCallback(
        event => {
            setPagingState({ ...paging, sort: event.target.value, page: 1 });
        },
        [paging, setPagingState]
    );

    const handleDisplay = useCallback(
        event => {
            setPagingState({ ...paging, pageSize: event.target.value, page: 1 });
        },
        [paging, setPagingState]
    );

    return (
        <Grid direction="column" container>
            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} mb={{ xs: 2, sm: 4 }}>
                <Typography className={clsx(commonStyles.bold, styles.header)}>{t('landingPage:available')}</Typography>
                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} ml={{ sm: 'auto' }}>
                    <Grid spacing={2} container>
                        <Grid sm={7} xs={12} item>
                            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                                <Typography className={styles.label}>{t('landingPage:sort')}</Typography>
                                <SelectInput
                                    handleChange={handleSort}
                                    items={[
                                        { value: '10', label: t('landingPage:sortLow') },
                                        { value: '20', label: t('landingPage:sortHigh') },
                                    ]}
                                    value={paging.sort}
                                />
                            </Box>
                        </Grid>
                        <Grid sm={5} xs={12} item>
                            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                                <Typography className={styles.label}>{t('landingPage:display')}</Typography>
                                <SelectInput
                                    handleChange={handleDisplay}
                                    items={[
                                        { value: 9, label: '9' },
                                        { value: 15, label: '15' },
                                        { value: 30, label: '30' },
                                    ]}
                                    value={paging.pageSize}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Grid spacing={3} container>
                {assetList?.map(item => (
                    <Asset key={item.id} asset={item} days={days} onSelect={onSelect} />
                ))}
            </Grid>

            <Box className={styles.paginationBox}>
                {paging.pages > 1 && (
                    <Pagination
                        className={styles.pagination}
                        color="secondary"
                        count={paging.pages}
                        onChange={(_, page) => {
                            setPagingState({ ...paging, page });
                        }}
                        page={paging.page}
                        shape="rounded"
                    />
                )}
            </Box>
        </Grid>
    );
};

export default AssetList;

import { Asset, AssetListDataFragment } from '../../../api';
import {
    BookingDate,
    CustomerDetailsFormValues,
    DriverDetailsFormValues,
    PackageSelection,
    PickUpAndDeliveryFormValues,
} from './types';

export const SET_BOOKING_DATA = 'SET_BOOKING_DATA';

export const SET_BOOKING_STATE = 'SET_BOOKING_STATE';

export type SetBookingData<T> = { type: typeof SET_BOOKING_DATA; step: string; data: T };

export const setBookingData = <T>(step: string, data: T): SetBookingData<T> => ({
    type: SET_BOOKING_DATA,
    step,
    data,
});

export type BookingActions = SetBookingData<
    | Asset
    | AssetListDataFragment
    | BookingDate
    | CustomerDetailsFormValues
    | DriverDetailsFormValues
    | PackageSelection
    | PickUpAndDeliveryFormValues
>;

import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import LandingPage from '../pages/LandingPage';

const LandingPageRoute = (): ReactElement => (
    <BasicLayout>
        <LandingPage />
    </BasicLayout>
);

export default LandingPageRoute;

import { range } from 'lodash';
import React from 'react';
import SelectField from './SelectField';

const timeOptions = range(0, 24).map(hour => {
    const period = hour >= 12 ? 'PM' : 'AM';
    const time = `${hour >= 13 ? hour - 12 : hour}`.padStart(2, '0');

    const val = `${time}:00${period}`;

    return { label: val, value: val };
});

type TimeFieldProps = {
    id: string;
    name: string;
    label: string;
};

function TimeField({ id, name, label }: TimeFieldProps) {
    return <SelectField id={id} label={label} name={name} options={timeOptions} variant="basic" />;
}
export default TimeField;

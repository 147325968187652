import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useOverviewLinks = () => {
    const { t } = useTranslation('overviewPage');

    const links = useMemo(
        () => [
            {
                id: 'bookings',
                label: t('menus.bookings'),
                to: '/overview/bookings',
                icon: ReceiptIcon,
            },
            {
                id: 'myAccount',
                label: t('menus.myAccount'),
                to: '/overview/account',
                icon: AccountCircleIcon,
            },
            {
                id: 'drivingInformation',
                label: t('menus.drivingInformation'),
                to: '/overview/driving-information',
                icon: DirectionsCarIcon,
            },
            {
                id: 'payment',
                label: t('menus.payment'),
                to: '/overview/payment',
                icon: CreditCardIcon,
            },
        ],
        [t]
    );

    return links;
};

export default useOverviewLinks;

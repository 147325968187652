import { Box } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface TabPanelProps {
    children: ReactNode;
    index: number;
    value: number;
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
    <div>{value === index && <Box style={{ paddingTop: '5px' }}>{children}</Box>}</div>
);

export default TabPanel;

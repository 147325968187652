import { Card, CardContent, Grid, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AssetListDataFragment } from '../../../api';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import usePublic from '../../../utilities/usePublic';
import ImageWithFallback from './ImageWithFallback';

interface AssetSummaryProps {
    asset: AssetListDataFragment;
}

const AssetSummary = ({ asset }: AssetSummaryProps) => {
    const { make, model, variant, category, technicalSpecifications, images } = asset;
    const fallbackImage = usePublic('assets/images/notAvailable.png');
    const classes = useBookingStyles();
    const { t } = useTranslation('booking');
    const specifications = [category.description, `${technicalSpecifications?.numberOfSeats} Seater`].join(' · ');

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid spacing={1} container>
                    <Grid xs={12} item>
                        <Typography className={classes.groupTitle} color="secondary" component="p">
                            {t('summary')}
                        </Typography>
                    </Grid>
                    <Grid xs={6} item>
                        <Box>
                            <Typography component="p">
                                {make} {model} {variant}
                            </Typography>
                            <Typography component="p">{specifications}</Typography>
                        </Box>
                    </Grid>
                    <Grid xs={6} item>
                        {images.map(item => (
                            <ImageWithFallback
                                key={item.signedUrl}
                                className={classes.assetImageAside}
                                fallbackSrc={fallbackImage}
                                src={item.signedUrl}
                            />
                        ))}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AssetSummary;

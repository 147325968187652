import React, { useMemo } from 'react';
import { useListLocationsQuery } from '../../api';
import { useLoadingController } from '../LoadingProvider';
import SelectField, { SelectFieldProps } from './SelectField';

export type LocationFieldProps = Omit<SelectFieldProps, 'options'>;

function LocationField(props: LocationFieldProps) {
    // fetch locations api
    const { data, loading } = useListLocationsQuery();
    // add loading while fetching
    useLoadingController(loading);
    // map location options
    const locationOptions = useMemo(
        () => (data?.listLocations || []).map(location => ({ value: location.id, label: location.name })),
        [data]
    );

    return <SelectField {...props} options={[{ value: 'any', label: 'All' }, ...locationOptions]} />;
}
export default LocationField;

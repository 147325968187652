import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        adornment: {
            color: theme.palette.secondary.main,
        },
        label: {
            '&.MuiInputLabel-shrink': {
                transform: 'translate(0, 1.5px) scale(0.9)',
            },
        },
        datepicker: {
            position: 'absolute',
            top: '100%',
            zIndex: 1000,

            display: 'none',
            opacity: 0,
            transition: 'all 0.25s',

            '&.open': {
                display: 'block',
                opacity: 1,
            },
        },
        select: {
            '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
            },
        },
        placeholderOpacity: {
            opacity: '40%',
        },
        button: {
            width: '50%',
            marginTop: theme.spacing(2),
            borderRadius: 0,
            '& .MuiButton-contained': {
                boxShadow: 'none',
            },
            padding: theme.spacing(1),
            color: 'secondary',
        },
    });

const useFieldStyles = makeStyles(styles);

export default useFieldStyles;

import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';

export type PasswordSuffixProps = {
    showPassword: Boolean;
    toggleVisibility: VoidFunction;
};

const PasswordSuffix = ({ showPassword, toggleVisibility }: PasswordSuffixProps) => {
    const Icon = showPassword ? Visibility : VisibilityOff;

    return (
        <InputAdornment position="end">
            <IconButton color="secondary" onClick={toggleVisibility} style={{ background: 'none' }}>
                <Icon />
            </IconButton>
        </InputAdornment>
    );
};

export default PasswordSuffix;

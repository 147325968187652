import { identity } from 'lodash/fp';
import React, { createContext, ReactNode, useContext, useEffect, useReducer, useMemo, Dispatch } from 'react';
import { BookingActions } from './actions';
import reducer, { initializer, initialState as init, storageKey } from './reducer';
import { BookingState } from './types';

export type BookingContext = {
    state: BookingState;
    dispatch: Dispatch<BookingActions>;
};

export const Context = createContext<BookingContext>(null!);

export const useBookContext = () => useContext(Context);

export type BookingProviderProps = {
    children: JSX.Element | ReactNode;
    initialState?: BookingState;
};

const BookingProvider = ({ children, initialState = init }: BookingProviderProps) => {
    const hasPropState = initialState !== init;
    const [state, dispatch] = useReducer(reducer, initialState, hasPropState ? identity : initializer);

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(state));
    }, [state]);

    const context = useMemo(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch]
    );

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default BookingProvider;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useOverviewStyles = makeStyles((theme: Theme) =>
    createStyles({
        overviewPage: {
            paddingTop: '5rem',
            paddingBottom: '2rem',
        },
        aside: {
            [theme.breakpoints.up('sm')]: {
                borderRight: '1px solid #d9d9d9',
            },
        },
        menuIcon: {
            fontSize: '2rem',
        },
        menuNavLink: {
            display: 'flex',
            alignItems: 'center',
            color: '#c3c3c3',
            textDecoration: 'none',
            '& > *:not(:last-child)': {
                marginRight: '1rem',
            },
            '&.active': {
                color: `${theme.palette.secondary.main}`,
            },
            '&:not(.active) > h6': {
                fontWeight: 'normal',
            },
        },
        selectNavLink: {
            '& .MuiInput-input': {
                color: `${theme.palette.secondary.main}`,
            },
        },
        carBookingDisplay: {
            width: '100%',
            paddingLeft: '1rem',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '2rem',
            },
        },
        banner: {
            paddingTop: '1rem',
            backgroundColor: 'rgb(58,104,107)',
            color: '#fff',
        },
        bannerModel: {
            fontSize: '1.25rem',
            fontWeight: 700,
        },
        bannerIcon: {
            paddingLeft: '1rem',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '0rem',
            },
        },
        bannerDetailsContainer: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '2rem',
            },
        },
        menuLink: {
            width: '100%',
            height: '150px',
            backgroundColor: 'white',
            borderRadius: '20px',
            boxShadow: '0px 0px 20px #a2a2a20d',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none',
            padding: '1rem',
            boxSizing: 'border-box',
            textAlign: 'center',
        },
        promotionTitle: {
            color: `${theme.palette.secondary.main}`,
            fontWeight: 500,
        },
        promotionCardTitle: {
            fontWeight: 500,
        },
        promotionCardDescription: {
            fontWeight: 'lighter',
        },
        promotionCardLearnMore: {
            fontWeight: 500,
            color: `${theme.palette.secondary.main}`,
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
        },
        promotionCard: {
            borderRadius: '20px',
            border: '0.5px solid #cd9862',
        },
        promotionCardMedia: {
            height: '200px',
        },
        promotionsContainer: {
            paddingTop: '2rem',
            paddingBottom: '2rem',
            display: 'flex',
            flexDirection: 'column',
            '& > *:not(:last-child)': {
                marginBottom: '2rem',
            },
        },
        avatarLarge: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
        overviewButton: {
            marginRight: '.5rem',
            width: '8.5rem',
            fontWeight: 700,
        },
        name: {
            color: `${theme.palette.secondary.main}`,
            fontWeight: 500,
        },
        buttonGroup: { display: 'flex', flexDirection: 'row' },
        subGroupTitle: {
            fontWeight: 400,
            fontSize: '1.5rem',
        },
        imageContainer: {
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
        },
        assetImage: {
            height: '8rem',
        },
        bookingItemLabel: {
            color: `${theme.palette.secondary.main}`,
            fontWeight: 'normal',
            fontSize: '1rem',
        },
        bookingItemCard: {
            boxShadow: 'none',
            border: 'none',
            borderRadius: '6px',
        },
        bookingItemContainer: {
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
        },
        bookingItemAssetDetails: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        bookingItemAssetDescription: { fontSize: '1.2rem', fontWeight: 'bold' },
        bookingItemAssetTechDetails: { fontSize: '1rem', fontWeight: 'normal' },
        bookingItemAssetIdentifier: { fontSize: '1rem', fontWeight: 'bold' },
        bookingItemButton: {
            fontWeight: 700,
        },
    })
);

export default useOverviewStyles;

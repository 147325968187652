import { Menu, MenuItem, Button, Fade, Avatar, Typography, ListItemIcon } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useOverviewLinks from '../../routes/OverviewRoutes/shared/useOverviewLinks';
import { useGlobalContext } from '../../utilities/shared/GlobalContextProvider';
import useBasicStyles from './useBasicStyles';

type UserMenuProps = {
    handleSignOut: () => void;
};

const UserMenu = ({ handleSignOut }: UserMenuProps) => {
    const classes = useBasicStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const baseLinks = useOverviewLinks();
    const { t } = useTranslation('overviewPage');
    const history = useHistory();
    const { profilePicture } = useGlobalContext();
    const links = useMemo(
        () => [
            {
                id: 'overview',
                label: t('menus.overview'),
                to: '/overview',
                icon: AccountCircleIcon,
            },
            ...baseLinks,
        ],
        [baseLinks, t]
    );

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (url: string) => {
        handleClose();
        history.push(url);
    };

    return (
        <div>
            <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar alt="User Profile" className={classes.avatarSmall} src={profilePicture} />
            </Button>
            <Menu
                TransitionComponent={Fade}
                anchorEl={anchorEl}
                id="fade-menu"
                onClose={handleClose}
                open={open}
                keepMounted
            >
                {links.map(link => (
                    <MenuItem key={link.id} onClick={() => handleMenuClick(link.to)}>
                        <ListItemIcon>{React.createElement(link.icon)}</ListItemIcon>
                        <Typography>{link.label}</Typography>
                    </MenuItem>
                ))}
                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                        <SettingsPowerIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography>{t('menus.signout')}</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserMenu;

import { useApolloClient } from '@apollo/client';
import { identity } from 'lodash/fp';
import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useReducer,
    useMemo,
    Dispatch,
    useCallback,
} from 'react';
import { RefreshCredentialsDocument, RefreshCredentialsMutation, RefreshCredentialsMutationVariables } from '../../api';
import usePublic from '../usePublic';
import { GlobalActions, setGlobalStateData } from './actions';
import reducer, { initializer, initialState as init, storageKey } from './reducer';
import { GlobalState } from './types';

export type SessionData = {
    data: {
        iat: number;
        exp: number;
        userId: { $oid: string };
    } | null;
};

export type GlobalContext = {
    state: GlobalState;
    dispatch: Dispatch<GlobalActions>;
    validateToken(token: String): {
        iat: number;
        exp: number;
        userId: { $oid: string };
    } | null;
    profilePicture: string;
};

export const Context = createContext<GlobalContext>(null!);

export const useGlobalContext = () => useContext(Context);

export type GlobalContextProviderProps = {
    children: JSX.Element | ReactNode;
    initialState?: GlobalState;
};

const validateTokenData = (token?: string) => {
    if (!token) {
        // no token
        return null;
    }

    const parts = token.split('.');

    if (parts.length !== 3) {
        // invalid token
        return null;
    }

    const rawData = atob(parts[1]);

    try {
        const data = JSON.parse(rawData) as SessionData['data'];

        if (data.exp * 1000 > new Date().getTime()) {
            return data;
        }
    } catch (error) {
        // invalid data
        console.warn(error);
    }

    return null;
};

const GlobalContextProvider = ({ children, initialState = init }: GlobalContextProviderProps) => {
    const hasPropState = initialState !== init;
    const [state, dispatch] = useReducer(reducer, initialState, hasPropState ? identity : initializer);
    const { authorization, customer } = state;
    const { token } = authorization;
    const client = useApolloClient();
    const blankProfile = usePublic('assets/images/blank_profile.png');

    const profilePicture = useMemo(() => {
        if (customer?.profilePicture?.signedUrl) {
            return customer?.profilePicture?.signedUrl;
        }

        return blankProfile;
    }, [customer, blankProfile]);

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(state));
    }, [state]);

    const tokenData = useMemo(() => validateTokenData(token), [token]);

    const updateToken = useCallback(
        (newToken: string | null) => {
            if (newToken) {
                dispatch(setGlobalStateData('authorization', { ...authorization, token: newToken }));
            } else {
                dispatch(setGlobalStateData('authorization', { ...authorization, token: null }));
                client.cache.reset();
            }
        },
        [authorization, client]
    );

    useEffect(() => {
        if (!tokenData) {
            return () => undefined;
        }

        let mounted = true;

        const fn = async () => {
            if (!mounted) {
                // do nothing
                return;
            }

            const left = tokenData.exp - Date.now() / 1000;

            // renew 10mn ahead maximum
            if (left > 600) {
                // do nothing
                setTimeout(fn, 1000);

                // stop here
                return;
            }

            try {
                const response = await client.mutate<RefreshCredentialsMutation, RefreshCredentialsMutationVariables>({
                    mutation: RefreshCredentialsDocument,
                });

                if (mounted) {
                    updateToken(response?.data?.refreshCredentials);
                }
            } catch (error) {
                // print it out
                console.error(error);

                if (mounted) {
                    // reset token
                    updateToken(null);
                }
            }
        };

        setTimeout(fn, 200);

        return () => {
            mounted = false;
        };
    }, [tokenData, client, updateToken]);

    const context = useMemo(
        () => ({
            state,
            dispatch,
            validateToken: validateTokenData,
            profilePicture,
        }),
        [state, dispatch, profilePicture]
    );

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default GlobalContextProvider;

import { Grid } from '@material-ui/core';
import React from 'react';
import BookingItem from './BookingItem';

interface BookingListProps {
    bookings: {
        category: string;
        identifier: string;
        imageUrl: string;
        make: string;
        model: string;
        numberOfSeats: string;
        variant: string;
        fallbackImage: string;
        rentalPeriodStart: Date;
        rentalPeriodEnd: Date;
    }[];
}
const BookingList = ({ bookings }: BookingListProps) => (
    <Grid spacing={2} container>
        {bookings.map(booking => (
            <Grid xs={12} item>
                <BookingItem {...booking} />
            </Grid>
        ))}
    </Grid>
);

export default BookingList;

import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useCreateAccountMutation } from '../../../../api';
import { useHandleError } from '../../../../utilities/handleError';
import { useNotificationContext } from '../../../../utilities/notificationContext/NotificationContextProvider';
import SignUpFormFields from './SignUpFormFields';
import SignUpFormFooter from './SignUpFormFooter';
import SignUpFormSubmit from './SignUpFormSubmit';

export type mobilePhone = {
    internationalCode: string;
    number: string;
};

export type SignUpFormValues = {
    fullName: string;
    password: string;
    confirmPassword: string;
    email: string;
    mobileNo: mobilePhone;
    agreeToTerms: boolean;
    marketingConsent: boolean;
};

export type SignUpFormProps = {
    initialValues: SignUpFormValues;
};

const SignUpForm = ({ initialValues }: SignUpFormProps) => {
    const [createAccount] = useCreateAccountMutation();
    const [t] = useTranslation('signUpPage');
    const { setNotification } = useNotificationContext();
    const history = useHistory();

    const signUpValidationSchema = useMemo(() => {
        const schema = yup.object().shape({
            confirmPassword: yup.lazy((_, { parent }) => {
                const { password } = parent;

                return yup
                    .string()
                    .required(t('validation.required.confirmPassword'))
                    .test('is-same', t('validation.notMatch.confirmPassword'), value => value === password);
            }),
            email: yup
                .string()
                .required(t('validation.required.email'))
                .email(t('validation.invalid.email'))
                .max(320, t('validation.maxLength.email')),
            fullName: yup
                .string()
                .required(t('validation.required.fullName'))
                .max(200, t('validation.maxLength.fullName')),
            password: yup
                .string()
                .required(t('validation.required.password'))
                .min(8, t('validation.minLength.password')),
        });

        return schema;
    }, [t]);

    const onSubmit = useHandleError(
        async (values: SignUpFormValues) => {
            const fields = {
                username: values.email,
                fullName: values.fullName,
                password: values.password,
                email: values.email,
                mobileNo: values.mobileNo,
            };
            await createAccount({ variables: { fields } });
            setNotification(t('modalCardSignUp'));
            history.push('/signin');
        },
        [createAccount]
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={signUpValidationSchema}>
            {({ isSubmitting, values, errors }) => (
                <Form>
                    <Grid spacing={2} container>
                        <SignUpFormFields />
                        <SignUpFormFooter />
                        <SignUpFormSubmit
                            hasError={!isEmpty(errors) || values.agreeToTerms !== true}
                            isSubmitting={isSubmitting}
                        />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default SignUpForm;

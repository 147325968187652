import { Container, Grid, Button, Typography, Box } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { omit } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSubmitBookingMutation, BookInputFields } from '../../../api';
import ImgIcon from '../../../components/ImgIcon';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';
import { useBookContext } from '../../../routes/BookRoutes/shared/BookingProvider';
import { calculate } from '../../../routes/BookRoutes/shared/calculate';
import { DefaultStringValue } from '../../../utilities/constants';
import usePublic from '../../../utilities/usePublic';
import BillingSummary from '../components/BillingSummary';
import ImageWithFallback from '../components/ImageWithFallback';
import PersonalDetails from '../components/PersonalDetails';
import PickupAndDeliverySummary from '../components/PickupAndDeliverySummary';
import RentalTermsDrawer from '../components/RentalTermsDrawer';

const ConfirmationPage = () => {
    const [submitBooking] = useSubmitBookingMutation();
    const { state } = useBookContext();

    const { asset, date, packageSelection, customer, driver, pickUpDelivery } = state;
    const { additionalOptions } = packageSelection;
    const { make, model, variant, restrictions, images, technicalSpecifications, category } = asset;
    const classes = useBookingStyles();
    const { t } = useTranslation('confirmationPage');
    const fallbackImage = usePublic('assets/images/notAvailable.png');
    const specifications = [category.description, `${technicalSpecifications.numberOfSeats} Seater`].join(' · ');
    const { features } = technicalSpecifications;
    const history = useHistory();
    const rentalFee = useMemo(() => asset.restrictions.pricePerDay * date.days, [asset, date]);

    const [showDrawer, setShowDrawer] = useState(false);

    const { onClose, onToggle } = useMemo(
        () => ({
            onClose: () => setShowDrawer(false),
            onToggle: () => setShowDrawer(state => !state),
        }),
        [setShowDrawer]
    );

    const prices = useMemo(
        () => calculate(rentalFee, packageSelection.additionalOptions),
        [packageSelection, rentalFee]
    );

    const submitInputFields = useMemo((): BookInputFields => {
        const inputFields: BookInputFields = {
            assetId: asset.id,
            rentalStartDate: date.start,
            rentalEndDate: date.end,
            days: date.days,
            price: rentalFee,
            gst: prices.taxValue,
            totalCost: prices.total,
            supportingDocuments: [
                ...customer.supportingDocuments?.map(document => ({
                    ...omit(['__typename', 'signedUrl'], document),
                    uploadedAt: new Date(document.uploadedAt) || new Date(),
                })),
            ],
            additionalItems: [...additionalOptions],
            customer: {
                fullName: {
                    ...customer.fullName,
                },
                displayName: {
                    ...customer.displayName,
                },
                email: {
                    ...customer.email,
                },
                mobilePhone: {
                    internationalCode: customer.mobile.internationalCode || '',
                    value: customer.mobile.value || '',
                    source: customer.mobile.source,
                },
                smsContact: {
                    internationalCode: customer.smsContact.internationalCode || '',
                    value: customer.smsContact.value || '',
                    source: customer.smsContact.source,
                },
                particulars: {
                    identityNo: {
                        ...customer.identityNo,
                    },
                    dateOfBirth: {
                        ...customer.dob,
                    },
                    gender: {
                        ...customer.gender,
                    },
                    race: {
                        ...customer.race,
                    },
                    maritalStatus: {
                        ...customer.maritalStatus,
                    },
                    nationality: {
                        ...customer.nationality,
                    },
                    salutation: {
                        ...customer.salutation,
                    },
                },
                residentialAddress: {
                    ...customer.residentialAddress,
                    addressType: customer.residentialAddress.addressType || { ...DefaultStringValue },
                },
                licenseDetails: {
                    ...driver.licenseDetails,
                },
            },
        };

        return inputFields;
    }, [asset, date, rentalFee, prices, additionalOptions, customer, driver]);

    const confirmBooking = useCallback(async () => {
        const { data } = await submitBooking({
            variables: {
                fields: submitInputFields,
            },
        });

        history.push({
            pathname: '/signin',
            state: {
                bookingId: data?.submitBooking?.id,
            },
        });

        history.push('/book/summary');
    }, [history, submitBooking, submitInputFields]);

    return (
        <Container className={classes.bookingPage} maxWidth="lg">
            <Formik initialValues={customer} onSubmit={confirmBooking}>
                {({ isSubmitting }) => (
                    <Form style={{ width: '100%' }}>
                        <Grid spacing={4} container>
                            <Grid xs={12} item>
                                <Container maxWidth="sm">
                                    <Typography color="secondary" style={{ textAlign: 'center' }} variant="h3">
                                        {t('title')}
                                    </Typography>
                                    <Typography
                                        component="p"
                                        style={{ textAlign: 'center', paddingBottom: '1rem' }}
                                        variant="body2"
                                    >
                                        {t('subTitle')}
                                    </Typography>
                                    {images?.map(item => (
                                        <ImageWithFallback
                                            className={classes.assetImage}
                                            fallbackSrc={fallbackImage}
                                            src={item.signedUrl}
                                        />
                                    ))}

                                    <Box pb="1rem">
                                        <Typography
                                            component="h4"
                                            style={{ fontSize: '1.75rem', fontWeight: 700, textAlign: 'center' }}
                                        >
                                            {make} {model} {variant}
                                        </Typography>
                                        <Typography component="h5" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                                            {specifications}
                                        </Typography>
                                    </Box>
                                    <Grid container>
                                        {features.map(feature => (
                                            <Grid direction="row" xs={6} container item>
                                                <ImgIcon className={classes.placeholderIcon} url={feature.icon} />
                                                <Typography style={{ paddingLeft: '1rem' }}>
                                                    {feature.description}
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Container>
                            </Grid>

                            <Grid md={6} xs={12} item>
                                <PersonalDetails customer={customer} />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <PickupAndDeliverySummary
                                    bookingDate={date}
                                    pickUpDelivery={pickUpDelivery}
                                    fullHeight
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <BillingSummary
                                    additionalOptions={additionalOptions}
                                    rentalFee={restrictions.pricePerDay * date.days}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <Button
                                    color="secondary"
                                    disabled={isSubmitting}
                                    onClick={confirmBooking}
                                    variant="contained"
                                    disableElevation
                                    fullWidth
                                >
                                    <Typography style={{ fontWeight: 700, textAlign: 'center' }}>
                                        {t('label.confirm')}
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid xs={12} item>
                                <Typography style={{ fontWeight: 'lighter', textAlign: 'center' }}>
                                    <Trans i18nKey="confirmationPage:terms">
                                        {"By continuing, you're agreeing to our "}
                                        <Box
                                            className={classes.link}
                                            onClick={onToggle}
                                            sx={{ display: 'inline-block' }}
                                        >
                                            {'Terms of Service '}
                                        </Box>
                                    </Trans>
                                    <RentalTermsDrawer onClose={onClose} open={showDrawer} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default withErrorBoundary(ConfirmationPage);

import { Box, Modal, Typography } from '@material-ui/core';
import useIdentityStyles from '../layouts/BasicLayout/useIdentityStyles';

export type NotificationModalProps = {
    onClose: () => void;
    open: boolean;
    message: string;
};

const NotificationModal = ({ onClose, open, message }: NotificationModalProps) => {
    const classes = useIdentityStyles();

    return (
        <Modal onClose={onClose} open={open}>
            <Box className={classes.signUpModalCard}>
                <Typography component="h2" variant="h6">
                    {message}
                </Typography>
            </Box>
        </Modal>
    );
};

export default NotificationModal;

import { Grid, Typography, Paper, IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingListDataFragment } from '../../../api';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';
import { calculateDays } from '../../../routes/BookRoutes/shared/calculate';
import { getCurrentDate } from '../../../utilities/commonFunctions';
import usePublic from '../../../utilities/usePublic';
import ImageWithFallback from '../../Booking/components/ImageWithFallback';

type ActiveBookingBannerProps = {
    bookings: BookingListDataFragment[];
};

const ActiveBookingBanner = ({ bookings }: ActiveBookingBannerProps) => {
    const imageUnavailable = usePublic('assets/images/notAvailable.png');
    const classes = useOverviewStyles();
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [bookingId, setBookingId] = useState<string>(bookings?.length > 0 ? bookings[0].id : null);
    const booking = useMemo(() => bookings?.find(booking => booking.id === bookingId), [bookings, bookingId]);
    const { t } = useTranslation('overviewPage');

    const handleNext = useCallback(() => {
        let newIndex = currentIndex;

        if (currentIndex >= bookings.length - 1) {
            newIndex = 0;
        } else {
            newIndex++;
        }
        setCurrentIndex(newIndex);
        setBookingId(bookings[newIndex].id);
    }, [currentIndex, bookings]);

    const { asset } = booking;

    const rentalStatus = useMemo(() => {
        const currentDate = getCurrentDate();
        let remainingDays = 0;
        if (booking) {
            remainingDays = calculateDays(currentDate, new Date(booking?.rentalEndDate));
        }

        const label = t('label.rentalStatus');

        return label.replace('[@identifier]', asset.identifier).replace('[@days]', remainingDays.toString());
    }, [asset, booking, t]);

    const picture = useMemo(() => {
        if (asset?.images.length > 0) {
            return asset.images[0].signedUrl;
        }

        return imageUnavailable;
    }, [asset, imageUnavailable]);

    return (
        <Paper className={classes.banner}>
            <Grid spacing={4} container>
                <Grid spacing={4} xs={12} container item>
                    <Grid md={2} xs={12} item>
                        <ImageWithFallback
                            className={classes.carBookingDisplay}
                            fallbackSrc={imageUnavailable}
                            src={picture}
                        />
                    </Grid>
                    <Grid direction="row" md={10} xs={12} container item>
                        <div className={classes.bannerDetailsContainer}>
                            <Typography className={classes.bannerModel}>{asset?.model}</Typography>
                            <Typography>{rentalStatus}</Typography>
                        </div>
                        {bookings.length > 1 && (
                            <div className={classes.bannerIcon}>
                                <IconButton className={classes.bannerIcon} onClick={handleNext}>
                                    <ChevronRight />
                                </IconButton>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ActiveBookingBanner;

import { Container, Grid, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ImgIcon from '../../../components/ImgIcon';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';
import { useBookContext } from '../../../routes/BookRoutes/shared/BookingProvider';
import usePublic from '../../../utilities/usePublic';
import BillingSummary from '../components/BillingSummary';
import ImageWithFallback from '../components/ImageWithFallback';
import PersonalDetails from '../components/PersonalDetails';
import PickupAndDeliverySummary from '../components/PickupAndDeliverySummary';

const ViewDetailsPage = () => {
    // use book context for now, in the future will use api to get booking data
    const { state } = useBookContext();
    const { asset, date, packageSelection, customer, pickUpDelivery } = state;
    const { additionalOptions } = packageSelection;
    const { make, model, variant, restrictions, images, technicalSpecifications, category } = asset;
    const classes = useBookingStyles();
    const { t } = useTranslation('viewBookingDetails');
    const fallbackImage = usePublic('assets/images/notAvailable.png');
    const specifications = [category.description, `${technicalSpecifications.numberOfSeats} Seater`].join(' · ');
    const { features } = technicalSpecifications;

    const styles = useBookingStyles();

    return (
        <Container className={classes.bookingPage} maxWidth="lg">
            <Grid spacing={4} container>
                <Grid xs={12} item>
                    <Container maxWidth="sm">
                        <Typography
                            color="secondary"
                            style={{ textAlign: 'center', marginBottom: '2rem', marginTop: '2rem' }}
                            variant="h3"
                        >
                            {t('title')}
                        </Typography>
                        {images?.map(item => (
                            <ImageWithFallback
                                className={classes.assetImage}
                                fallbackSrc={fallbackImage}
                                src={item.signedUrl}
                            />
                        ))}

                        <Box pb="1rem">
                            <Typography
                                component="h4"
                                style={{ fontSize: '1.75rem', fontWeight: 700, textAlign: 'center' }}
                            >
                                {make} {model} {variant}
                            </Typography>
                            <Typography component="h5" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                                {specifications}
                            </Typography>
                            <Box
                                className={styles.viewBookingDetails}
                                sx={{
                                    bgcolor: '#4C6669',
                                    width: '40%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    color: '#ffffff',
                                    borderRadius: '6px',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {t('bookingComplete')}
                                </Typography>
                            </Box>
                        </Box>
                        <Grid container>
                            {features.map(feature => (
                                <Grid direction="row" xs={6} container item>
                                    <ImgIcon className={classes.placeholderIcon} url={feature.icon} />
                                    <Typography style={{ paddingLeft: '1rem' }}>{feature.description}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Grid>

                <Grid xs={12} item>
                    <PersonalDetails customer={customer} />
                </Grid>
                <Grid xs={12} item>
                    <PickupAndDeliverySummary bookingDate={date} pickUpDelivery={pickUpDelivery} fullHeight />
                </Grid>
                <Grid xs={12} item>
                    <BillingSummary
                        additionalOptions={additionalOptions}
                        rentalFee={restrictions.pricePerDay * date.days}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default withErrorBoundary(ViewDetailsPage);

import { FormControlLabel, Checkbox, CheckboxProps } from '@material-ui/core';
import { useField } from 'formik';
import React, { ReactElement } from 'react';

export interface CheckBoxFieldProps extends Omit<CheckboxProps, 'value' | 'onChange'> {
    name: string;
    label: string | ReactElement;
}

const CheckBoxField = ({ label, name, ...props }: CheckBoxFieldProps) => {
    const [field] = useField({ name });

    return <FormControlLabel control={<Checkbox {...field} />} label={label} />;
};

export default CheckBoxField;

import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { DriverDetailsFormValues } from '../../../routes/BookRoutes/shared/types';
import DriverDetailsForm from './DriverDetailsForm';

interface DriverDetailsProps {
    driverDetails: DriverDetailsFormValues;
}

const DriverDetails = ({ driverDetails }: DriverDetailsProps) => {
    const classes = useBookingStyles();
    const { t } = useTranslation('driverPage');

    return (
        <Grid direction="column" spacing={2} container>
            <Grid xs={12} item>
                <Typography className={classes.subGroupTitle} component="p">
                    {t('title')}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <DriverDetailsForm licenseClasses={driverDetails.licenseDetails.classes} />
            </Grid>
        </Grid>
    );
};

export default DriverDetails;

import { SignUpPage } from '../../pages/SignUp';

const initialValues = {
    fullName: '',
    password: '',
    confirmPassword: '',
    email: '',
    mobileNo: { internationalCode: '', number: '' },
    agreeToTerms: false,
    marketingConsent: false,
};

const SignUpRoute = () => <SignUpPage initialValues={initialValues} />;

export default SignUpRoute;

import { FormControl, InputLabel, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { useField } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useFieldStyles from './useFieldStyles';

export interface ViewOnlyDateFieldProps {
    label?: string;
    name: string;
}

const ViewOnlyDateField = ({ name, label }: ViewOnlyDateFieldProps) => {
    const styles = useFieldStyles();
    const [meta] = useField({ name });
    const { value } = meta;
    const { t } = useTranslation('common');

    const displayValue = useMemo(() => {
        if (value) {
            const tmpValue: Date = typeof value === 'string' ? dayjs(value).toDate() : value;

            return dayjs(tmpValue).format(t('formats.datePickerFormat'));
        }

        return '';
    }, [t, value]);

    return (
        <FormControl fullWidth>
            {label && (
                <InputLabel className={styles.label} shrink>
                    {label}
                </InputLabel>
            )}
            <>
                <br />
                <Typography>{displayValue}</Typography>
            </>
        </FormControl>
    );
};

export default ViewOnlyDateField;

import { FormControl, FormHelperText, InputLabel, InputProps, Typography } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { variantComponent } from './shared';
import useFieldStyles from './useFieldStyles';

export interface TextFieldProps extends Omit<InputProps, 'value' | 'onChange'> {
    label?: string;
    name: string;
    variant?: keyof typeof variantComponent;
    helperText?: string;
    endAdornment?: JSX.Element;
    viewOnly?: boolean;
    required?: boolean;
}

const TextField = ({
    name,
    label,
    variant = 'standard',
    helperText,
    viewOnly = false,
    required = false,
    ...props
}: TextFieldProps) => {
    const styles = useFieldStyles();

    const [field, meta] = useField({ name });

    const hasError = !!meta.error && meta.touched;

    const InputComponent = variantComponent[variant];

    return (
        <FormControl fullWidth>
            {label && (
                <InputLabel className={styles.label} shrink>
                    {label} {required && <span style={{ color: 'red' }}>*</span>}
                </InputLabel>
            )}
            {!viewOnly && <InputComponent {...props} error={hasError} {...field} />}
            {viewOnly && (
                <>
                    <br />
                    <Typography>{meta.value}</Typography>
                </>
            )}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default TextField;

import { Grid, Container, Button, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash/fp';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useSaveDriverDetailsMutation } from '../../../api';
import { useAlert } from '../../../components/AlertProvider';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';
import { CustomerDetailsFormValues, DriverDetailsFormValues } from '../../../routes/BookRoutes/shared/types';
import { convertToCustomerDetailsFormValues } from '../../../utilities/commonFunctions';
import { useHandleError } from '../../../utilities/handleError';
import { useGlobalContext } from '../../../utilities/shared/GlobalContextProvider';
import { setGlobalStateData } from '../../../utilities/shared/actions';
import LicenceSuspension from '../../Booking/DriverPage/LicenceSuspension';
import LicenseClass from '../../Booking/DriverPage/LicenseClass';
import LicenseRevocation from '../../Booking/DriverPage/LicenseRevocation';
import LicenseValidity from '../../Booking/DriverPage/LicenseValidity';

const DriverInfoPage = () => {
    const classes = useOverviewStyles();
    const bookingClasses = useBookingStyles();
    const { dispatch, state } = useGlobalContext();
    const { driver, appOptions } = state;
    const [editMode, setEditMode] = useState<boolean>(false);
    const { t } = useTranslation('overviewPage');
    const { t: transDriverPage } = useTranslation('driverPage');
    const { show } = useAlert();

    const [saveDriverDetails] = useSaveDriverDetailsMutation();
    const handleSubmit = useHandleError(
        async (values: DriverDetailsFormValues) => {
            const { licenseDetails } = values;

            const { data } = await saveDriverDetails({
                variables: {
                    fields: { ...licenseDetails },
                },
            });

            const customerData = convertToCustomerDetailsFormValues(data.saveDriverDetails);

            dispatch(setGlobalStateData<CustomerDetailsFormValues>('customer', customerData));

            setEditMode(false);
            show('success', t('alert.driverInfoSaveSuccess'));
        },
        [saveDriverDetails]
    );

    const validationSchema = useMemo(() => {
        const schema = yup.object().shape({
            licenseDetails: yup.object().shape({
                classes: yup
                    .array()
                    .of(
                        yup.object().shape({
                            class: yup.string().required(transDriverPage('validation.required.licenseClass')),
                            issueDate: yup.string().required(transDriverPage('validation.required.issueDate')),
                        })
                    )
                    .required(transDriverPage('validation.required.licenseClass')),
                expiryDate: yup.string().required(transDriverPage('validation.required.expiryDate')),
                validity: yup.string().required(transDriverPage('validation.required.licenseValidity')),
            }),
        });

        return schema;
    }, [transDriverPage]);

    return (
        <Container maxWidth="md">
            <Formik initialValues={driver} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ isSubmitting, errors, values }) => (
                    <Form style={{ width: '100%' }}>
                        <Grid spacing={3} container>
                            <Grid justify="flex-end" md={8} xs={12} container item>
                                {editMode && (
                                    <Button
                                        className={classes.overviewButton}
                                        color="secondary"
                                        disabled={isSubmitting || !isEmpty(errors)}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('label.save')}
                                    </Button>
                                )}
                                {!editMode && (
                                    <Button
                                        className={classes.overviewButton}
                                        color="secondary"
                                        onClick={() => setEditMode(true)}
                                        type="button"
                                        variant="contained"
                                    >
                                        {t('label.edit')}
                                    </Button>
                                )}
                            </Grid>
                            <Grid xs={12} item>
                                <Typography className={bookingClasses.subGroupTitle} component="p">
                                    {transDriverPage('driverLicense.title')}
                                </Typography>
                            </Grid>
                            <Grid spacing={2} xs={12} container item>
                                {values.licenseDetails.classes.map((item, index) => (
                                    <LicenseClass
                                        licenseClassOptions={appOptions?.licenseClasses}
                                        name={`licenseDetails.classes.${index}`}
                                        viewOnly={!editMode}
                                    />
                                ))}
                                <LicenseValidity
                                    licenseValidityOptions={state?.appOptions?.licenseValidity}
                                    name="licenseDetails"
                                    viewOnly={!editMode}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <Typography className={bookingClasses.subGroupTitle} component="p">
                                    {transDriverPage('drivingLicenseSuspension.title')}
                                </Typography>
                            </Grid>
                            <Grid spacing={2} xs={12} container item>
                                <LicenceSuspension name="licenseDetails" viewOnly={!editMode} />
                            </Grid>
                            <Grid xs={12} item>
                                <Typography className={bookingClasses.subGroupTitle} component="p">
                                    {transDriverPage('drivingLicenseRevocation.title')}
                                </Typography>
                            </Grid>
                            <Grid spacing={2} xs={12} container item>
                                <LicenseRevocation name="licenseDetails" viewOnly={!editMode} />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default withErrorBoundary(DriverInfoPage);

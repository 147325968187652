import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { TextField, PasswordField } from '../../../../components/inputs';
import useIdentityStyles from '../../../../layouts/BasicLayout/useIdentityStyles';

const LoginFormFields = () => {
    const { t } = useTranslation(['loginPage']);
    const classes = useIdentityStyles();

    const history = useHistory();

    return (
        <>
            <Grid xs={12} item>
                <TextField label={t('label.email')} name="username" placeholder={t('placeholder.email')} />
            </Grid>
            <Grid xs={12} item>
                <PasswordField label={t('label.password')} name="password" placeholder={t('placeholder.password')} />
                <Box mt={1} textAlign="right">
                    <Link className={classes.link} onClick={() => history.push('/forgot')} to="/forgot">
                        {t('label.forgotPassword')}
                    </Link>
                </Box>
            </Grid>
        </>
    );
};

export default LoginFormFields;

import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocationFullDataFragment } from '../../../api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        locationInfo: {
            backgroundColor: '#EDECE8',
        },
        header: {
            color: '#676767',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
        },
        information: {
            color: '#3A686B',
        },
    })
);

type LocationInfoProps = {
    location: LocationFullDataFragment;
};

export default function LocationInfo({ location }: LocationInfoProps) {
    const styles = useStyles();
    const { t } = useTranslation(['pickUpPage']);

    return (
        <Box className={styles.locationInfo} display="flex" flexDirection="column" my={3} p={3}>
            <Typography>{location.name}</Typography>
            <Typography className={styles.header}>{t('pickUpPage:address')}</Typography>
            <Typography className={styles.information}>{location.address.building}</Typography>
            <Typography className={styles.header}> {t('pickUpPage:serviceCallCenter')}</Typography>
            <Typography className={styles.information}>
                {location.phone.internationalCode} {location.phone.value}
            </Typography>
            <Typography className={styles.header}>{t('pickUpPage:salesOfficeWorkingHours')}</Typography>
            {location.workingHours.map((workingHour, i) => (
                <Typography key={workingHour.day} className={styles.information}>
                    {workingHour.day}: {workingHour.open} - {workingHour.close}
                </Typography>
            ))}
        </Box>
    );
}

import { Grid } from '@material-ui/core';
import { range } from 'lodash/fp';
import React from 'react';
import PromotionCard from './PromotionCard';

const promotions = range(1, 5).map(index => ({
    id: index,
    image: 'assets/images/promotions.png',
    title: `Promotion ${index}`,
    description:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore mque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore',
    to: '',
}));

const PromotionsLists = () => (
    <Grid alignItems="stretch" spacing={3} container>
        {promotions.map(({ id, ...props }) => (
            <Grid key={id} sm={3} xs={12} item>
                <PromotionCard {...props} />
            </Grid>
        ))}
    </Grid>
);

export default PromotionsLists;

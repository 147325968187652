import numbro from 'numbro';

export const formatNumber =
    (decimal = 2, isFixed = false) =>
    value =>
        numbro(value).format({ thousandSeparated: true, mantissa: decimal, optionalMantissa: !isFixed });

export const formatCurrency =
    (decimal = 2, isFixed = false, unit = '$') =>
    value =>
        `${unit} ${formatNumber(decimal, isFixed)(value)}`;

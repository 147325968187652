import { FormControl, InputLabel, ClickAwayListener, InputProps, FormHelperText } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Field, FieldProps } from 'formik';
import { compose, omit } from 'lodash/fp';
import React, { createRef, Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { DateRangePicker as TinyDateRangePicker } from 'tiny-date-picker/dist/date-range-picker';
import DateAdornment from './DateAdornment';
import { variantComponent } from './shared';
import { styles } from './useFieldStyles';
import 'tiny-date-picker/tiny-date-picker.css';
import 'tiny-date-picker/date-range-picker.css';

export interface DateRangePickerFieldProps
    extends Omit<InputProps, 'value' | 'onChange' | 'classes'>,
        WithStyles<typeof styles>,
        WithTranslation {
    label?: string;
    variant?: keyof typeof variantComponent;
    format: string;
    handlePickerChange?(start: Date, end: Date): void;
}

export interface DateRangePickerInputProps extends DateRangePickerFieldProps, FieldProps<{ start: Date; end: Date }> {}

type DateRangePickerState = {
    value: string;
    open: boolean;
};

class DateRangePickerInput extends Component<DateRangePickerInputProps, DateRangePickerState> {
    componentRef = createRef<HTMLDivElement>();

    datepickerRef = createRef<HTMLDivElement>();

    // TODO: create datepicker type
    picker: any;

    constructor(props) {
        super(props);

        this.state = { open: false, value: '' };
    }

    componentDidMount() {
        const { field, format } = this.props;
        const { value } = field;
        this.picker = TinyDateRangePicker(this.datepickerRef.current, {
            // options passed to the start date picker
            startOpts: {
                mode: 'dp-below',
            },
            // options passed to the end date picker
            endOpts: { mode: 'dp-below' },
        });

        if (value) {
            const updatedValue = {
                start: typeof value.start === 'string' ? dayjs(value.start).toDate() : value.start,
                end: typeof value.end === 'string' ? dayjs(value.end).toDate() : value.end,
            };

            this.picker.setState(updatedValue);

            this.setState({
                value: [dayjs(updatedValue.start).format(format), dayjs(updatedValue.end).format(format)].join(' - '),
            });
        }

        this.picker.on('statechange', this.onChange);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        const { format } = this.props;

        if (format !== prevProps.format) {
            const { field } = this.props;
            const { value } = field;
            if (value) {
                const updatedValue = {
                    start: typeof value.start === 'string' ? dayjs(value.start).toDate() : value.start,
                    end: typeof value.end === 'string' ? dayjs(value.end).toDate() : value.end,
                };

                this.picker.setState(updatedValue);
            }
        }
    }

    componentWillUnmount() {
        if (this.picker.destroy) {
            this.picker.destroy();
        }
    }

    onChange = (_, picker) => {
        const { form, field, format, handlePickerChange } = this.props;
        const { setFieldValue } = form;

        const { start, end } = picker.state;
        if (handlePickerChange) {
            handlePickerChange(start, end);
        }

        const value = [start ? dayjs(start).format(format) : '', end ? dayjs(end).format(format) : ''].join(' - ');

        this.setState({ value });

        setFieldValue(field.name, {
            start: start ? dayjs(start).toDate() : undefined,
            end: end ? dayjs(end).toDate() : undefined,
        });
    };

    onFocus = () => this.setState({ open: true });

    onFocusOut = () => {
        this.setState({ open: false });
    };

    render() {
        const { open, value } = this.state;

        const { label, classes, meta, field, variant = 'standard', ...props } = this.props;

        const hasError = !!meta.error && meta.touched;

        const InputComponent = variantComponent[variant];

        return (
            <ClickAwayListener onClickAway={this.onFocusOut}>
                <FormControl fullWidth>
                    {label && (
                        <InputLabel className={classes.label} shrink>
                            {label}
                        </InputLabel>
                    )}
                    <InputComponent
                        // spread input props
                        {...omit('form', props)}
                        // spread field
                        // onChange will be overriden by date picker
                        {...omit('onChange', field)}
                        // add end adornment
                        endAdornment={<DateAdornment position="end" />}
                        // error state
                        error={hasError}
                        // set on focus for showing date picker
                        onFocus={this.onFocus}
                        // formatted value from state
                        value={value}
                    />
                    <div ref={this.datepickerRef} className={clsx(classes.datepicker, open && 'open')} />
                    {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
                </FormControl>
            </ClickAwayListener>
        );
    }
}

const DateRangePickerField = (props: DateRangePickerFieldProps) => (
    <Field {...props}>{fieldProps => <DateRangePickerInput {...props} {...fieldProps} />}</Field>
);

export default compose(withStyles(styles))(DateRangePickerField);

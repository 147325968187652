import { useTranslation } from 'react-i18next';
import useIdentityStyles from '../../../layouts/BasicLayout/useIdentityStyles';

const LoginTitle = () => {
    const classes = useIdentityStyles();
    const { t } = useTranslation(['loginPage']);

    return <h5 className={classes.cardTitle}>{t('title')}</h5>;
};

export default LoginTitle;

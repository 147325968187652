import { InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const BasicInput = withStyles(theme => ({
    root: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: 0,
        padding: '2.25px 1rem',

        'label + &': {
            marginTop: theme.spacing(4),
        },
    },
}))(InputBase);

export default BasicInput;

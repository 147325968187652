import { Grid, Container } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useBookingsQuery } from '../../api';
import useOverviewStyles from '../../layouts/BasicLayout/useOverviewStyles';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import useOverviewLinks from '../../routes/OverviewRoutes/shared/useOverviewLinks';
import { getCurrentDate } from '../../utilities/commonFunctions';
import { ActiveBookingBanner, MenuLink, Promotions } from './components';

const OverviewPage = () => {
    const classes = useOverviewStyles();
    const { data } = useBookingsQuery();
    const links = useOverviewLinks();

    const activeBookings = useMemo(() => {
        if (!data) {
            return [];
        }
        const currentDate = getCurrentDate();

        return data.bookings.filter(
            booking =>
                currentDate >= new Date(booking.rentalStartDate) && currentDate <= new Date(booking.rentalEndDate)
        );
    }, [data]);

    return (
        <>
            <Container className={classes.overviewPage}>
                <Grid spacing={4} container>
                    {activeBookings.length > 0 && (
                        <Grid xs={12} item>
                            <ActiveBookingBanner bookings={activeBookings} />
                        </Grid>
                    )}
                    <Grid spacing={3} xs={12} container item>
                        {links.map(link => (
                            <Grid key={link.id} sm={3} xs={6} item>
                                <MenuLink icon={React.createElement(link.icon)} label={link.label} to={link.to} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Container>
            <Promotions />
        </>
    );
};

export default withErrorBoundary(OverviewPage);

import { Container, Grid, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';
import { useBookContext } from '../../../routes/BookRoutes/shared/BookingProvider';
import { setBookingData } from '../../../routes/BookRoutes/shared/actions';
import { BookingState, CustomerDetailsFormValues } from '../../../routes/BookRoutes/shared/types';
import { useGlobalContext } from '../../../utilities/shared/GlobalContextProvider';
import AssetSummary from '../components/AssetSummary';
import BillingSummary from '../components/BillingSummary';
import PickupAndDeliverySummary from '../components/PickupAndDeliverySummary';
import CustomerDetails from './CustomerDetails';

const CustomerPage = () => {
    const classes = useBookingStyles();
    const { state, dispatch } = useBookContext();
    const { state: globalState } = useGlobalContext();
    const { customer } = globalState;

    const { asset, date, packageSelection, pickUpDelivery }: BookingState = state;
    const { additionalOptions } = packageSelection;
    const { t } = useTranslation('customerPage');
    const history = useHistory();

    const validationSchema = useMemo(() => {
        const schema = yup.object().shape({
            displayName: yup.object().shape({
                value: yup.string().required(t('validation.required.displayName')),
            }),
            dob: yup.object().shape({
                value: yup.string().required(t('validation.required.dob')),
            }),
            email: yup.object().shape({
                value: yup
                    .string()
                    .required(t('validation.required.email'))
                    .email(t('validation.invalid.email'))
                    .max(320, t('validation.maxLength.email')),
            }),
            fullName: yup.object().shape({
                value: yup.string().required(t('validation.required.fullName')),
            }),
            gender: yup.object().shape({
                value: yup.string().required(t('validation.required.gender')),
            }),
            identityNo: yup.object().shape({
                value: yup.string().required(t('validation.required.identityNo')),
            }),
            mobile: yup.object().shape({
                value: yup
                    .string()
                    .required(t('validation.required.mobile'))
                    .min(8, t('validation.minLength.mobile'))
                    .max(8, t('validation.maxLength.mobile')),
            }),
            nationality: yup.object().shape({
                value: yup.string().required(t('validation.required.nationality')),
            }),
            race: yup.object().shape({
                value: yup.string().required(t('validation.required.race')),
            }),
            residentialAddress: yup.object().shape({
                block: yup.string().required(t('validation.required.residentialAddress.block')),
                country: yup.string().required(t('validation.required.residentialAddress.country')),
                postalCode: yup
                    .string()
                    .required(t('validation.required.residentialAddress.postalCode'))
                    .min(6, t('validation.minLength.residentialAddress.postalCode'))
                    .max(6, t('validation.maxLength.residentialAddress.postalCode')),
                street: yup.string().required(t('validation.required.residentialAddress.street')),
                unit: yup.string().required(t('validation.required.residentialAddress.unit')),
            }),
            salutation: yup.object().shape({
                value: yup.string().required(t('validation.required.salutation')),
            }),
            smsContact: yup.object().shape({
                value: yup
                    .string()
                    .required(t('validation.required.smsContact'))
                    .min(8, t('validation.minLength.smsContact'))
                    .max(8, t('validation.maxLength.smsContact')),
            }),
        });

        return schema;
    }, [t]);

    const handleApply = useCallback(
        data => {
            dispatch(setBookingData<CustomerDetailsFormValues>('customer', data));
            history.push('/book/driver');
        },
        [dispatch, history]
    );

    return (
        <Container className={classes.bookingPage} maxWidth="lg">
            <Formik initialValues={customer} onSubmit={handleApply} validationSchema={validationSchema}>
                {({ isSubmitting, errors }) => (
                    <Form style={{ width: '100%' }}>
                        <Grid spacing={5} container>
                            <Grid lg={8} sm={12} style={{ borderRight: 'solid 1px rgb(234,234,234)' }} item>
                                <Container maxWidth="md">
                                    <CustomerDetails initialValues={customer} />
                                </Container>
                            </Grid>
                            <Grid lg={4} sm={12} item>
                                <AssetSummary asset={state.asset} />
                                <div style={{ height: '1rem' }} />
                                <PickupAndDeliverySummary bookingDate={date} pickUpDelivery={pickUpDelivery} />
                                <div style={{ height: '1rem' }} />
                                <BillingSummary
                                    additionalOptions={additionalOptions}
                                    rentalFee={asset.restrictions.pricePerDay * date.days}
                                />
                                <div style={{ height: '1rem' }} />
                                <Button
                                    color="secondary"
                                    disabled={isSubmitting || !isEmpty(errors)}
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                    fullWidth
                                >
                                    {t('continueButtonText')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default withErrorBoundary(CustomerPage);

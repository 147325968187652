import { Button } from '@material-ui/core';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RentalTermsDrawer from './RentalTermsDrawer';

const RentalTerms = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    const { t } = useTranslation('booking');

    const { onClose, onToggle } = useMemo(
        () => ({
            onClose: () => setShowDrawer(false),
            onToggle: () => setShowDrawer(state => !state),
        }),
        [setShowDrawer]
    );

    return (
        <>
            <Button
                color="secondary"
                onClick={onToggle}
                style={{ fontWeight: 'bold' }}
                type="button"
                variant="outlined"
            >
                {t('rentTerm.title')}
            </Button>
            <RentalTermsDrawer onClose={onClose} open={showDrawer} />
        </>
    );
};

export default RentalTerms;

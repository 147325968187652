import { Box, Grid, Typography } from '@material-ui/core';
import { get } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetListDataFragment } from '../../../api';
import ImgIcon from '../../../components/ImgIcon';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';

interface FeatureProps {
    asset: AssetListDataFragment;
}

const mapFeatures = ({ label, value, classes, Icon }) => (
    <Grid key={label} xs={12} item>
        <div className={classes.featureRow}>
            <Box sx={{ display: 'flex' }}>
                <ImgIcon className={classes.featureIcon} url={Icon} />
                <Typography className={classes.featureLabel} component="h6">
                    <span>{label}</span>
                </Typography>
            </Box>
            <Typography className={classes.featureValue} component="h6">
                {value}
            </Typography>
        </div>
    </Grid>
);

const Features = ({ asset }: FeatureProps) => {
    const classes = useBookingStyles();
    const { t } = useTranslation('booking');

    const insuranceIcon = 'assets/images/insurance.svg';
    const passengerIcon = 'assets/images/passenger-capacity.svg';
    const luggageIcon = 'assets/images/luggage-capacity.svg';
    const bagIcon = 'assets/images/bags-capacity.svg';
    const transmissionIcon = 'assets/images/transmission.svg';
    const airConditioningIcon = 'assets/images/aircondition.svg';
    const doorsIcon = 'assets/images/doors.svg';

    const { features, capacities } = useMemo(
        () => ({
            features: [
                {
                    label: t('label.transmission'),
                    Icon: transmissionIcon,
                    value: get('technicalSpecifications.transmissionType', asset),
                },
                {
                    label: t('label.airConditioning'),
                    Icon: airConditioningIcon,
                    value: get('technicalSpecifications.hasRefrigeration', asset) ? 'Yes' : 'No',
                },
                {
                    label: t('label.doors'),
                    Icon: doorsIcon,
                    value: get('technicalSpecifications.numberOfDoors', asset),
                },
            ],
            capacities: [
                {
                    label: t('label.pasengerCapacity'),
                    Icon: passengerIcon,
                    value: get('technicalSpecifications.numberOfSeats', asset),
                },
                {
                    label: t('label.luggageCapacity'),
                    Icon: luggageIcon,
                    value: get('technicalSpecifications.luggageCapacity', asset),
                },
                {
                    label: t('label.bags'),
                    Icon: bagIcon,
                    value: get('technicalSpecifications.luggageCapacity', asset),
                },
            ],
        }),
        [asset, t, airConditioningIcon, transmissionIcon, doorsIcon, passengerIcon, luggageIcon, bagIcon]
    );

    return (
        <>
            <Grid container item>
                <Grid xs={12} item>
                    <Typography className={classes.featureHeader} component="h5">
                        {t('label.insurance')}
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <div className={classes.featureRow}>
                        <Box sx={{ display: 'flex' }}>
                            <ImgIcon className={classes.featureIcon} url={insuranceIcon} />
                            <Typography className={classes.featureLabel} component="h5">
                                {t('label.insuranceDescription')}
                            </Typography>
                        </Box>
                    </div>
                </Grid>
            </Grid>

            <Grid xs={12} container item>
                <Grid xs={12} item>
                    <Typography className={classes.featureHeader} component="h5">
                        {t('label.capacity')}
                    </Typography>
                </Grid>
                {capacities.map(item => mapFeatures({ ...item, classes }))}
            </Grid>
            <Grid xs={12} container item>
                <Grid xs={12} item>
                    <Typography className={classes.featureHeader} component="h5">
                        {t('label.features')}
                    </Typography>
                    {features.map(item => mapFeatures({ ...item, classes }))}
                </Grid>
            </Grid>
        </>
    );
};

export default Features;

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Option } from '../../../api';
import { SelectField, DatePickerField, ViewOnlyDateField } from '../../../components/inputs';

interface LicenseValidityProps {
    name: string;
    licenseValidityOptions: Option[];
    viewOnly?: boolean;
}

const LicenseValidity = ({ name, licenseValidityOptions, viewOnly = false }: LicenseValidityProps) => {
    const { t } = useTranslation('driverPage');

    return (
        <>
            <Grid md={6} xs={12} item>
                <SelectField
                    label={t('label.validity')}
                    name={`${name}.validity`}
                    options={licenseValidityOptions}
                    placeholder={t('placeholder.validity')}
                    viewOnly={viewOnly}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                {viewOnly && <ViewOnlyDateField label={t('label.expiryDate')} name={`${name}.expiryDate`} />}
                <div style={{ display: viewOnly ? 'none' : '' }}>
                    <DatePickerField
                        label={t('label.expiryDate')}
                        name={`${name}.expiryDate`}
                        placeholder={t('placeholder.expiryDate')}
                        required
                    />
                </div>
            </Grid>
        </>
    );
};

export default LicenseValidity;

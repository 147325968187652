import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { useGlobalContext } from '../../../utilities/shared/GlobalContextProvider';
import LicenceSuspension from './LicenceSuspension';
import LicenseClass from './LicenseClass';
import LicenseRevocation from './LicenseRevocation';
import LicenseValidity from './LicenseValidity';

interface DiverDetailsFormProps {
    licenseClasses: any;
}
const DriverDetailsForm = ({ licenseClasses }: DiverDetailsFormProps) => {
    const [t] = useTranslation('driverPage');
    const classes = useBookingStyles();
    const { state } = useGlobalContext();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Typography className={classes.subGroupTitle} component="p">
                    {t('drivingLicense.title')}
                </Typography>
            </Grid>
            <Grid spacing={2} xs={12} container item>
                {licenseClasses.map((item, index) => (
                    <LicenseClass
                        licenseClassOptions={state?.appOptions?.licenseClasses}
                        name={`licenseDetails.classes.${index}`}
                    />
                ))}
                <LicenseValidity licenseValidityOptions={state?.appOptions?.licenseValidity} name="licenseDetails" />
            </Grid>
            <Grid xs={12} item>
                <Typography className={classes.subGroupTitle} component="p">
                    {t('drivingLicenseSuspension.title')}
                </Typography>
            </Grid>
            <Grid spacing={2} xs={12} container item>
                <LicenceSuspension name="licenseDetails" />
            </Grid>
            <Grid xs={12} item>
                <Typography className={classes.subGroupTitle} component="p">
                    {t('drivingLicenseRevocation.title')}
                </Typography>
            </Grid>
            <Grid spacing={2} xs={12} container item>
                <LicenseRevocation name="licenseDetails" />
            </Grid>
        </Grid>
    );
};

export default DriverDetailsForm;

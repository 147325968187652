import { getCurrentDate } from '../../../utilities/commonFunctions';
import { SET_LANDING_PAGE_DATA, LandingPageActions, LandingPageState } from './actions';

const currentDate = getCurrentDate();

export const landingPageInitialState: LandingPageState = {
    paging: { page: 1, pageSize: 9, pages: 0, records: 1, sort: '10' },
    filter: {
        location: 'any',
        brand: 'any',
        carType: 'any',
        budget: [10, 500],
        budgetMin: 10,
        budgetMax: 500,
        date: { start: currentDate, end: currentDate, days: 1 },
    },
};

const reducer = (state, action: LandingPageActions): LandingPageState => {
    switch (action.type) {
        case SET_LANDING_PAGE_DATA:
            return {
                ...state,
                [action.step]: { ...action.data },
            };
        default:
            return state;
    }
};

export default reducer;

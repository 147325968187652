import { Box } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';

const TermsLabel = () => (
    <span>
        <Trans i18nKey="signUpPage:terms">
            {"I have read, understood and consented to appvatage's "}
            <Box color="secondary.main" component="span">
                {'terms of use '}
            </Box>
            and
            <Box color="secondary.main" component="span">
                {'privacy policy '}
            </Box>
            now!
        </Trans>
    </span>
);

export default TermsLabel;

import { Grid, Button, Typography } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { useField } from 'formik';
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadedFile, useUploadDocumentMutation } from '../../../api';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import SupportingDocument from './SupportingDocument';

interface SupportingDocumentsProps {
    name: string;
}

const SupportingDocuments = ({ name }: SupportingDocumentsProps) => {
    const classes = useBookingStyles();
    const [uploadDocument] = useUploadDocumentMutation();

    const [, meta, helpers] = useField(name);
    const { setValue } = helpers;

    const [documents, setDocuments] = useState<UploadedFile[]>(meta.initialValue || []);

    const { t } = useTranslation('customerPage');

    const imgRef = useRef<HTMLInputElement>(null);

    const openFileDialog = useCallback(() => {
        imgRef.current.click();
    }, [imgRef]);

    const handleUpload = useCallback(
        async event => {
            const file = event.target.files[0];

            const uploadResult = await uploadDocument({
                variables: { file },
            });

            const uploadedDocument = uploadResult.data?.uploadDocument || {
                filename: file.name,
                id: '',
                etag: '',
                size: 0,
                objectName: '',
                uploadedAt: '',
                signedUrl: '',
            };

            const doc: UploadedFile = {
                ...uploadedDocument,
            };

            setDocuments(previousState => [...previousState, doc]);
        },
        [uploadDocument]
    );

    const assignValue = useCallback((): void => setValue(documents), [documents]);

    useEffect(() => {
        assignValue();
    }, [assignValue]);

    const remove = (index: number) => {
        const newState = [...documents];
        newState.splice(index, 1);
        setDocuments([...newState]);
    };

    const render = (field: UploadedFile, index) => (
        <SupportingDocument key={field.id} fileName={field.filename} remove={(): void => remove(index)} />
    );

    return (
        <>
            <Grid xs={12} container item>
                <Grid md={10} xs={12} item>
                    <Typography component="p" style={{ textAlign: 'left' }}>
                        {t('label.supportingDocuments')}
                    </Typography>
                </Grid>
                <Grid md={2} xs={12} item>
                    <Button
                        color="secondary"
                        disabled={documents.length >= 5}
                        onClick={openFileDialog}
                        startIcon={<AddAPhotoIcon />}
                        variant="outlined"
                        fullWidth
                    >
                        {t('label.upload')}
                    </Button>
                    <input
                        ref={imgRef}
                        accept="image/jpg, image/png, image/tiff, image/gif, image/bmp, application/pdf"
                        onChange={handleUpload}
                        style={{ display: 'none' }}
                        type="file"
                    />
                </Grid>
            </Grid>

            <Grid xs={12} item>
                <ul className={classes.uploadList}>{documents.map(render)}</ul>
            </Grid>
        </>
    );
};
export default SupportingDocuments;

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SelectField, TextField } from '../../../components/inputs';
import { useGlobalContext } from '../../../utilities/shared/GlobalContextProvider';

const ContactDetailsSection = () => {
    const [t] = useTranslation('customerPage');
    const { state } = useGlobalContext();

    return (
        <Grid spacing={3} xs={12} container item>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.mobile')}
                    name="mobile.value"
                    placeholder={t('placeholder.mobile')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.smsContact')}
                    name="smsContact.value"
                    placeholder={t('placeholder.smsContact')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField label={t('label.email')} name="email.value" placeholder={t('placeholder.email')} required />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.residentialAddress.block')}
                    name="residentialAddress.block"
                    placeholder={t('placeholder.residentialAddress.block')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.residentialAddress.unit')}
                    name="residentialAddress.unit"
                    placeholder={t('placeholder.residentialAddress.unit')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.residentialAddress.street')}
                    name="residentialAddress.street"
                    placeholder={t('placeholder.residentialAddress.street')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.residentialAddress.building')}
                    name="residentialAddress.building"
                    placeholder={t('placeholder.residentialAddress.building')}
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <TextField
                    label={t('label.residentialAddress.postalCode')}
                    name="residentialAddress.postalCode"
                    placeholder={t('placeholder.residentialAddress.postalCode')}
                    required
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <SelectField
                    label={t('label.residentialAddress.country')}
                    name="residentialAddress.country"
                    options={state?.appOptions?.countries}
                    placeholder={t('placeholder.residentialAddress.country')}
                    required
                />
            </Grid>
        </Grid>
    );
};

export default ContactDetailsSection;

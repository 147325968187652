import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import usePublic from '../../utilities/usePublic';
import Drawer from './Drawer';
import useBasicStyles from './useBasicStyles';
import useUserProfileMenu from './useUserProfileMenu';

const Header = () => {
    const classes = useBasicStyles();
    const appvantageLogo = usePublic('assets/images/appvantageLogo.png');

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [showDrawer, setShowDrawer] = useState(false);

    const { onClose, onToggle } = useMemo(
        () => ({
            onClose: () => setShowDrawer(false),
            onToggle: event =>
                setShowDrawer(state => {
                    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                        return state;
                    }

                    return !state;
                }),
        }),
        [setShowDrawer]
    );

    const profileMenu = useUserProfileMenu();

    return (
        <AppBar className={classes.appBar}>
            <Toolbar variant="dense">
                <Link className={classes.logoLink} to="/">
                    <img alt="logo" className={classes.logo} src={appvantageLogo} />
                </Link>
                <Box alignItems="center" display="flex" ml="auto">
                    {matches ? (
                        <div>{profileMenu}</div>
                    ) : (
                        <>
                            <IconButton color="secondary" onClick={onToggle}>
                                <Menu />
                            </IconButton>
                            <Drawer anchor="right" onClose={onClose} open={showDrawer} />
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;

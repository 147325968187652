import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ResidentialAddress } from '../../../api';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { CustomerDetailsFormValues } from '../../../routes/BookRoutes/shared/types';

interface PersonalDetailsProps {
    cardNumber?: string;
    customer: CustomerDetailsFormValues;
}

const formatAddress = (address: ResidentialAddress) =>
    `${address.street} ${address.building} ${address.unit}, ${address.postalCode}`;

const PersonalDetails = ({ cardNumber, customer }: PersonalDetailsProps) => {
    const classes = useBookingStyles();
    const { t } = useTranslation('personalDetails');

    return (
        <Card>
            <CardContent>
                <Grid spacing={1} container>
                    <Grid xs={12} item>
                        <Typography className={classes.groupTitle} color="secondary" component="p">
                            {t('title')}
                        </Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <Typography className={classes.subGroupTitle} component="p">
                            {t('label.payment')}
                        </Typography>
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            {t('label.cardNumber')}
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            **** **** **** ****
                        </Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <Typography className={classes.subGroupTitle} component="p">
                            {t('label.primaryContact')}
                        </Typography>
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            {t('label.fullName')}
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {customer.fullName.value}
                        </Typography>
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            {t('label.mobileNo')}
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {customer.mobile.value}
                        </Typography>
                    </Grid>
                    <Grid direction="row" xs={12} container item>
                        <Typography className={classes.label} component="p">
                            {t('label.address')}
                        </Typography>
                        <Typography className={classes.asideValue} component="p">
                            {formatAddress(customer.residentialAddress)}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PersonalDetails;

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import ImgIcon from '../../../components/ImgIcon';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';

interface SupportingDocumentProps {
    fileName: string;
    remove: VoidFunction;
}

const SupportingDocument = ({ fileName, remove }: SupportingDocumentProps) => {
    const classes = useBookingStyles();

    return (
        <li>
            <div className={classes.uploadPreview}>
                <ImgIcon url="assets/images/document.svg" />
                <span>{fileName}</span>
                <IconButton onClick={remove} style={{ marginLeft: 'auto' }}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </li>
    );
};
export default SupportingDocument;

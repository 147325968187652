import { Container, Grid, Button } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';
import { useBookContext } from '../../../routes/BookRoutes/shared/BookingProvider';
import { setBookingData } from '../../../routes/BookRoutes/shared/actions';
import { BookingState, AdditionalOption, PackageSelection } from '../../../routes/BookRoutes/shared/types';
import { AssetDetails, AssetFeatures, BillingSummary, BookingDatesCard } from '../components';

const PackageSelectionPage = () => {
    const classes = useBookingStyles();
    const { state, dispatch } = useBookContext();
    const { asset, date }: BookingState = state;
    const { t } = useTranslation('booking');
    const history = useHistory();

    const [additionalOptions, setAdditionalOptions] = useState<AdditionalOption[]>([]);

    const onOptionsChange = useCallback(
        value => {
            setAdditionalOptions(prevState => {
                const currentItem = prevState.find(option => option.id === value.id);

                if (currentItem) {
                    currentItem.quantity = value.quantity;
                    currentItem.totalPrice = value.price * value.quantity;

                    return [...prevState];
                }

                return [...prevState, value];
            });
        },
        [setAdditionalOptions]
    );

    const handleApply = useCallback(() => {
        dispatch(setBookingData<PackageSelection>('packageSelection', { additionalOptions }));
        history.push('/book/customer');
    }, [additionalOptions, dispatch, history]);

    return (
        <Container className={classes.bookingPage} maxWidth="lg">
            <Grid spacing={5} container>
                <Grid lg={8} sm={12} style={{ borderRight: 'solid 1px rgb(234,234,234)' }} item>
                    <AssetDetails asset={asset} bookingDate={date} />
                    <AssetFeatures additionalItems={asset.additionalItems} asset={asset} onChange={onOptionsChange} />
                </Grid>
                <Grid lg={4} sm={12} item>
                    <BookingDatesCard bookingDate={date} />
                    <div style={{ height: '1rem' }} />
                    <BillingSummary
                        additionalOptions={additionalOptions}
                        rentalFee={asset.restrictions.pricePerDay * date.days}
                    />
                    <div style={{ height: '1rem' }} />
                    <Button color="secondary" onClick={handleApply} variant="contained" disableElevation fullWidth>
                        {t('label.continue')}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default withErrorBoundary(PackageSelectionPage);

import { LoginPage } from '../../pages/Login';

const initialValues = {
    username: '',
    password: '',
};

const LoginRoute = () => <LoginPage initialValues={initialValues} />;

export default LoginRoute;

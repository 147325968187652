import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';
import useOverviewLinks from '../../../routes/OverviewRoutes/shared/useOverviewLinks';
import OverviewLink from './OverviewLink';
import SelectNavLink from './SelectNavLink';

const OverviewLinks = () => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
    const location = useLocation();
    const history = useHistory();
    const classes = useOverviewStyles();
    const baseLinks = useOverviewLinks();
    const { t } = useTranslation('overviewPage');

    const links = useMemo(
        () =>
            [
                {
                    id: 'overview',
                    label: t('menus.overview'),
                    to: '/overview',
                    icon: AccountCircleIcon,
                },
                ...baseLinks,
            ].map(link => ({ ...link, value: link.to })),
        [baseLinks, t]
    );

    const [link, setLink] = useState(location.pathname);

    // set link and push to new route for select
    const onChange = useCallback(
        value => {
            setLink(value);
            history.push(value);
        },
        [history]
    );

    if (isDesktop) {
        return (
            <>
                {links.map(({ id, icon, ...data }) => (
                    <OverviewLink
                        key={id}
                        {...data}
                        icon={React.createElement(icon)}
                        onClick={() => setLink(data.value)}
                        exact
                    />
                ))}
            </>
        );
    }

    return (
        <SelectNavLink className={classes.selectNavLink} onChange={onChange} options={links} value={link} fullWidth />
    );
};

export default OverviewLinks;

import { Card, CardContent, Grid, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import usePublic from '../../../utilities/usePublic';

const MyInfoBox = () => {
    const { t } = useTranslation('customerPage');
    const myinfo = usePublic('assets/images/myinfonew.png');
    const singpass = usePublic('assets/images/singpass-new.png');

    return (
        <Card>
            <CardContent>
                <Grid spacing={1} container>
                    <Grid sm={1} xs={12} item>
                        <img alt="singpass" src={singpass} style={{ maxWidth: '100%' }} />
                    </Grid>
                    <Grid sm={9} xs={12} item>
                        <Typography>{t('myinfo')}</Typography>
                    </Grid>
                    <Grid sm={2} xs={12} item>
                        <Button>
                            <img alt="myinfo" src={myinfo} style={{ maxWidth: '100%' }} />
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default MyInfoBox;

/* eslint-disable max-len, camelcase */
import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: string | Date;
    ObjectID: string;
    Upload: File;
};

export type AccountFields = {
    fullName: CustomerStringValueInput;
    email: CustomerStringValueInput;
    mobilePhone: CustomerContactInput;
    identityNo: CustomerStringValueInput;
    dateOfBirth: CustomerDateValueInput;
    gender: CustomerStringValueInput;
    race: CustomerStringValueInput;
    maritalStatus: CustomerStringValueInput;
    nationality: CustomerStringValueInput;
    residentialAddress: ResidentialAddressInput;
    password?: Maybe<Scalars['String']>;
};

export type AccountRegistrationFields = {
    username: Scalars['String'];
    fullName: Scalars['String'];
    password: Scalars['String'];
    email: Scalars['String'];
    mobileNo: PhoneNumberInput;
};

export type AdditionalItemInput = {
    id: Scalars['ObjectID'];
    label: Scalars['String'];
    quantity: Scalars['Float'];
    price: Scalars['Float'];
    totalPrice: Scalars['Float'];
};

export type Address = {
    __typename?: 'Address';
    block: Scalars['String'];
    unit: Scalars['String'];
    street: Scalars['String'];
    building: Scalars['String'];
    country: Scalars['String'];
    postalCode: Scalars['String'];
};

export type AdminUser = User & {
    __typename?: 'AdminUser';
    id: Scalars['ObjectID'];
    username: Scalars['String'];
    fullName: CustomerStringValueType;
    displayName?: Maybe<CustomerStringValueType>;
    email: CustomerStringValueType;
    mobilePhone: CustomerContactType;
    smsContact?: Maybe<CustomerContactType>;
    type: UserType;
    status: UserStatus;
    registeredAt: Scalars['DateTime'];
};

export type AppOptions = {
    __typename?: 'AppOptions';
    businessTypes: Array<Option>;
    countries: Array<Option>;
    genders: Array<Option>;
    gendersManual: Array<Option>;
    races: Array<Option>;
    licenseClasses: Array<Option>;
    licenseValidity: Array<Option>;
    nationalities: Array<Option>;
    salutations: Array<Option>;
    maritalStatuses: Array<Option>;
    addressTypes: Array<Option>;
};

export type Asset = {
    __typename?: 'Asset';
    id: Scalars['ObjectID'];
    /** Public displayed on interfaces */
    identifier: Scalars['String'];
    make: Scalars['String'];
    model: Scalars['String'];
    variant: Scalars['String'];
    colour: Scalars['String'];
    status: AssetStatus;
    categoryId: Scalars['ObjectID'];
    category?: Maybe<AssetCategory>;
    providerId?: Maybe<Scalars['ObjectID']>;
    provider?: Maybe<AssetProvider>;
    currentLocation?: Maybe<Location>;
    images: Array<UploadedFile>;
    technicalSpecifications: TechnicalSpecifications;
    restrictions: AssetRestrictions;
    additionalItems: Array<Maybe<AssetAdditionalItem>>;
};

export type AssetAdditionalItem = {
    __typename?: 'AssetAdditionalItem';
    id: Scalars['ObjectID'];
    label: Scalars['String'];
    price: Scalars['Float'];
};

export type AssetCategory = {
    __typename?: 'AssetCategory';
    id: Scalars['ObjectID'];
    code: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    parentId?: Maybe<Scalars['ObjectID']>;
};

export type AssetFeature = {
    __typename?: 'AssetFeature';
    icon: Scalars['String'];
    description: Scalars['String'];
};

export type AssetProvider = {
    __typename?: 'AssetProvider';
    id: Scalars['ObjectID'];
    code: Scalars['String'];
    description?: Maybe<Scalars['String']>;
};

export type AssetRestrictions = {
    __typename?: 'AssetRestrictions';
    pricePerHour: Scalars['Float'];
    pricePerDay: Scalars['Float'];
    pricePerWeek: Scalars['Float'];
    pricePerMonth: Scalars['Float'];
    mileageCap?: Maybe<Scalars['Float']>;
    returnLocation?: Maybe<Coordinate>;
};

export enum AssetStatus {
    Active = 'Active',
    Inactive = 'Inactive',
}

export type AuthenticationSuccessful = {
    __typename?: 'AuthenticationSuccessful';
    /** User authenticated */
    user: User;
    /** Json Web Token */
    token: Scalars['String'];
};

export type BookInputFields = {
    supportingDocuments?: Maybe<Array<UploadedFileInput>>;
    assetId: Scalars['ObjectID'];
    rentalStartDate: Scalars['DateTime'];
    rentalEndDate: Scalars['DateTime'];
    days: Scalars['Float'];
    price: Scalars['Float'];
    gst: Scalars['Float'];
    totalCost: Scalars['Float'];
    rentalAgreement?: Maybe<Scalars['String']>;
    additionalItems?: Maybe<Array<AdditionalItemInput>>;
    customer: CustomerFields;
};

export type Booking = {
    __typename?: 'Booking';
    id: Scalars['ObjectID'];
    customerId: Scalars['ObjectID'];
    customer: Customer;
    supportingDocuments?: Maybe<Array<UploadedFile>>;
    assetId: Scalars['ObjectID'];
    asset: Asset;
    rentalStartDate: Scalars['DateTime'];
    rentalEndDate: Scalars['DateTime'];
    days: Scalars['Float'];
    price: Scalars['Float'];
    gst: Scalars['Float'];
    totalCost: Scalars['Float'];
    rentalAgreement?: Maybe<Scalars['String']>;
    additionalItems?: Maybe<Array<AssetAdditionalItem>>;
};

export type ContactType = {
    __typename?: 'ContactType';
    value: Scalars['String'];
    internationalCode: Scalars['String'];
};

export type Coordinate = {
    __typename?: 'Coordinate';
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
};

export type Customer = User & {
    __typename?: 'Customer';
    id: Scalars['ObjectID'];
    username: Scalars['String'];
    fullName: CustomerStringValueType;
    displayName?: Maybe<CustomerStringValueType>;
    email: CustomerStringValueType;
    mobilePhone: CustomerContactType;
    smsContact?: Maybe<CustomerContactType>;
    type: UserType;
    status: UserStatus;
    registeredAt: Scalars['DateTime'];
    particulars?: Maybe<PersonParticulars>;
    subscribeToNewsLetter?: Maybe<Scalars['Boolean']>;
    residentialAddress?: Maybe<ResidentialAddress>;
    licenseDetails?: Maybe<LicenseDetails>;
    profilePicture?: Maybe<UploadedFile>;
};

export type CustomerContactInput = {
    source: CustomerDetailsSource;
    value: Scalars['String'];
    internationalCode: Scalars['String'];
};

export type CustomerContactType = {
    __typename?: 'CustomerContactType';
    source?: Maybe<CustomerDetailsSource>;
    value?: Maybe<Scalars['String']>;
    internationalCode?: Maybe<Scalars['String']>;
};

export type CustomerDateValueInput = {
    source: CustomerDetailsSource;
    value: Scalars['DateTime'];
};

export type CustomerDateValueType = {
    __typename?: 'CustomerDateValueType';
    source: CustomerDetailsSource;
    value: Scalars['DateTime'];
};

export enum CustomerDetailsSource {
    Government = 'Government',
    User = 'User',
    Na = 'NA',
    Singpass = 'Singpass',
    Manual = 'Manual',
}

export type CustomerFields = {
    fullName: CustomerStringValueInput;
    displayName: CustomerStringValueInput;
    email: CustomerStringValueInput;
    mobilePhone: CustomerContactInput;
    smsContact: CustomerContactInput;
    particulars: PersonParticularsInput;
    residentialAddress: ResidentialAddressInput;
    licenseDetails: LicenseDetailsInput;
};

export type CustomerStringValueInput = {
    source: CustomerDetailsSource;
    value: Scalars['String'];
};

export type CustomerStringValueType = {
    __typename?: 'CustomerStringValueType';
    source: CustomerDetailsSource;
    value: Scalars['String'];
};

export type DateRangeInput = {
    start: Scalars['DateTime'];
    end: Scalars['DateTime'];
};

export enum DeliveryMethod {
    Pickup = 'Pickup',
    Delivery = 'Delivery',
}

export type LicenseClass = {
    __typename?: 'LicenseClass';
    class: Scalars['String'];
    issueDate: Scalars['DateTime'];
};

export type LicenseClassInput = {
    class: Scalars['String'];
    issueDate: Scalars['DateTime'];
};

export type LicenseDates = {
    __typename?: 'LicenseDates';
    startDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
};

export type LicenseDatesInput = {
    startDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
};

export type LicenseDetails = {
    __typename?: 'LicenseDetails';
    source: CustomerDetailsSource;
    number: Scalars['String'];
    validity: Scalars['String'];
    expiryDate: Scalars['DateTime'];
    classes: Array<LicenseClass>;
    suspension?: Maybe<LicenseDates>;
    revocation?: Maybe<LicenseDates>;
    disqualification?: Maybe<LicenseDates>;
};

export type LicenseDetailsInput = {
    source: CustomerDetailsSource;
    number: Scalars['String'];
    validity: Scalars['String'];
    expiryDate: Scalars['DateTime'];
    classes: Array<LicenseClassInput>;
    suspension?: Maybe<LicenseDatesInput>;
    revocation?: Maybe<LicenseDatesInput>;
    disqualification?: Maybe<LicenseDatesInput>;
};

export type Location = {
    __typename?: 'Location';
    id: Scalars['ObjectID'];
    name: Scalars['String'];
    coordinate: Coordinate;
    phone: ContactType;
    email: Scalars['String'];
    address?: Maybe<Address>;
    workingHours: Array<WorkingHour>;
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Create a new account/user */
    createAccount: User;
    /** Validate credentials (username/password) and return a Json Web Token */
    authenticate: AuthenticationSuccessful;
    /**
     * Take the Json Web Token (JWT) from headers and returns a new one with a renewed lifetime
     *
     * Authentication is required
     */
    refreshCredentials: Scalars['String'];
    submitBooking?: Maybe<Booking>;
    uploadProfilePicture?: Maybe<UploadedFile>;
    uploadDocument?: Maybe<UploadedFile>;
    saveUserAccountDetails?: Maybe<Customer>;
    saveDriverDetails?: Maybe<Customer>;
};

export type MutationCreateAccountArgs = {
    fields: AccountRegistrationFields;
};

export type MutationAuthenticateArgs = {
    username: Scalars['String'];
    password: Scalars['String'];
};

export type MutationSubmitBookingArgs = {
    fields?: Maybe<BookInputFields>;
};

export type MutationUploadProfilePictureArgs = {
    file: Scalars['Upload'];
};

export type MutationUploadDocumentArgs = {
    file: Scalars['Upload'];
};

export type MutationSaveUserAccountDetailsArgs = {
    fields: AccountFields;
};

export type MutationSaveDriverDetailsArgs = {
    fields: LicenseDetailsInput;
};

export type Option = {
    __typename?: 'Option';
    label: Scalars['String'];
    value: Scalars['String'];
};

export type Pagination = {
    /** Offset to apply when fetching a list */
    offset: Scalars['Int'];
    /** Number of items to fetch from a list */
    limit: Scalars['Int'];
};

export type PersonParticulars = {
    __typename?: 'PersonParticulars';
    identityNo: CustomerStringValueType;
    dateOfBirth: CustomerDateValueType;
    gender: CustomerStringValueType;
    race: CustomerStringValueType;
    maritalStatus: CustomerStringValueType;
    nationality: CustomerStringValueType;
    salutation?: Maybe<CustomerStringValueType>;
};

export type PersonParticularsInput = {
    identityNo: CustomerStringValueInput;
    dateOfBirth: CustomerDateValueInput;
    gender: CustomerStringValueInput;
    race: CustomerStringValueInput;
    maritalStatus: CustomerStringValueInput;
    nationality: CustomerStringValueInput;
    salutation?: Maybe<CustomerStringValueInput>;
};

export type PhoneNumberInput = {
    internationalCode: Scalars['String'];
    number: Scalars['String'];
};

export type Query = {
    __typename?: 'Query';
    appOptions?: Maybe<AppOptions>;
    /** Fetch user document for the logged in user, returns null otherwise for anonymous */
    account?: Maybe<User>;
    /** Fetch customer document for the logged in user, returns null otherwise for anonymous */
    customer?: Maybe<Customer>;
    /** Fetch list of assets */
    assets: Array<Asset>;
    /** Get all office locations */
    listLocations: Array<Location>;
    /** Fetch current user bookings */
    bookings: Array<Booking>;
};

export type QueryAssetsArgs = {
    filter: SearchAssetInput;
};

export type ResidentialAddress = {
    __typename?: 'ResidentialAddress';
    source: CustomerDetailsSource;
    block: Scalars['String'];
    unit: Scalars['String'];
    street: Scalars['String'];
    building?: Maybe<Scalars['String']>;
    country: Scalars['String'];
    postalCode: Scalars['String'];
    addressType?: Maybe<CustomerStringValueType>;
};

export type ResidentialAddressInput = {
    source: CustomerDetailsSource;
    block: Scalars['String'];
    unit: Scalars['String'];
    street: Scalars['String'];
    building?: Maybe<Scalars['String']>;
    country: Scalars['String'];
    postalCode: Scalars['String'];
    addressType: CustomerStringValueInput;
};

export type SearchAssetInput = {
    bookingDate?: Maybe<DateRangeInput>;
    location: Scalars['String'];
    brand: Scalars['String'];
    carType: Scalars['String'];
};

export enum SortingOrder {
    Asc = 'Asc',
    Desc = 'Desc',
}

export type TechnicalSpecifications = {
    __typename?: 'TechnicalSpecifications';
    mileage?: Maybe<Scalars['Float']>;
    numberOfSeats?: Maybe<Scalars['Int']>;
    fuelCapacityLitres?: Maybe<Scalars['Float']>;
    numberOfGears?: Maybe<Scalars['Int']>;
    length?: Maybe<Scalars['Float']>;
    numberOfAxles?: Maybe<Scalars['Float']>;
    numberOfWheels?: Maybe<Scalars['Int']>;
    hasTailLift?: Maybe<Scalars['Boolean']>;
    hasRefrigeration?: Maybe<Scalars['Boolean']>;
    fuelType?: Maybe<Scalars['String']>;
    features: Array<AssetFeature>;
};

export type UploadedFile = {
    __typename?: 'UploadedFile';
    etag: Scalars['String'];
    filename: Scalars['String'];
    id: Scalars['ObjectID'];
    objectName: Scalars['String'];
    signedUrl: Scalars['String'];
    size: Scalars['Int'];
    uploadedAt: Scalars['DateTime'];
};

export type UploadedFileInput = {
    id: Scalars['String'];
    filename: Scalars['String'];
    etag: Scalars['String'];
    size: Scalars['Int'];
    objectName: Scalars['String'];
    uploadedAt: Scalars['DateTime'];
};

export type User = {
    id: Scalars['ObjectID'];
    username: Scalars['String'];
    fullName: CustomerStringValueType;
    displayName?: Maybe<CustomerStringValueType>;
    email: CustomerStringValueType;
    mobilePhone: CustomerContactType;
    smsContact?: Maybe<CustomerContactType>;
    type: UserType;
    status: UserStatus;
    registeredAt: Scalars['DateTime'];
};

export enum UserStatus {
    Active = 'Active',
    Disabled = 'Disabled',
}

export enum UserType {
    Admin = 'Admin',
    Customer = 'Customer',
}

export type WorkingHour = {
    __typename?: 'WorkingHour';
    day: Scalars['String'];
    open: Scalars['String'];
    close: Scalars['String'];
};

export type AppOptionsAllFragment = {
    __typename?: 'AppOptions';
    businessTypes: Array<{ __typename?: 'Option'; label: string; value: string }>;
    countries: Array<{ __typename?: 'Option'; label: string; value: string }>;
    genders: Array<{ __typename?: 'Option'; label: string; value: string }>;
    gendersManual: Array<{ __typename?: 'Option'; label: string; value: string }>;
    races: Array<{ __typename?: 'Option'; label: string; value: string }>;
    licenseClasses: Array<{ __typename?: 'Option'; label: string; value: string }>;
    licenseValidity: Array<{ __typename?: 'Option'; label: string; value: string }>;
    nationalities: Array<{ __typename?: 'Option'; label: string; value: string }>;
    salutations: Array<{ __typename?: 'Option'; label: string; value: string }>;
    maritalStatuses: Array<{ __typename?: 'Option'; label: string; value: string }>;
    addressTypes: Array<{ __typename?: 'Option'; label: string; value: string }>;
};

export type LocationFullDataFragment = {
    __typename?: 'Location';
    id: string;
    name: string;
    coordinate: { __typename?: 'Coordinate'; latitude: number; longitude: number };
    phone: { __typename?: 'ContactType'; value: string; internationalCode: string };
    workingHours: Array<{ __typename?: 'WorkingHour'; day: string; open: string; close: string }>;
    address?: Maybe<{
        __typename?: 'Address';
        building: string;
        country: string;
        postalCode: string;
        street: string;
        unit: string;
        block: string;
    }>;
};

export type AppOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type AppOptionsQuery = {
    __typename?: 'Query';
    appOptions?: Maybe<{
        __typename?: 'AppOptions';
        businessTypes: Array<{ __typename?: 'Option'; label: string; value: string }>;
        countries: Array<{ __typename?: 'Option'; label: string; value: string }>;
        genders: Array<{ __typename?: 'Option'; label: string; value: string }>;
        gendersManual: Array<{ __typename?: 'Option'; label: string; value: string }>;
        races: Array<{ __typename?: 'Option'; label: string; value: string }>;
        licenseClasses: Array<{ __typename?: 'Option'; label: string; value: string }>;
        licenseValidity: Array<{ __typename?: 'Option'; label: string; value: string }>;
        nationalities: Array<{ __typename?: 'Option'; label: string; value: string }>;
        salutations: Array<{ __typename?: 'Option'; label: string; value: string }>;
        maritalStatuses: Array<{ __typename?: 'Option'; label: string; value: string }>;
        addressTypes: Array<{ __typename?: 'Option'; label: string; value: string }>;
    }>;
};

export type AssetListDataFragment = {
    __typename?: 'Asset';
    id: string;
    identifier: string;
    make: string;
    model: string;
    variant: string;
    colour: string;
    status: AssetStatus;
    categoryId: string;
    providerId?: Maybe<string>;
    category?: Maybe<{ __typename?: 'AssetCategory'; code: string; description?: Maybe<string> }>;
    provider?: Maybe<{ __typename?: 'AssetProvider'; code: string; description?: Maybe<string> }>;
    currentLocation?: Maybe<{
        __typename?: 'Location';
        id: string;
        name: string;
        coordinate: { __typename?: 'Coordinate'; latitude: number; longitude: number };
    }>;
    images: Array<{ __typename?: 'UploadedFile'; signedUrl: string }>;
    technicalSpecifications: {
        __typename?: 'TechnicalSpecifications';
        mileage?: Maybe<number>;
        numberOfSeats?: Maybe<number>;
        fuelCapacityLitres?: Maybe<number>;
        numberOfGears?: Maybe<number>;
        length?: Maybe<number>;
        numberOfAxles?: Maybe<number>;
        numberOfWheels?: Maybe<number>;
        hasTailLift?: Maybe<boolean>;
        hasRefrigeration?: Maybe<boolean>;
        fuelType?: Maybe<string>;
        features: Array<{ __typename?: 'AssetFeature'; icon: string; description: string }>;
    };
    restrictions: {
        __typename?: 'AssetRestrictions';
        pricePerHour: number;
        pricePerDay: number;
        pricePerWeek: number;
        pricePerMonth: number;
        mileageCap?: Maybe<number>;
        returnLocation?: Maybe<{ __typename?: 'Coordinate'; latitude: number; longitude: number }>;
    };
    additionalItems: Array<Maybe<{ __typename?: 'AssetAdditionalItem'; id: string; label: string; price: number }>>;
};

export type AssetsQueryVariables = Exact<{
    filter: SearchAssetInput;
}>;

export type AssetsQuery = {
    __typename?: 'Query';
    assets: Array<{
        __typename?: 'Asset';
        id: string;
        identifier: string;
        make: string;
        model: string;
        variant: string;
        colour: string;
        status: AssetStatus;
        categoryId: string;
        providerId?: Maybe<string>;
        category?: Maybe<{ __typename?: 'AssetCategory'; code: string; description?: Maybe<string> }>;
        provider?: Maybe<{ __typename?: 'AssetProvider'; code: string; description?: Maybe<string> }>;
        currentLocation?: Maybe<{
            __typename?: 'Location';
            id: string;
            name: string;
            coordinate: { __typename?: 'Coordinate'; latitude: number; longitude: number };
        }>;
        images: Array<{ __typename?: 'UploadedFile'; signedUrl: string }>;
        technicalSpecifications: {
            __typename?: 'TechnicalSpecifications';
            mileage?: Maybe<number>;
            numberOfSeats?: Maybe<number>;
            fuelCapacityLitres?: Maybe<number>;
            numberOfGears?: Maybe<number>;
            length?: Maybe<number>;
            numberOfAxles?: Maybe<number>;
            numberOfWheels?: Maybe<number>;
            hasTailLift?: Maybe<boolean>;
            hasRefrigeration?: Maybe<boolean>;
            fuelType?: Maybe<string>;
            features: Array<{ __typename?: 'AssetFeature'; icon: string; description: string }>;
        };
        restrictions: {
            __typename?: 'AssetRestrictions';
            pricePerHour: number;
            pricePerDay: number;
            pricePerWeek: number;
            pricePerMonth: number;
            mileageCap?: Maybe<number>;
            returnLocation?: Maybe<{ __typename?: 'Coordinate'; latitude: number; longitude: number }>;
        };
        additionalItems: Array<Maybe<{ __typename?: 'AssetAdditionalItem'; id: string; label: string; price: number }>>;
    }>;
};

export type AuthenticateMutationVariables = Exact<{
    username: Scalars['String'];
    password: Scalars['String'];
}>;

export type AuthenticateMutation = {
    __typename?: 'Mutation';
    authenticate: {
        __typename?: 'AuthenticationSuccessful';
        token: string;
        user: { __typename?: 'AdminUser'; id: string } | { __typename?: 'Customer'; id: string };
    };
};

export type RefreshCredentialsMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshCredentialsMutation = { __typename?: 'Mutation'; refreshCredentials: string };

export type BookingListDataFragment = {
    __typename?: 'Booking';
    id: string;
    rentalStartDate: string | Date;
    rentalEndDate: string | Date;
    days: number;
    price: number;
    gst: number;
    totalCost: number;
    rentalAgreement?: Maybe<string>;
    asset: {
        __typename?: 'Asset';
        id: string;
        identifier: string;
        make: string;
        model: string;
        variant: string;
        colour: string;
        status: AssetStatus;
        categoryId: string;
        providerId?: Maybe<string>;
        category?: Maybe<{ __typename?: 'AssetCategory'; code: string; description?: Maybe<string> }>;
        provider?: Maybe<{ __typename?: 'AssetProvider'; code: string; description?: Maybe<string> }>;
        currentLocation?: Maybe<{
            __typename?: 'Location';
            id: string;
            name: string;
            coordinate: { __typename?: 'Coordinate'; latitude: number; longitude: number };
        }>;
        images: Array<{ __typename?: 'UploadedFile'; signedUrl: string }>;
        technicalSpecifications: {
            __typename?: 'TechnicalSpecifications';
            mileage?: Maybe<number>;
            numberOfSeats?: Maybe<number>;
            fuelCapacityLitres?: Maybe<number>;
            numberOfGears?: Maybe<number>;
            length?: Maybe<number>;
            numberOfAxles?: Maybe<number>;
            numberOfWheels?: Maybe<number>;
            hasTailLift?: Maybe<boolean>;
            hasRefrigeration?: Maybe<boolean>;
            fuelType?: Maybe<string>;
            features: Array<{ __typename?: 'AssetFeature'; icon: string; description: string }>;
        };
        restrictions: {
            __typename?: 'AssetRestrictions';
            pricePerHour: number;
            pricePerDay: number;
            pricePerWeek: number;
            pricePerMonth: number;
            mileageCap?: Maybe<number>;
            returnLocation?: Maybe<{ __typename?: 'Coordinate'; latitude: number; longitude: number }>;
        };
        additionalItems: Array<Maybe<{ __typename?: 'AssetAdditionalItem'; id: string; label: string; price: number }>>;
    };
    additionalItems?: Maybe<Array<{ __typename?: 'AssetAdditionalItem'; id: string; label: string; price: number }>>;
};

export type BookingsQueryVariables = Exact<{ [key: string]: never }>;

export type BookingsQuery = {
    __typename?: 'Query';
    bookings: Array<{
        __typename?: 'Booking';
        id: string;
        rentalStartDate: string | Date;
        rentalEndDate: string | Date;
        days: number;
        price: number;
        gst: number;
        totalCost: number;
        rentalAgreement?: Maybe<string>;
        asset: {
            __typename?: 'Asset';
            id: string;
            identifier: string;
            make: string;
            model: string;
            variant: string;
            colour: string;
            status: AssetStatus;
            categoryId: string;
            providerId?: Maybe<string>;
            category?: Maybe<{ __typename?: 'AssetCategory'; code: string; description?: Maybe<string> }>;
            provider?: Maybe<{ __typename?: 'AssetProvider'; code: string; description?: Maybe<string> }>;
            currentLocation?: Maybe<{
                __typename?: 'Location';
                id: string;
                name: string;
                coordinate: { __typename?: 'Coordinate'; latitude: number; longitude: number };
            }>;
            images: Array<{ __typename?: 'UploadedFile'; signedUrl: string }>;
            technicalSpecifications: {
                __typename?: 'TechnicalSpecifications';
                mileage?: Maybe<number>;
                numberOfSeats?: Maybe<number>;
                fuelCapacityLitres?: Maybe<number>;
                numberOfGears?: Maybe<number>;
                length?: Maybe<number>;
                numberOfAxles?: Maybe<number>;
                numberOfWheels?: Maybe<number>;
                hasTailLift?: Maybe<boolean>;
                hasRefrigeration?: Maybe<boolean>;
                fuelType?: Maybe<string>;
                features: Array<{ __typename?: 'AssetFeature'; icon: string; description: string }>;
            };
            restrictions: {
                __typename?: 'AssetRestrictions';
                pricePerHour: number;
                pricePerDay: number;
                pricePerWeek: number;
                pricePerMonth: number;
                mileageCap?: Maybe<number>;
                returnLocation?: Maybe<{ __typename?: 'Coordinate'; latitude: number; longitude: number }>;
            };
            additionalItems: Array<
                Maybe<{ __typename?: 'AssetAdditionalItem'; id: string; label: string; price: number }>
            >;
        };
        additionalItems?: Maybe<
            Array<{ __typename?: 'AssetAdditionalItem'; id: string; label: string; price: number }>
        >;
    }>;
};

export type CreateAccountMutationVariables = Exact<{
    fields: AccountRegistrationFields;
}>;

export type CreateAccountMutation = {
    __typename?: 'Mutation';
    createAccount: { __typename?: 'AdminUser'; id: string } | { __typename?: 'Customer'; id: string };
};

export type CustomerQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerQuery = {
    __typename?: 'Query';
    customer?: Maybe<{
        __typename?: 'Customer';
        id: string;
        username: string;
        type: UserType;
        status: UserStatus;
        registeredAt: string | Date;
        subscribeToNewsLetter?: Maybe<boolean>;
        profilePicture?: Maybe<{ __typename?: 'UploadedFile'; signedUrl: string }>;
        fullName: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        displayName?: Maybe<{ __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string }>;
        email: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        mobilePhone: {
            __typename?: 'CustomerContactType';
            source?: Maybe<CustomerDetailsSource>;
            value?: Maybe<string>;
            internationalCode?: Maybe<string>;
        };
        smsContact?: Maybe<{
            __typename?: 'CustomerContactType';
            source?: Maybe<CustomerDetailsSource>;
            value?: Maybe<string>;
            internationalCode?: Maybe<string>;
        }>;
        particulars?: Maybe<{
            __typename?: 'PersonParticulars';
            identityNo: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            dateOfBirth: { __typename?: 'CustomerDateValueType'; source: CustomerDetailsSource; value: string | Date };
            gender: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            race: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            maritalStatus: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            nationality: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            salutation?: Maybe<{
                __typename?: 'CustomerStringValueType';
                source: CustomerDetailsSource;
                value: string;
            }>;
        }>;
        licenseDetails?: Maybe<{
            __typename?: 'LicenseDetails';
            source: CustomerDetailsSource;
            number: string;
            validity: string;
            expiryDate: string | Date;
            classes: Array<{ __typename?: 'LicenseClass'; class: string; issueDate: string | Date }>;
            suspension?: Maybe<{
                __typename?: 'LicenseDates';
                startDate?: Maybe<string | Date>;
                endDate?: Maybe<string | Date>;
            }>;
            revocation?: Maybe<{
                __typename?: 'LicenseDates';
                startDate?: Maybe<string | Date>;
                endDate?: Maybe<string | Date>;
            }>;
            disqualification?: Maybe<{
                __typename?: 'LicenseDates';
                startDate?: Maybe<string | Date>;
                endDate?: Maybe<string | Date>;
            }>;
        }>;
        residentialAddress?: Maybe<{
            __typename?: 'ResidentialAddress';
            source: CustomerDetailsSource;
            block: string;
            unit: string;
            street: string;
            building?: Maybe<string>;
            country: string;
            postalCode: string;
            addressType?: Maybe<{
                __typename?: 'CustomerStringValueType';
                source: CustomerDetailsSource;
                value: string;
            }>;
        }>;
    }>;
};

export type ListLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type ListLocationsQuery = {
    __typename?: 'Query';
    listLocations: Array<{
        __typename?: 'Location';
        id: string;
        name: string;
        coordinate: { __typename?: 'Coordinate'; latitude: number; longitude: number };
        phone: { __typename?: 'ContactType'; value: string; internationalCode: string };
        workingHours: Array<{ __typename?: 'WorkingHour'; day: string; open: string; close: string }>;
        address?: Maybe<{
            __typename?: 'Address';
            building: string;
            country: string;
            postalCode: string;
            street: string;
            unit: string;
            block: string;
        }>;
    }>;
};

export type SaveDriverDetailsMutationVariables = Exact<{
    fields: LicenseDetailsInput;
}>;

export type SaveDriverDetailsMutation = {
    __typename?: 'Mutation';
    saveDriverDetails?: Maybe<{
        __typename?: 'Customer';
        id: string;
        username: string;
        type: UserType;
        status: UserStatus;
        registeredAt: string | Date;
        subscribeToNewsLetter?: Maybe<boolean>;
        profilePicture?: Maybe<{ __typename?: 'UploadedFile'; signedUrl: string }>;
        fullName: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        displayName?: Maybe<{ __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string }>;
        email: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        mobilePhone: {
            __typename?: 'CustomerContactType';
            source?: Maybe<CustomerDetailsSource>;
            value?: Maybe<string>;
            internationalCode?: Maybe<string>;
        };
        smsContact?: Maybe<{
            __typename?: 'CustomerContactType';
            source?: Maybe<CustomerDetailsSource>;
            value?: Maybe<string>;
            internationalCode?: Maybe<string>;
        }>;
        particulars?: Maybe<{
            __typename?: 'PersonParticulars';
            identityNo: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            dateOfBirth: { __typename?: 'CustomerDateValueType'; source: CustomerDetailsSource; value: string | Date };
            gender: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            race: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            maritalStatus: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            nationality: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            salutation?: Maybe<{
                __typename?: 'CustomerStringValueType';
                source: CustomerDetailsSource;
                value: string;
            }>;
        }>;
        licenseDetails?: Maybe<{
            __typename?: 'LicenseDetails';
            source: CustomerDetailsSource;
            number: string;
            validity: string;
            expiryDate: string | Date;
            classes: Array<{ __typename?: 'LicenseClass'; class: string; issueDate: string | Date }>;
            suspension?: Maybe<{
                __typename?: 'LicenseDates';
                startDate?: Maybe<string | Date>;
                endDate?: Maybe<string | Date>;
            }>;
            revocation?: Maybe<{
                __typename?: 'LicenseDates';
                startDate?: Maybe<string | Date>;
                endDate?: Maybe<string | Date>;
            }>;
            disqualification?: Maybe<{
                __typename?: 'LicenseDates';
                startDate?: Maybe<string | Date>;
                endDate?: Maybe<string | Date>;
            }>;
        }>;
        residentialAddress?: Maybe<{
            __typename?: 'ResidentialAddress';
            source: CustomerDetailsSource;
            block: string;
            unit: string;
            street: string;
            building?: Maybe<string>;
            country: string;
            postalCode: string;
            addressType?: Maybe<{
                __typename?: 'CustomerStringValueType';
                source: CustomerDetailsSource;
                value: string;
            }>;
        }>;
    }>;
};

export type SaveUserAccountDetailsMutationVariables = Exact<{
    fields: AccountFields;
}>;

export type SaveUserAccountDetailsMutation = {
    __typename?: 'Mutation';
    saveUserAccountDetails?: Maybe<{
        __typename?: 'Customer';
        id: string;
        username: string;
        type: UserType;
        status: UserStatus;
        registeredAt: string | Date;
        subscribeToNewsLetter?: Maybe<boolean>;
        profilePicture?: Maybe<{ __typename?: 'UploadedFile'; signedUrl: string }>;
        fullName: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        displayName?: Maybe<{ __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string }>;
        email: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        mobilePhone: {
            __typename?: 'CustomerContactType';
            source?: Maybe<CustomerDetailsSource>;
            value?: Maybe<string>;
            internationalCode?: Maybe<string>;
        };
        smsContact?: Maybe<{
            __typename?: 'CustomerContactType';
            source?: Maybe<CustomerDetailsSource>;
            value?: Maybe<string>;
            internationalCode?: Maybe<string>;
        }>;
        particulars?: Maybe<{
            __typename?: 'PersonParticulars';
            identityNo: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            dateOfBirth: { __typename?: 'CustomerDateValueType'; source: CustomerDetailsSource; value: string | Date };
            gender: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            race: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            maritalStatus: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            nationality: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
            salutation?: Maybe<{
                __typename?: 'CustomerStringValueType';
                source: CustomerDetailsSource;
                value: string;
            }>;
        }>;
        licenseDetails?: Maybe<{
            __typename?: 'LicenseDetails';
            source: CustomerDetailsSource;
            number: string;
            validity: string;
            expiryDate: string | Date;
            classes: Array<{ __typename?: 'LicenseClass'; class: string; issueDate: string | Date }>;
            suspension?: Maybe<{
                __typename?: 'LicenseDates';
                startDate?: Maybe<string | Date>;
                endDate?: Maybe<string | Date>;
            }>;
            revocation?: Maybe<{
                __typename?: 'LicenseDates';
                startDate?: Maybe<string | Date>;
                endDate?: Maybe<string | Date>;
            }>;
            disqualification?: Maybe<{
                __typename?: 'LicenseDates';
                startDate?: Maybe<string | Date>;
                endDate?: Maybe<string | Date>;
            }>;
        }>;
        residentialAddress?: Maybe<{
            __typename?: 'ResidentialAddress';
            source: CustomerDetailsSource;
            block: string;
            unit: string;
            street: string;
            building?: Maybe<string>;
            country: string;
            postalCode: string;
            addressType?: Maybe<{
                __typename?: 'CustomerStringValueType';
                source: CustomerDetailsSource;
                value: string;
            }>;
        }>;
    }>;
};

export type SubmitBookingMutationVariables = Exact<{
    fields: BookInputFields;
}>;

export type SubmitBookingMutation = {
    __typename?: 'Mutation';
    submitBooking?: Maybe<{ __typename?: 'Booking'; id: string }>;
};

export type UploadDocumentMutationVariables = Exact<{
    file: Scalars['Upload'];
}>;

export type UploadDocumentMutation = {
    __typename?: 'Mutation';
    uploadDocument?: Maybe<{
        __typename?: 'UploadedFile';
        id: string;
        filename: string;
        etag: string;
        size: number;
        objectName: string;
        uploadedAt: string | Date;
        signedUrl: string;
    }>;
};

export type UploadProfilePictureMutationVariables = Exact<{
    file: Scalars['Upload'];
}>;

export type UploadProfilePictureMutation = {
    __typename?: 'Mutation';
    uploadProfilePicture?: Maybe<{
        __typename?: 'UploadedFile';
        id: string;
        filename: string;
        etag: string;
        size: number;
        objectName: string;
        uploadedAt: string | Date;
        signedUrl: string;
    }>;
};

type UserPreviewData_AdminUser_Fragment = {
    __typename?: 'AdminUser';
    id: string;
    fullName: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
};

type UserPreviewData_Customer_Fragment = {
    __typename?: 'Customer';
    id: string;
    fullName: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
};

export type UserPreviewDataFragment = UserPreviewData_AdminUser_Fragment | UserPreviewData_Customer_Fragment;

type UserFullData_AdminUser_Fragment = {
    __typename?: 'AdminUser';
    id: string;
    username: string;
    fullName: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
};

type UserFullData_Customer_Fragment = {
    __typename?: 'Customer';
    id: string;
    username: string;
    fullName: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
};

export type UserFullDataFragment = UserFullData_AdminUser_Fragment | UserFullData_Customer_Fragment;

export type CustomerFullDataFragment = {
    __typename?: 'Customer';
    id: string;
    username: string;
    type: UserType;
    status: UserStatus;
    registeredAt: string | Date;
    subscribeToNewsLetter?: Maybe<boolean>;
    profilePicture?: Maybe<{ __typename?: 'UploadedFile'; signedUrl: string }>;
    fullName: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
    displayName?: Maybe<{ __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string }>;
    email: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
    mobilePhone: {
        __typename?: 'CustomerContactType';
        source?: Maybe<CustomerDetailsSource>;
        value?: Maybe<string>;
        internationalCode?: Maybe<string>;
    };
    smsContact?: Maybe<{
        __typename?: 'CustomerContactType';
        source?: Maybe<CustomerDetailsSource>;
        value?: Maybe<string>;
        internationalCode?: Maybe<string>;
    }>;
    particulars?: Maybe<{
        __typename?: 'PersonParticulars';
        identityNo: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        dateOfBirth: { __typename?: 'CustomerDateValueType'; source: CustomerDetailsSource; value: string | Date };
        gender: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        race: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        maritalStatus: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        nationality: { __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string };
        salutation?: Maybe<{ __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string }>;
    }>;
    licenseDetails?: Maybe<{
        __typename?: 'LicenseDetails';
        source: CustomerDetailsSource;
        number: string;
        validity: string;
        expiryDate: string | Date;
        classes: Array<{ __typename?: 'LicenseClass'; class: string; issueDate: string | Date }>;
        suspension?: Maybe<{
            __typename?: 'LicenseDates';
            startDate?: Maybe<string | Date>;
            endDate?: Maybe<string | Date>;
        }>;
        revocation?: Maybe<{
            __typename?: 'LicenseDates';
            startDate?: Maybe<string | Date>;
            endDate?: Maybe<string | Date>;
        }>;
        disqualification?: Maybe<{
            __typename?: 'LicenseDates';
            startDate?: Maybe<string | Date>;
            endDate?: Maybe<string | Date>;
        }>;
    }>;
    residentialAddress?: Maybe<{
        __typename?: 'ResidentialAddress';
        source: CustomerDetailsSource;
        block: string;
        unit: string;
        street: string;
        building?: Maybe<string>;
        country: string;
        postalCode: string;
        addressType?: Maybe<{ __typename?: 'CustomerStringValueType'; source: CustomerDetailsSource; value: string }>;
    }>;
};

export type CreateNewAccountMutationVariables = Exact<{
    fields: AccountRegistrationFields;
}>;

export type CreateNewAccountMutation = {
    __typename?: 'Mutation';
    createAccount: { __typename?: 'AdminUser'; id: string } | { __typename?: 'Customer'; id: string };
};

export const AppOptionsAllFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AppOptionsAll' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppOptions' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countries' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genders' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gendersManual' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'races' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenseClasses' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenseValidity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nationalities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salutations' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maritalStatuses' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const LocationFullDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LocationFullData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Location' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinate' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingHours' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'block' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const AssetListDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AssetListData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'colour' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'providerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentLocation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coordinate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'technicalSpecifications' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fuelCapacityLitres' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfGears' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfAxles' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfWheels' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasTailLift' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasRefrigeration' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'features' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restrictions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerHour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerDay' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerWeek' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerMonth' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileageCap' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'returnLocation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalItems' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const BookingListDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BookingListData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Booking' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AssetListData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rentalStartDate' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rentalEndDate' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'days' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rentalAgreement' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalItems' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AssetListData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'colour' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'providerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentLocation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coordinate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'technicalSpecifications' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fuelCapacityLitres' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfGears' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfAxles' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfWheels' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasTailLift' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasRefrigeration' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'features' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restrictions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerHour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerDay' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerWeek' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerMonth' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileageCap' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'returnLocation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalItems' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const UserPreviewDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'UserPreviewData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const UserFullDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'UserFullData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const CustomerFullDataFragmentDoc = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CustomerFullData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'email' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'smsContact' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'particulars' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'identityNo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateOfBirth' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gender' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'race' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maritalStatus' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nationality' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salutation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenseDetails' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'validity' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'classes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'class' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'suspension' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revocation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'disqualification' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'residentialAddress' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'block' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'addressType' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'registeredAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subscribeToNewsLetter' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export const AppOptionsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'appOptions' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appOptions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppOptionsAll' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AppOptionsAll' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AppOptions' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countries' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genders' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gendersManual' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'races' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenseClasses' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenseValidity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nationalities' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salutations' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maritalStatuses' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addressTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useAppOptionsQuery__
 *
 * To run a query within a React component, call `useAppOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AppOptionsQuery, AppOptionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<AppOptionsQuery, AppOptionsQueryVariables>(AppOptionsDocument, options);
}
export function useAppOptionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AppOptionsQuery, AppOptionsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<AppOptionsQuery, AppOptionsQueryVariables>(AppOptionsDocument, options);
}
export type AppOptionsQueryHookResult = ReturnType<typeof useAppOptionsQuery>;
export type AppOptionsLazyQueryHookResult = ReturnType<typeof useAppOptionsLazyQuery>;
export type AppOptionsQueryResult = Apollo.QueryResult<AppOptionsQuery, AppOptionsQueryVariables>;
export const AssetsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'assets' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchAssetInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assets' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AssetListData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AssetListData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'colour' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'providerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentLocation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coordinate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'technicalSpecifications' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fuelCapacityLitres' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfGears' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfAxles' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfWheels' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasTailLift' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasRefrigeration' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'features' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restrictions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerHour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerDay' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerWeek' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerMonth' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileageCap' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'returnLocation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalItems' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAssetsQuery(baseOptions: Apollo.QueryHookOptions<AssetsQuery, AssetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
}
export function useAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetsQuery, AssetsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
}
export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsQueryResult = Apollo.QueryResult<AssetsQuery, AssetsQueryVariables>;
export const AuthenticateDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'authenticate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authenticate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'username' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'password' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateMutation(
    baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
}
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<
    AuthenticateMutation,
    AuthenticateMutationVariables
>;
export const RefreshCredentialsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'refreshCredentials' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'refreshCredentials' } }],
            },
        },
    ],
} as unknown as DocumentNode;
export type RefreshCredentialsMutationFn = Apollo.MutationFunction<
    RefreshCredentialsMutation,
    RefreshCredentialsMutationVariables
>;

/**
 * __useRefreshCredentialsMutation__
 *
 * To run a mutation, you first call `useRefreshCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshCredentialsMutation, { data, loading, error }] = useRefreshCredentialsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshCredentialsMutation(
    baseOptions?: Apollo.MutationHookOptions<RefreshCredentialsMutation, RefreshCredentialsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<RefreshCredentialsMutation, RefreshCredentialsMutationVariables>(
        RefreshCredentialsDocument,
        options
    );
}
export type RefreshCredentialsMutationHookResult = ReturnType<typeof useRefreshCredentialsMutation>;
export type RefreshCredentialsMutationResult = Apollo.MutationResult<RefreshCredentialsMutation>;
export type RefreshCredentialsMutationOptions = Apollo.BaseMutationOptions<
    RefreshCredentialsMutation,
    RefreshCredentialsMutationVariables
>;
export const BookingsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'bookings' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bookings' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BookingListData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AssetListData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'make' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'model' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'variant' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'colour' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'providerId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentLocation' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coordinate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'technicalSpecifications' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'mileage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfSeats' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fuelCapacityLitres' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfGears' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfAxles' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfWheels' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasTailLift' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'hasRefrigeration' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fuelType' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'features' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restrictions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerHour' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerDay' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerWeek' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'pricePerMonth' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mileageCap' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'returnLocation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalItems' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BookingListData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Booking' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AssetListData' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'rentalStartDate' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rentalEndDate' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'days' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'gst' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'rentalAgreement' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalItems' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingsQuery(baseOptions?: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
}
export function useBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
}
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsQueryResult = Apollo.QueryResult<BookingsQuery, BookingsQueryVariables>;
export const CreateAccountDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createAccount' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRegistrationFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createAccount' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
}
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
    CreateAccountMutation,
    CreateAccountMutationVariables
>;
export const CustomerDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomerFullData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CustomerFullData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'email' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'smsContact' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'particulars' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'identityNo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateOfBirth' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gender' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'race' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maritalStatus' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nationality' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salutation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenseDetails' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'validity' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'classes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'class' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'suspension' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revocation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'disqualification' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'residentialAddress' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'block' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'addressType' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'registeredAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subscribeToNewsLetter' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerQuery(baseOptions?: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const ListLocationsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'listLocations' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listLocations' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LocationFullData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LocationFullData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Location' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinate' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingHours' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'block' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useListLocationsQuery__
 *
 * To run a query within a React component, call `useListLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLocationsQuery(
    baseOptions?: Apollo.QueryHookOptions<ListLocationsQuery, ListLocationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useQuery<ListLocationsQuery, ListLocationsQueryVariables>(ListLocationsDocument, options);
}
export function useListLocationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ListLocationsQuery, ListLocationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useLazyQuery<ListLocationsQuery, ListLocationsQueryVariables>(ListLocationsDocument, options);
}
export type ListLocationsQueryHookResult = ReturnType<typeof useListLocationsQuery>;
export type ListLocationsLazyQueryHookResult = ReturnType<typeof useListLocationsLazyQuery>;
export type ListLocationsQueryResult = Apollo.QueryResult<ListLocationsQuery, ListLocationsQueryVariables>;
export const SaveDriverDetailsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'saveDriverDetails' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'LicenseDetailsInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saveDriverDetails' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomerFullData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CustomerFullData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'email' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'smsContact' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'particulars' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'identityNo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateOfBirth' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gender' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'race' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maritalStatus' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nationality' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salutation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenseDetails' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'validity' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'classes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'class' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'suspension' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revocation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'disqualification' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'residentialAddress' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'block' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'addressType' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'registeredAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subscribeToNewsLetter' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type SaveDriverDetailsMutationFn = Apollo.MutationFunction<
    SaveDriverDetailsMutation,
    SaveDriverDetailsMutationVariables
>;

/**
 * __useSaveDriverDetailsMutation__
 *
 * To run a mutation, you first call `useSaveDriverDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDriverDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDriverDetailsMutation, { data, loading, error }] = useSaveDriverDetailsMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useSaveDriverDetailsMutation(
    baseOptions?: Apollo.MutationHookOptions<SaveDriverDetailsMutation, SaveDriverDetailsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<SaveDriverDetailsMutation, SaveDriverDetailsMutationVariables>(
        SaveDriverDetailsDocument,
        options
    );
}
export type SaveDriverDetailsMutationHookResult = ReturnType<typeof useSaveDriverDetailsMutation>;
export type SaveDriverDetailsMutationResult = Apollo.MutationResult<SaveDriverDetailsMutation>;
export type SaveDriverDetailsMutationOptions = Apollo.BaseMutationOptions<
    SaveDriverDetailsMutation,
    SaveDriverDetailsMutationVariables
>;
export const SaveUserAccountDetailsDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'saveUserAccountDetails' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saveUserAccountDetails' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomerFullData' } }],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CustomerFullData' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profilePicture' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'email' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'smsContact' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'internationalCode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'particulars' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'identityNo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateOfBirth' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'gender' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'race' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maritalStatus' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nationality' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salutation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenseDetails' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'validity' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'classes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'class' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'issueDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'suspension' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revocation' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'disqualification' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'residentialAddress' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'block' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'addressType' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'registeredAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subscribeToNewsLetter' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type SaveUserAccountDetailsMutationFn = Apollo.MutationFunction<
    SaveUserAccountDetailsMutation,
    SaveUserAccountDetailsMutationVariables
>;

/**
 * __useSaveUserAccountDetailsMutation__
 *
 * To run a mutation, you first call `useSaveUserAccountDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserAccountDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserAccountDetailsMutation, { data, loading, error }] = useSaveUserAccountDetailsMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useSaveUserAccountDetailsMutation(
    baseOptions?: Apollo.MutationHookOptions<SaveUserAccountDetailsMutation, SaveUserAccountDetailsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<SaveUserAccountDetailsMutation, SaveUserAccountDetailsMutationVariables>(
        SaveUserAccountDetailsDocument,
        options
    );
}
export type SaveUserAccountDetailsMutationHookResult = ReturnType<typeof useSaveUserAccountDetailsMutation>;
export type SaveUserAccountDetailsMutationResult = Apollo.MutationResult<SaveUserAccountDetailsMutation>;
export type SaveUserAccountDetailsMutationOptions = Apollo.BaseMutationOptions<
    SaveUserAccountDetailsMutation,
    SaveUserAccountDetailsMutationVariables
>;
export const SubmitBookingDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'submitBooking' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'BookInputFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submitBooking' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type SubmitBookingMutationFn = Apollo.MutationFunction<SubmitBookingMutation, SubmitBookingMutationVariables>;

/**
 * __useSubmitBookingMutation__
 *
 * To run a mutation, you first call `useSubmitBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBookingMutation, { data, loading, error }] = useSubmitBookingMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useSubmitBookingMutation(
    baseOptions?: Apollo.MutationHookOptions<SubmitBookingMutation, SubmitBookingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<SubmitBookingMutation, SubmitBookingMutationVariables>(SubmitBookingDocument, options);
}
export type SubmitBookingMutationHookResult = ReturnType<typeof useSubmitBookingMutation>;
export type SubmitBookingMutationResult = Apollo.MutationResult<SubmitBookingMutation>;
export type SubmitBookingMutationOptions = Apollo.BaseMutationOptions<
    SubmitBookingMutation,
    SubmitBookingMutationVariables
>;
export const UploadDocumentDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uploadDocument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'etag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'objectName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'uploadedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UploadDocumentMutationFn = Apollo.MutationFunction<UploadDocumentMutation, UploadDocumentMutationVariables>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadDocumentMutation, UploadDocumentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(UploadDocumentDocument, options);
}
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type UploadDocumentMutationResult = Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<
    UploadDocumentMutation,
    UploadDocumentMutationVariables
>;
export const UploadProfilePictureDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadProfilePicture' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uploadProfilePicture' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'etag' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'objectName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'uploadedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type UploadProfilePictureMutationFn = Apollo.MutationFunction<
    UploadProfilePictureMutation,
    UploadProfilePictureMutationVariables
>;

/**
 * __useUploadProfilePictureMutation__
 *
 * To run a mutation, you first call `useUploadProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePictureMutation, { data, loading, error }] = useUploadProfilePictureMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProfilePictureMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>(
        UploadProfilePictureDocument,
        options
    );
}
export type UploadProfilePictureMutationHookResult = ReturnType<typeof useUploadProfilePictureMutation>;
export type UploadProfilePictureMutationResult = Apollo.MutationResult<UploadProfilePictureMutation>;
export type UploadProfilePictureMutationOptions = Apollo.BaseMutationOptions<
    UploadProfilePictureMutation,
    UploadProfilePictureMutationVariables
>;
export const CreateNewAccountDocument = /* #__PURE__ */ {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createNewAccount' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRegistrationFields' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createAccount' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fields' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'fields' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;
export type CreateNewAccountMutationFn = Apollo.MutationFunction<
    CreateNewAccountMutation,
    CreateNewAccountMutationVariables
>;

/**
 * __useCreateNewAccountMutation__
 *
 * To run a mutation, you first call `useCreateNewAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewAccountMutation, { data, loading, error }] = useCreateNewAccountMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateNewAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateNewAccountMutation, CreateNewAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };

    return Apollo.useMutation<CreateNewAccountMutation, CreateNewAccountMutationVariables>(
        CreateNewAccountDocument,
        options
    );
}
export type CreateNewAccountMutationHookResult = ReturnType<typeof useCreateNewAccountMutation>;
export type CreateNewAccountMutationResult = Apollo.MutationResult<CreateNewAccountMutation>;
export type CreateNewAccountMutationOptions = Apollo.BaseMutationOptions<
    CreateNewAccountMutation,
    CreateNewAccountMutationVariables
>;

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { i18n as I18n } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import Bootstrap, { BootstrapProps } from './Bootstrap';
import MainRouter from './MainRouter';
import AlertProvider from './components/AlertProvider';
import LoadingProvider from './components/LoadingProvider';
import theme from './layouts/BasicLayout/basicTheme';
import { RuntimeProvider, RuntimeConfig } from './runtimeConfig';
import NotificationContextProvider from './utilities/notificationContext/NotificationContextProvider';
import GlobalContextProvider from './utilities/shared/GlobalContextProvider';

export type AppProps = {
    i18n: I18n;
    runtime: RuntimeConfig;
    createApolloClient: BootstrapProps['createApolloClient'];
};

const App = ({ i18n, runtime, createApolloClient }: AppProps) => (
    <RuntimeProvider runtime={runtime}>
        <I18nextProvider i18n={i18n}>
            <LoadingProvider>
                <AlertProvider>
                    <Bootstrap createApolloClient={createApolloClient}>
                        <GlobalContextProvider>
                            <MuiThemeProvider theme={theme}>
                                <NotificationContextProvider>
                                    <CssBaseline />
                                    <MainRouter />
                                </NotificationContextProvider>
                            </MuiThemeProvider>
                        </GlobalContextProvider>
                    </Bootstrap>
                </AlertProvider>
            </LoadingProvider>
        </I18nextProvider>
    </RuntimeProvider>
);

export default App;

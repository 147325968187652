import { ReactSVG } from 'react-svg';
import usePublic from '../utilities/usePublic';

interface ImgIconProps {
    url: string;
    className?: string;
}

const ImgIcon = ({ url, ...props }: ImgIconProps) => {
    const icon = usePublic(url);

    return <ReactSVG src={icon} {...props} />;
};

export default ImgIcon;

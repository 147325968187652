import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import ContactlDetailsSection from './ContactDetailsSection';
import PersonalDetailsSection from './PersonalDetailsSection';

const CustomerDetailsForm = () => {
    const [t] = useTranslation('customerPage');
    const classes = useBookingStyles();

    return (
        <Grid spacing={4} container>
            <Grid xs={12} item>
                <Typography className={classes.subGroupTitle} component="p">
                    {t('personalDetailsTitle')}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <PersonalDetailsSection />
            </Grid>
            <Grid xs={12} item>
                <Typography className={classes.subGroupTitle} component="p">
                    {t('contactDetailsTitle')}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <ContactlDetailsSection />
            </Grid>
        </Grid>
    );
};

export default CustomerDetailsForm;

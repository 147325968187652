import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAppOptionsQuery } from './api';
import { withErrorBoundary } from './layouts/RoutedErrorBoundary';
import BookContextRouter from './routers/BookContextRouter';
import IdentityRouter from './routers/IdentityRouter';
import OverviewRouter from './routers/OverviewRouter';
import DummyErrorRoute from './routes/DummyErrorRoute';
import InternalErrorRoute from './routes/InternalErrorRoute';
import NotFoundRoute from './routes/NotFoundRoute';
import { useGlobalContext } from './utilities/shared/GlobalContextProvider';
import { setGlobalStateData } from './utilities/shared/actions';
import useScrollToTop from './utilities/useScrollToTop';

const MainRouter = () => {
    useScrollToTop();
    const { data } = useAppOptionsQuery();
    const { dispatch } = useGlobalContext();

    useEffect(() => {
        if (data) {
            dispatch(setGlobalStateData('appOptions', data.appOptions));
        }
    }, [data, dispatch]);

    return (
        <Switch>
            <Route component={IdentityRouter} path={['/signin', '/signup']} exact />
            <Route component={OverviewRouter} path={['/overview']} />
            <Route path={['/book', '/']} render={BookContextRouter} />
            <Route component={DummyErrorRoute} path="/dummyError" exact />
            <Route component={InternalErrorRoute} path="/500" exact />l
            <Route component={NotFoundRoute} />
        </Switch>
    );
};

export default withErrorBoundary(MainRouter);

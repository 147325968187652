import { Grid } from '@material-ui/core';
import React from 'react';
import { Switch, Route } from 'react-router';
import { OverviewLinks, OverviewContainer, Aside } from '../pages/Overview/components';
import { BookingsRoute, OverviewRoute, AccountRoute, DriverInfoRoute, PaymentRoute } from '../routes/OverviewRoutes';
import AuthenticatedRouter from './AuthenticatedRouter';

const renderWithLinks = () => (
    <OverviewContainer>
        <Grid spacing={4} container>
            <Aside>
                <OverviewLinks />
            </Aside>
            <Grid md={9} xs={12} item>
                <Switch>
                    <Route component={BookingsRoute} path="/overview/bookings" />
                    <Route component={AccountRoute} path="/overview/account" />
                    <Route component={DriverInfoRoute} path="/overview/driving-information" />
                    <Route component={PaymentRoute} path="/overview/payment" />
                </Switch>
            </Grid>
        </Grid>
    </OverviewContainer>
);

const OverviewRouter = () => (
    <AuthenticatedRouter>
        <Switch>
            <Route component={OverviewRoute} path="/overview" exact />
            <Route
                path={['/overview/bookings', '/overview/account', '/overview/driving-information', '/overview/payment']}
                render={renderWithLinks}
            />
        </Switch>
    </AuthenticatedRouter>
);

export default OverviewRouter;

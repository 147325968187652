import { Container, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';
import PromotionList from './PromotionList';

const Promotions = () => {
    const classes = useOverviewStyles();
    const { t } = useTranslation('overviewPage');

    return (
        <div>
            <Container className={classes.promotionsContainer}>
                <div>
                    <Typography className={classes.promotionTitle} variant="h3">
                        {t('label.latestPromotions')}
                    </Typography>
                    <Typography className={classes.promotionCardDescription} variant="body2">
                        {t('label.latestPromotionsDescription')}
                    </Typography>
                </div>
                <PromotionList />
            </Container>
        </div>
    );
};

export default Promotions;

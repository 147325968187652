import { makeStyles } from '@material-ui/core/styles';

const useCommonStyles = makeStyles(theme => ({
    primary: {
        color: `${theme.palette.primary.main} `,
    },

    primary2: {
        color: `${theme.palette.secondary.contrastText} `,
    },

    primary2Border: {
        borderColor: `${theme.palette.secondary.contrastText} `,
    },

    secondary: {
        color: `${theme.palette.secondary.main} `,
    },

    secondaryBorder: {
        borderColor: `${theme.palette.secondary.main} `,
    },

    selectInput: {
        border: '1px solid #fff',
        borderRadius: '4px',
        boxShadow: '0px 0px 10px #46676A1A;',
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
    },
    bold: {
        fontWeight: 'bold',
        fontSize: '18px',
    },
}));

export default useCommonStyles;

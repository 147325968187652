import { Container } from '@material-ui/core';
import { ReactElement, ReactNode } from 'react';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';

export type OverviewContainerProps = {
    children: ReactElement | ReactNode | null;
};

const OverviewContainer = ({ children }: OverviewContainerProps): ReactElement => {
    const classes = useOverviewStyles();

    return (
        <Container className={classes.overviewPage} maxWidth={false}>
            {children}
        </Container>
    );
};

export default OverviewContainer;

import { Grid, Typography, Container, Button } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';

const PaymentPage = () => {
    const classes = useOverviewStyles();
    const bookingClasses = useBookingStyles();
    const [editMode, setEditMode] = useState<boolean>(false);

    const { t } = useTranslation('overviewPage');

    return (
        <Container maxWidth="md">
            <Grid spacing={3} container>
                <Grid justify="flex-end" md={8} xs={12} container item>
                    <Button
                        className={classes.overviewButton}
                        color="secondary"
                        onClick={() => setEditMode(!editMode)}
                        type={editMode ? 'submit' : 'button'}
                        variant="contained"
                    >
                        {editMode ? t('label.save') : t('label.addPayment')}
                    </Button>
                </Grid>
                <Grid xs={12} item>
                    <Typography className={bookingClasses.subGroupTitle} component="p">
                        {t('label.paymentMethods')}
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Typography>No Payment Methods Available. Please add a Credit Card.</Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default withErrorBoundary(PaymentPage);

import { useTranslation } from 'react-i18next';
import useIdentityStyles from '../../../layouts/BasicLayout/useIdentityStyles';

const SignUpTitle = () => {
    const classes = useIdentityStyles();
    const { t } = useTranslation(['signUpPage']);

    return <h5 className={classes.cardTitle}>{t('title')}</h5>;
};

export default SignUpTitle;

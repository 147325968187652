import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useBasicStyles from '../../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../../layouts/useCommonStyles';

export type SignUpFormSubmitProps = { isSubmitting: boolean; hasError: boolean };

const SignUpFormSubmit = ({ isSubmitting, hasError }: SignUpFormSubmitProps) => {
    const { t } = useTranslation(['signUpPage']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();

    return (
        <Grid xs={12} item>
            <Button
                className={basicStyles.signInButton}
                classes={{ disabled: commonStyles.disabledSecondaryButton }}
                color="secondary"
                disabled={isSubmitting || hasError}
                size="large"
                type="submit"
                variant="contained"
                fullWidth
            >
                {t('submitButton')}
            </Button>
        </Grid>
    );
};

export default SignUpFormSubmit;

import { Select, MenuItem, FormControl } from '@material-ui/core';
import React from 'react';
import SelectInputField from '../../../components/inputs/SelectInputField';
import useCommonStyles from '../../../useCommonStyles';

export type SelectProps = {
    value: string | number;
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    items: MenuItemProps[];
    onFilter?: boolean;
};

export type MenuItemProps = {
    value: string | number;
    label: string;
};

const SelectInput = ({ value, handleChange, items, onFilter }: SelectProps) => {
    const commonStyles = useCommonStyles();

    const getItems = items.map(data => (
        <MenuItem key={data.value} value={data.value}>
            {data.label}
        </MenuItem>
    ));

    return (
        <FormControl fullWidth>
            <Select
                className={commonStyles.selectInput}
                input={<SelectInputField />}
                labelId="sort-select"
                onChange={e => handleChange(e)}
                value={value}
                disableUnderline
            >
                {getItems}
            </Select>
        </FormControl>
    );
};

export default SelectInput;

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { TextField, PasswordField } from '../../../../components/inputs';

const SignUpFormFields = () => {
    const { t } = useTranslation(['signUpPage']);

    return (
        <>
            <Grid xs={12} item>
                <TextField label={t('label.fullName')} name="fullName" placeholder={t('placeholder.fullName')} />
            </Grid>
            <Grid xs={12} item>
                <TextField label={t('label.email')} name="email" placeholder={t('placeholder.email')} />
            </Grid>
            <Grid md={6} xs={12} item>
                <PasswordField
                    helperText={t('helperText.password')}
                    label={t('label.password')}
                    name="password"
                    placeholder={t('placeholder.password')}
                />
            </Grid>
            <Grid md={6} xs={12} item>
                <PasswordField
                    helperText={t('helperText.password')}
                    label={t('label.confirmPassword')}
                    name="confirmPassword"
                    placeholder={t('placeholder.confirmPassword')}
                />
            </Grid>
        </>
    );
};

export default SignUpFormFields;

import { Container, Card, CardContent } from '@material-ui/core';
import useIdentityStyles from '../../layouts/BasicLayout/useIdentityStyles';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import Wrapper from '../Wrapper';
import SignUpFooter from './components/SignUpFooter';
import SignUpForm, { SignUpFormProps } from './components/SignUpForm';
import SignUpTitle from './components/SignUpTitle';

export type SignUpPageProps = SignUpFormProps;

const SignUpPage = ({ initialValues }: SignUpPageProps) => {
    const classes = useIdentityStyles();

    return (
        <Wrapper>
            <Container className={classes.root}>
                <Card className={classes.signUpCard}>
                    <CardContent className={classes.cardContent}>
                        <SignUpTitle />
                        <SignUpForm initialValues={initialValues} />
                        <SignUpFooter />
                    </CardContent>
                </Card>
            </Container>
        </Wrapper>
    );
};

export default withErrorBoundary(SignUpPage);

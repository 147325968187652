import React, { ReactNode, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCustomerQuery } from '../api';
import BasicLayout from '../layouts/BasicLayout';
import { CustomerDetailsFormValues, DriverDetailsFormValues } from '../routes/BookRoutes/shared/types';
import { convertToCustomerDetailsFormValues, convertToDriverDetails } from '../utilities/commonFunctions';
import { useGlobalContext } from '../utilities/shared/GlobalContextProvider';
import { setGlobalStateData } from '../utilities/shared/actions';

type AuthenticatedRouterProps = {
    children: JSX.Element | ReactNode;
};

const AuthenticatedRouter = ({ children }: AuthenticatedRouterProps) => {
    const { data } = useCustomerQuery();
    const history = useHistory<{ from: string }>();
    const location = useLocation();
    const { pathname } = location;
    const { state, dispatch: dispatchGlobal, validateToken } = useGlobalContext();

    useEffect(() => {
        if (!validateToken(state?.authorization?.token)) {
            history.replace({
                pathname: '/signin',
                state: {
                    from: pathname,
                },
            });
        }
    }, [state, history, pathname, validateToken]);

    useEffect(() => {
        if (data?.customer) {
            const customerData = convertToCustomerDetailsFormValues(data.customer);
            dispatchGlobal(setGlobalStateData<CustomerDetailsFormValues>('customer', customerData));

            if (data?.customer?.licenseDetails) {
                const driverData = convertToDriverDetails(data.customer.licenseDetails);
                dispatchGlobal(setGlobalStateData<DriverDetailsFormValues>('driver', driverData));
            }
        }
    }, [data, dispatchGlobal]);

    return <BasicLayout>{children}</BasicLayout>;
};

export default AuthenticatedRouter;

import { Box, InputLabel, Slider, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemType } from '../../../routes/BookRoutes/shared/types';
import useCommonStyles from '../../../useCommonStyles';
import { FilterState } from '../shared/actions';
import SelectInput from './SelectInput';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterHeader: {
            fontSize: '18px',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.spacing(4),
            },
        },

        filterInputLabel: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            marginBottom: theme.spacing(2),
        },

        filters: {
            '& > *': {
                marginBottom: theme.spacing(2),
                [theme.breakpoints.up('sm')]: {
                    marginBottom: theme.spacing(4),
                },
            },
        },
    })
);

const formatBudget = budget => `SGD$${budget[0]}-SGD$${budget[1]}`;

type FilterProps = {
    filterOptions: {
        brands: ListItemType[];
        carTypes: ListItemType[];
    };
    setFilterState: (filterState: FilterState) => void;
    filter: FilterState;
};

const Filter = ({ filterOptions, filter, setFilterState }: FilterProps) => {
    // const [budget, setBudget] = useState([10, 30]);

    const handleBrand = useCallback(
        event => {
            const brand = event.target.value;
            setFilterState({ ...filter, brand });
        },
        [setFilterState, filter]
    );

    const handleCarType = useCallback(
        event => {
            const carType = event.target.value;
            setFilterState({ ...filter, carType });
        },
        [setFilterState, filter]
    );

    const handleSlider = useCallback(
        (_, newBudget) => {
            setFilterState({ ...filter, budget: newBudget });
        },
        [filter, setFilterState]
    );

    const { t } = useTranslation(['landingPage']);

    const styles = useStyles();
    const commonStyles = useCommonStyles();

    return (
        <Box display="flex" flexDirection="column">
            <Typography className={clsx(commonStyles.bold, styles.filterHeader)}>
                {t('landingPage:filterHeader')}
            </Typography>

            <div className={styles.filters}>
                <div>
                    <InputLabel className={styles.filterInputLabel} id="brand-select">
                        {t('landingPage:brands')}
                    </InputLabel>
                    <SelectInput
                        handleChange={handleBrand}
                        items={[{ value: 'any', label: 'All' }, ...filterOptions.brands]}
                        value={filter.brand}
                        onFilter
                    />
                </div>
                <div>
                    <InputLabel className={styles.filterInputLabel} id="carType-select">
                        {t('landingPage:carType')}
                    </InputLabel>
                    <SelectInput
                        handleChange={handleCarType}
                        items={[{ value: 'any', label: 'All' }, ...filterOptions.carTypes]}
                        value={filter.carType}
                        onFilter
                    />
                </div>
                <div>
                    <InputLabel className={styles.filterInputLabel} id="location-select">
                        {t('landingPage:totalPrice')}
                    </InputLabel>
                    <Typography>{formatBudget(filter.budget)}</Typography>
                    <Slider
                        color="secondary"
                        max={filter.budgetMax}
                        min={filter.budgetMin}
                        onChange={handleSlider}
                        step={1}
                        value={filter.budget}
                    />
                </div>
            </div>
        </Box>
    );
};

export default Filter;

import { Icon } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';

type OverviewLinkProps = {
    icon: JSX.Element;
    label: string;
    to: string;
    onClick: VoidFunction;
    exact: boolean;
};

const OverviewLink = ({ icon, label, to, onClick, exact }: OverviewLinkProps) => {
    const classes = useOverviewStyles();

    return (
        <NavLink className={classes.menuNavLink} onClick={onClick} to={to} exact>
            <Icon className={classes.menuIcon}>{icon}</Icon>
            <h3>{label}</h3>
        </NavLink>
    );
};
export default OverviewLink;

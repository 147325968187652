import { Grid } from '@material-ui/core';
import { ReactElement, ReactNode } from 'react';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';

export type AsideProps = {
    children: ReactElement | ReactNode | null;
};

const Aside = ({ children }: AsideProps): ReactElement => {
    const classes = useOverviewStyles();

    return (
        <Grid className={classes.aside} md={3} xs={12} item>
            {children}
        </Grid>
    );
};

export default Aside;

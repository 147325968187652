import { Container, Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { AssetListDataFragment } from '../../../api';
import { useBookContext } from '../../../routes/BookRoutes/shared/BookingProvider';
import { setBookingData } from '../../../routes/BookRoutes/shared/actions';
import { BookingDate, ListItemType } from '../../../routes/BookRoutes/shared/types';
import { PagingState, FilterState } from '../shared/actions';
import AssetList from './AssetList';
import Filter from './Filter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
    })
);

interface LandingBodyProps {
    assets: AssetListDataFragment[];
    filterOptions: { brands: ListItemType[]; carTypes: ListItemType[] };
    setPagingState: (pagingState: PagingState) => void;
    paging: PagingState;
    setFilterState: (filterState: FilterState) => void;
    filter: FilterState;
}

const LandingBody = ({ assets, filterOptions, setPagingState, paging, setFilterState, filter }: LandingBodyProps) => {
    const styles = useStyles();
    const history = useHistory();
    const { dispatch } = useBookContext();
    const { date } = filter;

    const onSelect = useCallback(
        (assetId: string) => {
            const selectedAsset = assets.find(asset => asset.id === assetId);
            dispatch(setBookingData<BookingDate>('date', { ...date }));
            dispatch(setBookingData<AssetListDataFragment>('asset', selectedAsset));
            history.push('/book/package-selection');
        },
        [assets, date, dispatch, history]
    );

    return (
        <Container maxWidth={false}>
            <Grid className={styles.root} direction="row" spacing={4} container>
                <Grid sm={3} xs={12} item>
                    <Filter filter={filter} filterOptions={filterOptions} setFilterState={setFilterState} />
                </Grid>
                <Grid sm={9} xs={12} item>
                    <AssetList
                        assetList={assets}
                        days={date.days}
                        onSelect={onSelect}
                        paging={paging}
                        setPagingState={setPagingState}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default LandingBody;

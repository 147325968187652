import { Grid, Container, Typography, Button, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { withErrorBoundary } from '../../../layouts/RoutedErrorBoundary';
import { useBookContext } from '../../../routes/BookRoutes/shared/BookingProvider';
import usePublic from '../../../utilities/usePublic';

const SummaryPage = () => {
    const classes = useBookingStyles();
    const { t } = useTranslation('summaryPage');
    const car = usePublic('assets/images/car.svg');
    const { state } = useBookContext();
    const { customer } = state;
    const logo = usePublic('assets/images/appvantageLogo.png');
    const history = useHistory();

    return (
        <div className={classes.bookingConfirmation}>
            <Container className={classes.bookingPage} maxWidth="md">
                <Grid container>
                    <Grid xs={12} item>
                        <Box sx={{ textAlign: 'center', mt: '3rem' }}>
                            <img alt="logo" className={classes.appvantageLogo} src={logo} />
                        </Box>
                    </Grid>
                    <Grid xs={12} item>
                        <Typography
                            style={{ textAlign: 'center', marginTop: '0.5rem', textTransform: 'uppercase' }}
                            variant="h5"
                        >
                            {t('thankyou')} {customer.fullName.value}!
                        </Typography>
                        <Typography color="secondary" style={{ textAlign: 'center', marginTop: '0.5rem' }} variant="h5">
                            {t('received')}
                        </Typography>
                        <Typography style={{ textAlign: 'center', marginTop: '0.5rem' }} variant="h6">
                            {t('contact')}
                        </Typography>
                    </Grid>
                    <Grid justifyContent="center" xs={12} container item>
                        <img alt="Car" src={car} style={{ maxWidth: '100%', marginTop: '4rem' }} />
                    </Grid>
                    <Grid
                        style={{ justifyContent: 'center', paddingTop: '2rem', marginTop: '3rem' }}
                        xs={12}
                        container
                        item
                    >
                        <Button className={classes.summaryButton} color="secondary" variant="contained">
                            {t('label.saveAsPdf')}
                        </Button>{' '}
                        <Button
                            className={classes.summaryButton}
                            color="secondary"
                            onClick={() => history.push('/book/viewBooking')}
                            variant="contained"
                        >
                            {t('label.viewMyBooking')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default withErrorBoundary(SummaryPage);

import React, { useState, useCallback } from 'react';
import PasswordSuffix from './PasswordSuffix';
import TextField, { TextFieldProps } from './TextField';

const PasswordField = ({ ...props }: TextFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const toggleVisibility = useCallback(() => setShowPassword(state => !state), [setShowPassword]);

    const endAdornment = <PasswordSuffix showPassword={showPassword} toggleVisibility={toggleVisibility} />;

    const type = showPassword ? 'text' : 'password';

    return <TextField {...props} endAdornment={endAdornment} type={type} />;
};

export default PasswordField;

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CheckBoxField } from '../../../../components/inputs';
import TermsLabel from '../Termslabel';

const SignUpFormFooter = () => {
    const { t } = useTranslation(['signUpPage']);

    return (
        <>
            <Grid xs={12} item>
                <CheckBoxField label={<TermsLabel />} name="agreeToTerms" />
            </Grid>
            <Grid xs={12} item>
                <CheckBoxField label={t('marketingConsent')} name="marketingConsent" />
            </Grid>
        </>
    );
};

export default SignUpFormFooter;

import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import * as yup from 'yup';
import { useAuthenticateMutation } from '../../../../api';
import { useHandleError } from '../../../../utilities/handleError';
import { useNotificationContext } from '../../../../utilities/notificationContext/NotificationContextProvider';
import { useGlobalContext } from '../../../../utilities/shared/GlobalContextProvider';
import { setGlobalStateData } from '../../../../utilities/shared/actions';
import LoginFormFields from './LoginFormFields';
import LoginFormSubmit from './LoginFormSubmit';

export type LoginFormValues = {
    username: string;
    password: string;
};

export type LoginFormProps = {
    initialValues: LoginFormValues;
};

const LoginForm = ({ initialValues }: LoginFormProps) => {
    const [authenticate] = useAuthenticateMutation();
    const [t] = useTranslation('loginPage');
    const { dispatch } = useGlobalContext();
    const { setNotification } = useNotificationContext();

    const signInValidationSchema = useMemo(() => {
        const schema = yup.object().shape({
            password: yup.string().required(t('validation.required.password')),
            username: yup
                .string()
                .required(t('validation.required.username'))
                .email(t('validation.invalid.username'))
                .max(320, t('validation.maxLength.username')),
        });

        return schema;
    }, [t]);

    const history = useHistory();
    const location = useLocation<{ from: string }>();

    const onSubmit = useHandleError(
        async (values: LoginFormValues) => {
            const { data } = await authenticate({ variables: values });

            dispatch(setGlobalStateData('authorization', { token: data.authenticate.token }));

            if (location.state?.from) {
                history.push(location.state?.from);
            } else {
                history.push('/');
            }
            setNotification(t('modalCardSignIn'));
        },
        [authenticate]
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={signInValidationSchema}>
            {({ isSubmitting, errors }) => (
                <Form>
                    <Grid spacing={2} container>
                        <LoginFormFields />
                        <LoginFormSubmit hasError={!isEmpty(errors)} isSubmitting={isSubmitting} />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;

import React from 'react';
import { Switch, Route } from 'react-router';
import {
    ConfirmationRoute,
    CustomerRoute,
    DriverRoute,
    PackageSelectionRoute,
    PickUpAndDeliveryRoute,
    SummaryRoute,
} from '../routes/BookRoutes';
import ViewBookingRoute from '../routes/BookRoutes/ViewBookingRoute';
import AuthenticatedRouter from './AuthenticatedRouter';

const BookingRouter = () => (
    <AuthenticatedRouter>
        <Switch>
            <Route component={PackageSelectionRoute} path="/book/package-selection" />
            <Route component={CustomerRoute} path="/book/customer" />
            <Route component={DriverRoute} path="/book/driver" />
            <Route component={PickUpAndDeliveryRoute} path="/book/pickUpDelivery" />
            <Route component={ConfirmationRoute} path="/book/confirmation" />
            <Route component={SummaryRoute} path="/book/summary" />
            <Route component={ViewBookingRoute} path="/book/viewBooking" />
        </Switch>
    </AuthenticatedRouter>
);

export default BookingRouter;

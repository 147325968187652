import { Typography, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useOverviewStyles from '../../../layouts/BasicLayout/useOverviewStyles';

interface MenuLinkProps {
    to: string;
    label: string;
    icon: JSX.Element;
}

const MenuLink = ({ to, label, icon, ...props }: MenuLinkProps) => {
    const classes = useOverviewStyles();

    return (
        <Link className={classes.menuLink} to={to} {...props}>
            <Icon className={classes.menuIcon}>{icon}</Icon>
            <Typography>{label} </Typography>
        </Link>
    );
};

export default MenuLink;

import { Typography, Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AssetListDataFragment } from '../../../api';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { BookingDate } from '../../../routes/BookRoutes/shared/types';
import usePublic from '../../../utilities/usePublic';
import ImageWithFallback from './ImageWithFallback';

interface AssetDetailsProps {
    asset: AssetListDataFragment;
    bookingDate: BookingDate;
}

interface PriceTableProps {
    pricePerDay: number;
    totalPrice: number;
}

const PriceTable = ({ pricePerDay, totalPrice }: PriceTableProps) => {
    const classes = useBookingStyles();
    const { t } = useTranslation('booking');

    return (
        <Box className={classes.pricingDetails} display="flex" flexDirection="row" mb={1} textAlign="center">
            <Box display="flex" flex="1" flexDirection="column">
                <Typography component="h3">
                    <sup>$</sup>
                    <span className={classes.price}>{pricePerDay}</span>
                </Typography>
                <Typography className={classes.priceType} color="secondary">
                    {t('label.dailyRateText')}
                </Typography>
            </Box>
            <Box display="flex" flex="1" flexDirection="column">
                <Typography component="h3">
                    <sup>$</sup>
                    <span className={classes.price}>{totalPrice}</span>
                </Typography>
                <Typography className={classes.priceType} color="secondary">
                    {t('label.totalPrice')}
                </Typography>
            </Box>
        </Box>
    );
};

const AssetDetails = ({ asset, bookingDate }: AssetDetailsProps) => {
    const { make, model, variant, category, technicalSpecifications, images, restrictions } = asset;
    const specifications = [category.description, `${technicalSpecifications?.numberOfSeats} Seater`].join(' · ');
    const classes = useBookingStyles();
    const { days } = bookingDate;
    const fallbackImage = usePublic('assets/images/notAvailable.png');

    return (
        <>
            {images?.map(item => (
                <ImageWithFallback
                    key={item.signedUrl}
                    className={classes.assetImage}
                    fallbackSrc={fallbackImage}
                    src={item.signedUrl}
                />
            ))}
            <Grid spacing={4} container>
                <Grid lg={7} xs={8} item>
                    <Box>
                        <Typography className={classes.assetDescription} component="h4">
                            {make} {model} {variant}
                        </Typography>
                        <Typography component="p">{specifications}</Typography>
                    </Box>
                </Grid>
                <Grid lg={5} xs={4} item>
                    <PriceTable pricePerDay={restrictions?.pricePerDay} totalPrice={restrictions?.pricePerDay * days} />
                </Grid>
            </Grid>
        </>
    );
};

export default AssetDetails;

import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { AdditionalItem as AdditionalItemType, AdditionalOption } from '../../../routes/BookRoutes/shared/types';
import AdditionalItemFC from './AdditionalItemFC';

type AdditionalItemsProps = {
    additionalItems: AdditionalItemType[];
    onChange(value: AdditionalOption): void;
};

const AdditionalItems = ({ additionalItems, onChange }: AdditionalItemsProps) => {
    const { t } = useTranslation('booking');

    const classes = useBookingStyles();
    const basicStyles = useBasicStyles();

    return (
        <Grid className={basicStyles.itemsContainer} container>
            <Grid xs={12} item>
                <Typography className={classes.featureHeader} component="h5">
                    {t('label.additionalItems')}
                </Typography>
            </Grid>
            {additionalItems.map(item => (
                <AdditionalItemFC key={item.id} item={item} onChange={onChange} />
            ))}
        </Grid>
    );
};

export default AdditionalItems;

import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useBookingStyles from '../../../layouts/BasicLayout/useBookingStyles';
import { BookingDate } from '../../../routes/BookRoutes/shared/types';
import useFormatting from '../../../utilities/useFormatting';

interface BookingDatesCardProps {
    bookingDate: BookingDate;
}

const BookingDatesCard = ({ bookingDate }: BookingDatesCardProps) => {
    const classes = useBookingStyles();
    const { t } = useTranslation('booking');
    const { formatDate } = useFormatting();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid spacing={1} container>
                    <Grid xs={12} item>
                        <Typography className={classes.groupTitle} color="secondary" component="p">
                            {t('bookingDates.title')}
                        </Typography>
                        <Typography className={classes.subGroupTitle} color="primary" component="p">
                            {t('bookingDates.subTitle')}
                        </Typography>
                    </Grid>
                    <Grid xs={12} container item>
                        <Grid direction="row" container>
                            <DateRangeIcon />
                            <Typography className={classes.subCardTitle} color="primary">
                                {t('bookingDates.label.rentDate')}
                            </Typography>
                        </Grid>
                        <Typography className={classes.subCardValue} color="primary" component="p">
                            {formatDate(bookingDate?.start)} - {formatDate(bookingDate?.end)}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default BookingDatesCard;

import { Button, ButtonGroup, FormControl } from '@material-ui/core';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import useFieldStyles from './useFieldStyles';

export interface ButtonGroupFieldProps {
    name: string;
    options: { label: string; value: string }[];
}

const ButtonGroupField = ({ name, options }: ButtonGroupFieldProps) => {
    const [field, meta, helper] = useField({ name });
    const styles = useFieldStyles();

    const onClick = useCallback(
        event => {
            helper.setValue(event.currentTarget.value);
        },
        [helper]
    );

    return (
        <FormControl fullWidth>
            <ButtonGroup variant="outlined">
                {options.map(option => (
                    <Button
                        className={styles.button}
                        color="secondary"
                        onClick={onClick}
                        value={option.value}
                        variant={field.value === option.value ? 'contained' : 'outlined'}
                    >
                        {option.label}
                    </Button>
                ))}
            </ButtonGroup>
        </FormControl>
    );
};

export default ButtonGroupField;

import { ReactElement } from 'react';
import useBasicStyles from '../layouts/BasicLayout/useBasicStyles';
import usePublic from '../utilities/usePublic';

export type WrapperProps = {
    children: ReactElement | null;
};

const desktopGradient = 'linear-gradient(90deg, #000000 0%, #00000000 100%)';

const Wrapper = ({ children }: WrapperProps): ReactElement => {
    const classes = useBasicStyles();
    const backgroundImageLogo = usePublic('assets/images/login-background.png');

    return (
        <div
            className={classes.wrapper}
            style={{
                backgroundImage: `${desktopGradient}, url(${backgroundImageLogo})`,
            }}
        >
            {children}
        </div>
    );
};

export default Wrapper;

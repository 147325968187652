import { Container, Card, CardContent } from '@material-ui/core';
import useIdentityStyles from '../../layouts/BasicLayout/useIdentityStyles';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import Wrapper from '../Wrapper';
import { LoginFooter, LoginTitle, LoginFormProps, LoginForm } from './components';

export type LoginPageProps = LoginFormProps;

const LoginPage = ({ initialValues }: LoginPageProps) => {
    const classes = useIdentityStyles();

    return (
        <Wrapper>
            <Container className={classes.root}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <LoginTitle />
                        <LoginForm initialValues={initialValues} />
                        <LoginFooter />
                    </CardContent>
                </Card>
            </Container>
        </Wrapper>
    );
};

export default withErrorBoundary(LoginPage);

import { Grid, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useState } from 'react';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';

type CounterProps = {
    initialValue: number;
    onChange: any;
};

const Counter = ({ onChange, initialValue = 0 }: CounterProps) => {
    const [count, setCount] = useState(initialValue);
    const basicStyles = useBasicStyles();

    const increment = () => {
        const value = count + 1;
        setCount(value);
        onChange(value);
    };

    const decrement = () => {
        if (count !== 0) {
            const value = count - 1;
            setCount(value);
            onChange(value);
        }
    };

    return (
        <Grid className={basicStyles.itemsCounter} container>
            <Grid item>
                <IconButton
                    className={count !== 0 && basicStyles.itemsButton}
                    color="secondary"
                    disabled={count === 0}
                    onClick={decrement}
                >
                    <RemoveIcon />
                </IconButton>
            </Grid>
            <Grid className={basicStyles.itemsCounterText} item>
                <Typography component="p">{count}</Typography>
            </Grid>
            <Grid item>
                <IconButton className={basicStyles.itemsButton} onClick={increment}>
                    <AddIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default Counter;
